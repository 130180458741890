import { Component, OnInit } from "@angular/core";
import { LoggedInCallback, UserLoginService, UserParametersService, Callback } from "../../service/cognito.service";
import { Router } from "@angular/router";

import { Store } from "@ngrx/store";
import * as fromRoot from '../../common/index';

import { Observable } from "rxjs";

import { utils } from '../../lib/utils';
import * as layout from '../../common/layout/layout.actions';


@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './map.html',
    styleUrls: ['./map.css']
})

export class MapComponent implements OnInit, LoggedInCallback {

    public devices$: Observable<any>;

    latitude: any = 0;
    longitude: any = 0;

     constructor(private _utils: utils, private store: Store<fromRoot.AppState>, public router: Router, public userService: UserLoginService, public userParams: UserParametersService) {
        this.userService.isAuthenticated(this);
        this.devices$ = store.select(fromRoot.getDevicesEntities);
     }
     ngOnInit() {
        
     }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            //  this.userParams.getParameters(new GetParametersCallback(this));
        }
    }

}