
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';



@Injectable()
export class utils {

    variables = [];

    title = "";
    constructor( public translate: TranslateService,){
        console.log("translateeee!!!!");

        setTimeout( ()=>{
            console.log("mi papa");

            translate.get('new_notification').subscribe((res: string) => {
                console.log(res);
                this.title = res;
                //=> 'hello world'
            });

            translate.get('tx_time').subscribe((res: string) => {
                console.log(res);
                this.variables[0] = res;
                //=> 'hello world'
            });
            translate.get('low_temperature_sensor_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[1] = res;
                //=> 'hello world'
            });
            translate.get('high_temperature_sensor_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[2] = res;
                //=> 'hello world'
            });
            translate.get('disconnection_sensor_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[3] = res;
                //=> 'hello world'
            });
            translate.get('connection_sensor_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[4] = res;
                //=> 'hello world'
            });
            translate.get('change_state_digital_entry_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[5] = res;
                //=> 'hello world'
            });
            translate.get('numer_of_sensor_not_allowed_in').subscribe((res: string) => {
                console.log(res);
                this.variables[6] = res;
                //=> 'hello world'
            });
            translate.get('sensors_absence').subscribe((res: string) => {
                console.log(res);
                this.variables[7] = res;
                //=> 'hello world'
            });
            translate.get('rfid_reading').subscribe((res: string) => {
                console.log(res);
                this.variables[8] = res;
                //=> 'hello world'
            });
            translate.get('superior_limit_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[9] = res;
                //=> 'hello world'
            });
            translate.get('inferior_limit_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[10] = res;
                //=> 'hello world'
            });
            translate.get('movement_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[11] = res;
                //=> 'hello world'
            });
            translate.get('device_connection_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[12] = res;
                //=> 'hello world'
            });
            translate.get('device_disconnection_alarm').subscribe((res: string) => {
                console.log(res);
                this.variables[13] = res;
                //=> 'hello world'
            });
            translate.get('end_of_movement').subscribe((res: string) => {
                console.log(res);
                this.variables[14] = res;
                //=> 'hello world'
            });
            translate.get('fall').subscribe((res: string) => {
                console.log(res);
                this.variables[15] = res;
                //=> 'hello world'
            });
            translate.get('click').subscribe((res: string) => {
                console.log(res);
                this.variables[16] = res;
                //=> 'hello world'
            });
            translate.get('on').subscribe((res: string) => {
                console.log(res);
                this.variables[17] = res;
                //=> 'hello world'
            });
            translate.get('off').subscribe((res: string) => {
                console.log(res);
                this.variables[18] = res;
                //=> 'hello world'
            });
            translate.get('closed_necklace').subscribe((res: string) => {
                console.log(res);
                this.variables[19] = res;
                //=> 'hello world'
            });
            translate.get('open_necklace').subscribe((res: string) => {
                console.log(res);
                this.variables[20] = res;
                //=> 'hello world'
            });
            translate.get('high_glucose').subscribe((res: string) => {
                console.log(res);
                this.variables[21] = res;
                //=> 'hello world'
            });
            translate.get('low_glucose').subscribe((res: string) => {
                console.log(res);
                this.variables[22] = res;
                //=> 'hello world'
            });
            translate.get('incorrect_documentation').subscribe((res: string) => {
                console.log(res);
                this.variables[23] = res;
                //=> 'hello world'
            });
            translate.get('unregistered_reaseon').subscribe((res: string) => {
                console.log(res);
                this.variables[24] = res;
                //=> 'hello world'
            });

            
            translate.get('encendido_transferencia_potencia').subscribe((res: string) => {
                console.log(res);
                this.variables[25] = res;
                //=> 'hello world'
            });
            translate.get('apagado_transferencia_potencia').subscribe((res: string) => {
                console.log(res);
                this.variables[26] = res;
                //=> 'hello world'
            });
            translate.get('encendido_planta').subscribe((res: string) => {
                console.log(res);
                this.variables[27] = res;
                //=> 'hello world'
            });
            translate.get('apagado_planta').subscribe((res: string) => {
                console.log(res);
                this.variables[28] = res;
                //=> 'hello world'
            });
            
            translate.get('encendido_portadora').subscribe((res: string) => {
                console.log(res);
                this.variables[29] = res;
                //=> 'hello world'
            });
            
            translate.get('apagado_portadora').subscribe((res: string) => {
                console.log(res);
                this.variables[30] = res;
                //=> 'hello world'
            });
            translate.get('DEPÓSITO').subscribe((res: string) => {
                console.log(res);
                this.variables[31] = res;
                //=> 'hello world'
            });
            translate.get('RETIRO').subscribe((res: string) => {
                console.log(res);
                this.variables[32] = res;
                //=> 'hello world'
            });

            translate.get('TX POR TIEMPO').subscribe((res: string) => {
                console.log(res);
                this.variables[33] = res;
                //=> 'hello world'
            });
            

        }, 3000);
      

    }

    getTitle(){
        return this.title;
    }
    getStateReason(tramaVer) {

        var estado = {
            text: "",
            image: ""
        };

        if (tramaVer == 1.00) {
            //estado.text = "TX POR TIEMPO";
            estado.text = this.variables[0] ? this.variables[0] : "TX POR TIEMPO";

            estado.image = "assets/img/estados/TXTIEMPO.png";
          
           

        } else if ((tramaVer >= 1.01) && (tramaVer <= 1.09)) {
            estado.text = this.variables[1] ? this.variables[1] : "ALARMA BAJA TEMPERATURA SENSOR " + tramaVer.toString().substring(3);
            estado.image = "assets/img/estados/BAJA_TEMPERATURA.jpg"

        } else if ((tramaVer >= 1.11) && (tramaVer <= 1.19)) {
            estado.text = this.variables[2] ? this.variables[2] : "ALARMA ALTA TEMPERATURA SENSOR " + tramaVer.toString().substring(3);
            estado.image = "assets/img/estados/TEMPERATURA_ALTA.gif";

        } else if ((tramaVer >= 2.01) && (tramaVer <= 2.09)) {
            estado.text = this.variables[3] ? this.variables[3] :  "ALARMA DESCONEXION SENSOR " + tramaVer.toString().substring(3);
            estado.image = "assets/img/estados/DESCONEXION_SENSOR.jpg";

        } else if ((tramaVer >= 2.11) && (tramaVer <= 2.19)) {
            estado.text = this.variables[4] ? this.variables[4] : "ALARMA CONEXION SENSOR " + tramaVer.toString().substring(3);

            //pending
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";


        } else if (tramaVer == 1.20) {
            estado.text = this.variables[5] ? this.variables[5] : "ALARMA CAMBIO ESTADO ENTRADA DIGITAL";
            estado.image = "assets/img/estados/CAMBIO_ENTRADA_DIGITAL.gif";


        } else if (tramaVer == 1.23) {
            estado.text =this.variables[6] ? this.variables[6] :  "CANTIDAD DE SENSORES NO PERMITIDO EN 1-W";

            //pending
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";

        } else if (tramaVer == 1.24) {
            estado.text =this.variables[7] ? this.variables[7] :  "AUSENCIA DE SENSORES 1-W ";

            //pending
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        } else if (tramaVer == 3.00) {
            estado.text = this.variables[8] ? this.variables[8] : "LECTURA RFID";
            //pending
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        } else if (tramaVer == 3.01) {
            estado.text =this.variables[9] ? this.variables[9] :  "ALARMA LIMITE SUPERIOR";
            //pending
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        } else if (tramaVer == 3.02) {
            estado.text = this.variables[10] ? this.variables[10] : "ALARMA LIMITE INFERIOR";
            //pending
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 4.00){
            estado.text =this.variables[11] ? this.variables[11] :  "ALARMA POR MOVIMIENTO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 4.01){
            estado.text = this.variables[12] ? this.variables[12] :  "ALARMA POR CONEXIÓN DEL EQUIPO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 4.02){
            estado.text = this.variables[13] ? this.variables[13] : "ALARMA POR DESCONEXIÓN DEL EQUIPO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 4.03){
            estado.text = this.variables[14] ? this.variables[14] : "FIN DE MOVIMIENTO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 5.00){
            estado.text = this.variables[15] ? this.variables[15] : "CAÍDA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 6.00){
            estado.text = this.variables[16] ? this.variables[16] : "CLICK";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 17){
            estado.text = this.variables[17] ? this.variables[17] : "ENCENDIDO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }

        else if(tramaVer == 18){
            estado.text = this.variables[18] ? this.variables[18] : "APAGADO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }

        else if(tramaVer == 19){
            estado.text = this.variables[19] ? this.variables[19] : "COLLAR CERRADO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }

        else if(tramaVer == 25){
            estado.text = this.variables[31] ? this.variables[31] : "ENTRADA A GEOCERCA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }

        else if(tramaVer == 26){
            estado.text = this.variables[32] ? this.variables[32] : "SALIDA DE GEOCERCA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }


        else if(tramaVer == 87){
            estado.text =this.variables[20] ? this.variables[20] :  "COLLAR ABIERTO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }

        else if(tramaVer == 1000){
            estado.text = this.variables[21] ? this.variables[21] : "GLUCOSA ALTA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }
        else if(tramaVer == 1001){
            estado.text = this.variables[22] ? this.variables[22] : "GLUCOSA BAJA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }

        else if(tramaVer == 7000 ){
            estado.text = this.variables[23] ? this.variables[23] : "DOCUMENTACIÓN INCORRECTA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

        else if(tramaVer == 10000 ){
            estado.text = this.variables[25] ? this.variables[25] : "ENCENDIDO TRANSFERENCIA POTENCIA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

        else if(tramaVer == 10001 ){
            estado.text = this.variables[26] ? this.variables[26] : "APAGADO TRANSFERENCIA POTENCIA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

        else if(tramaVer == 10002 ){
            estado.text = this.variables[27] ? this.variables[27] : "ENCENDIDO PLANTA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

        else if(tramaVer == 10003 ){
            estado.text = this.variables[28] ? this.variables[28] : "APAGADO PLANTA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }
        else if(tramaVer == 10004 ){
            estado.text = this.variables[29] ? this.variables[29] : "ENCENDIDO PORTADORA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }
        else if(tramaVer == 10005 ){
            estado.text = this.variables[30] ? this.variables[30] : "APAGADO PORTADORA";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

        else if(tramaVer == 555 ){
            estado.text = this.variables[31] ? this.variables[31] : "DEPÓSITO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

        else if(tramaVer == 777 ){
            estado.text = this.variables[32] ? this.variables[32] : "RETIRO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

        else if(tramaVer == 444 ){
            estado.text = this.variables[33] ? this.variables[33] : "TX POR TIEMPO";
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
       
        }

         else {
            estado.text = this.variables[24] ? this.variables[24] : "MOTIVO NO REGISTRADO";
            //pending
            estado.image = "assets/img/estados/NINGUN_SENSOR_CONECTADO.jpg";
        }

        return estado;

    }
}
