import { Component, OnInit, Input, Output } from '@angular/core';

declare var $: any;
@Component({
    selector: 'tacometrocard-item',
    templateUrl: 'tacometrocard.html',
    styleUrls: ['./tacometrocard.css']

})

export class TacometroCardComponent implements OnInit {

    @Input() value: any;
     @Input() valueString: any;
    @Input() name: any;
    @Input() attributes: any;
    percentage = 0;

    constructor() { }
    ngOnInit() {

       // console.log(this.value);
        var comp = this;

        var max = this.attributes.maxRango;
        
        setTimeout( ()=>{
             runAllGauges();
        }, 4300);
       

        function runAllGauges() {
            var gauges = $('.gauge-cont');
            $.each(gauges, function (i: any, v) {
                var self = this;
                setTimeout(function () {
                    setGauge(self);
                }, i * 700);
            });
        }

        function resetAllGauges() {
            var gauges = $('.gauge-cont').get().reverse();
            $.each(gauges, function (i, v) {
                var self = this;
                setTimeout(function () {
                    resetGauge(self);
                }, i * 700);
            });
        }

        function resetGauge(gauge) {
            var spinner = $(gauge).find('.spinner');
            var pointer = $(gauge).find('.pointer');
            $(spinner).attr({
                style: 'transform: rotate(0deg)'
            });
            $(pointer).attr({
                style: 'transform: rotate(-90deg)'
            });
        }

        function setGauge(gauge) {
         //   var percentage = $(gauge).data('percentage') / max;
            var percentage = comp.value / max;
       
            var degrees = 180 * percentage;
            var pointerDegrees = degrees - 90;
            var spinner = $(gauge).find('.spinner');
            var pointer = $(gauge).find('.pointer');
            $(spinner).attr({
                style: 'transform: rotate(' + degrees + 'deg)'
            });
            $(pointer).attr({
                style: 'transform: rotate(' + pointerDegrees + 'deg)'
            });
        }


    }
}