import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { ApolloLink, from } from 'apollo-link';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { HttpHeaders } from '@angular/common/http';

@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
  ],
})
export class GraphQLModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    /*const uri = 'https://sns4.azlogica.com/graphql';
    const http = httpLink.create({ uri });

    apollo.create({
      link: http,
      cache: new InMemoryCache(),
    }); */

   const http = httpLink.create({
   //   uri: 'https://evolucion.azlogica.com:13302/graphql'
       uri: 'https://api3.azlogica.com/graphql'
      //  uri: 'https://sns4.azlogica.com/graphql'
    });

    // Create a WebSocket link:
    const ws = new WebSocketLink({
      uri: `wss://api3.azlogica.com:13302/subscriptions`,
      options: {
        reconnect: true
      }
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } : any = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      ws,
      http,
    );



    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      // we assume `headers` as a defined instance of HttpHeaders
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IkhFUk1FUy5NQVRFVVNAQVpMT0dJQ0EuQ09NIiwiRW1haWwiOiJIRVJNRVMuTUFURVVTQEFaTE9HSUNBLkNPTSIsImlhdCI6MTUxMjYwMTczNX0.kiu--v_1Ort50lmVSnWQmzPlUUDQQ6Nnd9QHiIM4_yU'),
      });

      return forward(operation);
    });


    apollo.create({
      link: from([authMiddleware, link]),

      cache: new InMemoryCache()
    }); 

  }
}