import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

import { SharedService } from "./global.service";
import { DynamoDBBeaconsService } from "./ddbbeacons.service";


/**
 * Created by Julian Montes
 */


declare var AWS: any;
declare var AWSCognito: any;

@Injectable()
export class DynamoDBIBeaconsService {

	static ddbb;

	constructor(private SharedService: SharedService, public ddb: DynamoDBBeaconsService) {
		console.log("DynamoDBIBeaconsService: constructor");
		DynamoDBIBeaconsService.ddbb = this.ddb;
	}

	getAWS() {
		return AWS;
	}

	follow(username, otheruser, $scope) {


		console.log(otheruser);
		var newOtherUser = otheruser;

		var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })
		var params = {
			TableName: 'Following',
			Key: {
				"FgCompanyFg": username
			},
			UpdateExpression: 'SET FgCompanyUsr = list_append(if_not_exists(FgCompanyUsr, :empty_list), :my_value)',
			ExpressionAttributeValues: {
				":my_value": [otheruser],
				":empty_list": []
			},
			ReturnValues: "UPDATED_NEW"

		};

		var compo = this;
		console.log(compo)

		console.log("Updating the item...");
		docClient.update(params, function (err, data) {
			if (err) {
				console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
				//	$scope(err, null);
			} else {
				console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
				compo.setFollow(newOtherUser, username, $scope);
				//	callback(null, data);

			}
		});



	}

	setFollow(otheruser, username, $scope) {
		var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })
		var params = {
			TableName: 'Followers',
			Key: {
				"FsCompanyUsr": otheruser
			},
			UpdateExpression: 'SET FsCompanyFg = list_append(if_not_exists(FsCompanyFg, :empty_list), :my_value)',
			ExpressionAttributeValues: {
				":my_value": [username],
				":empty_list": []
			},
			ReturnValues: "UPDATED_NEW"

		};

		console.log("Updating the item...");
		docClient.update(params, function (err, data) {
			if (err) {
				console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
				$scope.Tfollowing(err, null);

			} else {
				console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
				$scope.Tfollowing(null, data);

			}
		});

	}

	unfollow(username, otheruser, $scope, following) {

		var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
		//console.log(username);
		// console.log(otheruser);

		var actualPos = 0;
		for (var i = 0; i < following.length; i++) {

			if (following[i] == otheruser) {
				actualPos = i;
			}
		}

		var params = {
			TableName: 'Following',
			Key: {
				"FgCompanyFg": username
			},
			UpdateExpression: 'REMOVE FgCompanyUsr[' + actualPos + ']',
			ReturnValues: "UPDATED_NEW"

		};
		var compo = this;
		console.log("Updating the item...");

		docClient.update(params, function (err, data) {
			if (err) {
				console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
				//callback(err, null);
			} else {

				console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
				compo.deleteFollow(otheruser, username, following, $scope)
				//	callback(null, data);
			}
		});

	}

	deleteFollow(otheruser, username, following, $scope) {

		var actualPos = 0;


		var params = {
			TableName: "Followers",
			ProjectionExpression: "FsCompanyUsr,FsCompanyFg",
			KeyConditionExpression: "#FsCompanyUsr = :companyValue",
			ExpressionAttributeNames: {
				"#FsCompanyUsr": "FsCompanyUsr",

			},
			ExpressionAttributeValues: {
				":companyValue": otheruser,
			}
		};

		var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
		docClient.query(params, onQuery);


		function onQuery(err, data) {
			if (err) {
				console.log(err);
				console.error("DynamoDBeaconsService: Unable to query the table. Error JSON:", JSON.stringify(err, null, 2));
			}
			else {
				// print all the movies
				console.log("DynamoDBeaconsService: Query succeeded.");
				for (var i = 0; i < data.Items[0].FsCompanyFg.length; i++) {

					if (data.Items[0].FsCompanyFg[i] == username) {
						actualPos = i;
					}
				}
				var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })

				var params = {
					TableName: 'Followers',
					Key: {
						"FsCompanyUsr": otheruser
					},
					UpdateExpression: 'REMOVE FsCompanyFg[' + actualPos + ']',
					ReturnValues: "UPDATED_NEW"

				};

				console.log("Updating the item...");
				docClient.update(params, function (err, data) {
					if (err) {
						console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
						$scope.Tunfollowing(err, null);

						//	callback(null, data);
					} else {
						console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
						//	callback(err, null);
						$scope.Tunfollowing(null, data);


					}
				});



			}
		}
	}

	setFollowerRequest(username, otheruser, $scope) {

		console.log("setFollowersREquest");
		var newOtherUser = otheruser;

		var params = {
			TableName: 'FollowersRequest',
			Key: {
				"FrUsr": username
			},
			UpdateExpression: 'SET FrFollowers = list_append(if_not_exists(FrFollowers, :empty_list), :my_value)',
			ExpressionAttributeValues: {
				":my_value": [otheruser],
				":empty_list": []
			},
			ReturnValues: "UPDATED_NEW"

		};


		var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })

		docClient.update(params, function (err, data) {
			if (err) {
				console.error(err);
				$scope.TPending(err, null);
			} else {

				console.log("jejejeje", data);
				$scope.TPending(null, data);

			}
		});

	}


	unfollowPending(otheruser, username, $scope, followerspending) {

		var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
		//console.log(username);
		// console.log(otheruser);
		console.warn("posiciones!", followerspending);

		var actualPos = 0;
		for (var i = 0; i < followerspending.length; i++) {
			console.log(followerspending[i]);
			if (followerspending[i] == otheruser) {
				actualPos = i;
			}
		}




		var params = {
			TableName: 'FollowersRequest',
			Key: {
				"FrUsr": username
			},
			UpdateExpression: 'REMOVE FrFollowers[' + actualPos + ']',
			ReturnValues: "UPDATED_NEW"

		};
		var compo = this;
		// console.log(compo)

		// console.log("Updating the item...");

		console.log("Updating the item...");
		docClient.update(params, function (err, data) {
			if (err) {
				console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
				$scope.TPending(err, null);

				//	callback(null, data);
			} else {
				console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
				//	callback(err, null);
				$scope.TPending(null, data);


			}
		});



	}

	searchUser(val, mapArray) {
		var params = {
			TableName: "usuario",
			ProjectionExpression: "#UsCompany, UsUrl",
			FilterExpression: "contains( #UsCompany, :input)",
			ExpressionAttributeNames: {
				"#UsCompany": "UsCompany",
			},
			ExpressionAttributeValues: {
				":input": val
			},


		};

		var docClient = new AWS.DynamoDB.DocumentClient();
		docClient.scan(params, onQuery);

		var comp = this;

		function onQuery(err, data) {
			if (err) {

				console.log("err", err);

			}
			else {
				// print all the movies
				console.log("teeeeees", data)
				data.Items.forEach(function (user) {

					if (user.UsCompany == comp.SharedService.UsId) {

					}
					else {
						mapArray.push(user);

					}
					//	console.log(logitem.IbUrl);
				});



			}
		}

	}


	getFollowing($scope, mapArray: Array<String>, otherprofile, user) {

		if (!otherprofile) {

			user = this.SharedService.UsId;
		}


		var params = {
			TableName: "Following",
			//IndexName: "FgCompanyFg-index",
			ProjectionExpression: "FgCompanyFg,FgCompanyUsr",
			KeyConditionExpression: "#FgCompanyFg = :companyValue",
			ExpressionAttributeNames: {
				"#FgCompanyFg": "FgCompanyFg",

			},
			ExpressionAttributeValues: {
				":companyValue": user,
			}
		};

		var docClient = new AWS.DynamoDB.DocumentClient();
		docClient.query(params, onQuery);

		var comp = this;

		function onQuery(err, data) {
			if (err) {

				console.log("err", err);

			}
			else {
				// print all the movies

				if (!otherprofile) {
					console.log("success", data);
					data.Items[0].FgCompanyUsr.forEach(function (following) {

						//	console.log(logitem.IbUrl);
						mapArray.push(following);
					});
					$scope.ddb.SharedService.Following = data.Items[0].FgCompanyUsr;
				}
				else {
					console.log("succe222ss", data);

					data.Items[0].FgCompanyUsr.forEach(function (following) {


						//	console.log(logitem.IbUrl);
						mapArray.push(following);
					});

				}
			}
		}



	}

	getFollowers($scope, mapArray: Array<String>, otherprofile, user, isFollowing) {

		if (!otherprofile) {

			user = this.SharedService.UsId;
		}



		var params = {
			TableName: "Followers",
			ProjectionExpression: "FsCompanyUsr,FsCompanyFg",
			KeyConditionExpression: "#FsCompanyUsr = :companyValue",
			ExpressionAttributeNames: {
				"#FsCompanyUsr": "FsCompanyUsr",

			},
			ExpressionAttributeValues: {
				":companyValue": user,
			}
		};

		var docClient = new AWS.DynamoDB.DocumentClient();

		var comp = this;

		docClient.query(params, onQuery);


		function onQuery(err, data) {
			if (err) {

				console.log("err", err);

			}
			else {
				// print all the movies

				if (!otherprofile) {


					console.log("success", data);
					data.Items[0].FsCompanyFg.forEach(function (follower) {

						//	console.log(logitem.IbUrl);
						mapArray.push(follower);
					});
					$scope.ddb.SharedService.Followers = data.Items[0].FsCompanyFg;
				}
				else {



					console.log(data);
					data.Items[0].FsCompanyFg.forEach(function (follower) {
						if (follower == comp.SharedService.UsId) {
							console.log("lo està siguiendo");
							$scope.isFollowing = 'Following';
						}

						//	console.log(logitem.IbUrl);
						mapArray.push(follower);
					});

				}

			}
		}


	}

	getFollowersRequest($scope, mapArray, otherprofile, user) {
		if (!otherprofile) {

			user = this.SharedService.UsId;
		}


		var params = {
			TableName: "FollowersRequest",
			ProjectionExpression: "FrUsr,FrFollowers",
			KeyConditionExpression: "#FrUsr = :companyValue",
			ExpressionAttributeNames: {
				"#FrUsr": "FrUsr",

			},
			ExpressionAttributeValues: {
				":companyValue": user,
			}
		};

		var docClient = new AWS.DynamoDB.DocumentClient();

		var comp = this;

		docClient.query(params, onQuery);


		function onQuery(err, data) {
			if (err) {

				console.log("err", err);

			}
			else {
				// print all the movies

				if (!otherprofile) {


					console.log("success", data);
					data.Items[0].FrFollowers.forEach(function (follower) {

						//	console.log(logitem.IbUrl);
						mapArray.push(follower);
					});
					$scope.ddb.SharedService.FollowersRequest = data.Items[0].FrFollowers;
				}
				else {



					console.log(data);
					data.Items[0].FrFollowers.forEach(function (follower) {
						if (follower == comp.SharedService.UsId) {
							console.log("està pending");
							$scope.isFollowing = 'Pending';
						}

						//	console.log(logitem.IbUrl);
						mapArray.push(follower);
					});

				}

			}
		}




	}

	getFollowingBeacons($scope, mapArray) {

		var params = {
			TableName: "Following",
			//IndexName: "FgCompanyFg-index",
			ProjectionExpression: "FgCompanyFg,FgCompanyUsr",
			KeyConditionExpression: "#FgCompanyFg = :companyValue",
			ExpressionAttributeNames: {
				"#FgCompanyFg": "FgCompanyFg",

			},
			ExpressionAttributeValues: {
				":companyValue": this.SharedService.UsId,
			}
		};

		var docClient = new AWS.DynamoDB.DocumentClient();
		docClient.query(params, onQuery);

		function onQuery(err, data) {
			if (err) {

				console.log("err", err);

			}
			else {
				// print all the movies
				console.log("success", data);
				/*data.Items[0].FgCompanyUsr.forEach(function (following) {

					//	console.log(logitem.IbUrl);
					mapArray.push(following);
				}); */
				$scope.ddb.SharedService.Following = data.Items[0].FgCompanyUsr;

				console.log($scope.ddb.SharedService.Following);
				$scope.ddb.SharedService.Following.forEach(function (user) {

					var params = {
						TableName: "ibeacons",
						IndexName: "IbCompany-index",
						ProjectionExpression: "IbUMm,IbCompany,IbAlias,IbGroup,IbMac,IbName,IbUrl",
						KeyConditionExpression: "#IbCompany = :beaValue ",
						ExpressionAttributeNames: {
							"#IbCompany": "IbCompany"
						},
						ExpressionAttributeValues: {
							":beaValue": user
						}
					};

					var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });

					docClient.query(params, function (err, data) {

						if (err) { console.log("error", err) }
						else {
							console.log(data);
							data.Items.forEach(function (logitem) {

								//	console.log(logitem.IbUrl);
								mapArray.push({ umm: logitem.IbUMm, name: logitem.IbName, alias: logitem.IbAlias, idb: logitem.IbUMm + ".jpg", group: logitem.IbGroup, url: logitem.IbUrl, user: logitem.IbCompany , userUrl: 'https://s3.amazonaws.com/azlogica-iris/' +  logitem.IbCompany });
							});



							$scope.ddb.SharedService.FollowingBeacons = data.Items;


						}





					});

				});
			}
		}

		//  console.log(following);
		console.log($scope.ddb.SharedService.Following);
		/*	$scope.ddb.SharedService.Following.forEach(function (user) {
	
				var params = {
					TableName: "ibeacons",
					IndexName: "IbCompany-index",
					ProjectionExpression: "IbUMm,IbCompany,IbAlias,IbGroup,IbMac,IbName,IbUrl",
					KeyConditionExpression: "#IbCompany = :beaValue ",
					ExpressionAttributeNames: {
						"#IbCompany": "IbCompany"
					},
					ExpressionAttributeValues: {
						":beaValue": user
					}
				};
	
				var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
	
				docClient.query(params, function (err, data) {
	
					if (err) { console.log("error", err) }
					else {
						console.log(data);
						data.Items.forEach(function (logitem) {
	
							//	console.log(logitem.IbUrl);
							mapArray.push({ umm: logitem.IbUMm, name: logitem.IbName, alias: logitem.IbAlias, idb: logitem.IbUMm + ".jpg", group: logitem.IbGroup, url: logitem.IbUrl, user: logitem.IbCompany });
						});
	
	
	
						$scope.ddb.SharedService.FollowingBeacons = data.Items;
	
	
	
						/*	data.Items.forEach(function (beacon) {
		
								console.log(user);
								var params = {
									TableName: 'decode-data',
									ProjectionExpression: "DdUMmIB,DdAliasIB,DdAddressDV,DdLatDV,DdLongDV,DdDatetimeServer,DdStatusIB,DdCompanyIB,DdTstamp,DdAliasDV,DdNameIB,DdIbUrlIB",
									KeyConditionExpression: "DdUMmIB = :DdUMmIB",
									ExpressionAttributeValues: {
										":DdUMmIB": beacon.IbUMm,
										":DdCompanyIB": user
									},
									FilterExpression: " DdCompanyIB =  :DdCompanyIB",
		
									ScanIndexForward: false,
									Limit: 1
								};
								var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
								docClient.query(params, function (err, data) {
									if (err) { console.log(err); }
									else {
		
										data.Items.forEach(function (logitem) {
		
											//	console.log(logitem.IbUrl);
											mapArray.push({ umm: logitem.IbUMm, name: logitem.IbName, alias: logitem.IbAlias, idb: logitem.IbUMm + ".jpg", group: logitem.IbGroup, url: logitem.IbUrl });
										});
		
		
		
										$scope.ddb.SharedService.Beacons = data.Items;
		
									}
								});
								//console.log("Beacon a Consultar--->"+key.IbUMm);
							}); 
	}





});

		}); */

	}

	getBeacons($scope, mapArray, otherprofile, user) {
		console.log("DynamoDBService: reading from IBeacons with creds - " + AWS.config.credentials);

		console.log("Usuario->>" + this.SharedService.UsId);

		if (!otherprofile) {
			user = this.SharedService.UsId;
		}

		var params = {
			TableName: environment.ddbBeaconsTableName,
			IndexName: "IbCompany-index",
			ProjectionExpression: "IbUMm,IbCompany,IbAlias,IbGroup,IbMac,IbName,IbUrl,IbIcon,IbIconName",
			KeyConditionExpression: "#IbCompany = :beaValue ",
			ExpressionAttributeNames: {
				"#IbCompany": "IbCompany"
			},
			ExpressionAttributeValues: {
				":beaValue": user
			}
		};
		var docClient = new AWS.DynamoDB.DocumentClient();
		docClient.query(params, onQuery);

		function onQuery(err, data) {
			if (err) {

				console.log(err);
				console.error("DynamoDBeaconsService: Unable to query the table. Error JSON:", JSON.stringify(err, null, 2));
			}
			else {
				// print all the movies
				console.log("DynamoDBeaconsService: Query succeeded.");

				data.Items.forEach(function (logitem) {

					//	console.log(logitem.IbUrl);
					mapArray.push({ umm: logitem.IbUMm, name: logitem.IbName, alias: logitem.IbAlias, idb: logitem.IbUMm + ".jpg", group: logitem.IbGroup, url: logitem.IbUrl , icon : logitem.IbIcon, iconName: logitem.IbIconName });
				});

				if (!otherprofile) {


					$scope.ddb.SharedService.Beacons = data.Items;
					$scope.ddb.SharedService.Beacons2 = "reel";
				}
				//console.log($scope.ddb.SharedService.Beacons);
				console.log(mapArray);
			}
		}


	}

	updateBeacon(url, uuid) {
		console.log("Usuario->>" + this.SharedService.UsId);

		//console.log(uuid);

		var docClient = new AWS.DynamoDB.DocumentClient()
		//var actualUser = $.CognitoUtil.getUserPool().getCurrentUser().username ;

		var params = {
			TableName: environment.ddbBeaconsTableName,
			Key: {
				"IbUMm": uuid,
				"IbCompany": this.SharedService.UsId
			},
			UpdateExpression: "set IbUrl = :url",
			ExpressionAttributeValues: {
				":url": url
			},
			ReturnValues: "UPDATED_NEW"
		};

		console.log("Updating the item...");
		docClient.update(params, function (err, data) {
			if (err) {
				console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
			} else {
				console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
				DynamoDBIBeaconsService.ddbb.updateLogEntry(uuid, url);
			}
		});

	}
}
