import { Injectable, style } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class HistoricExport {

    constructor() { }

    public exportHistoricExcel(json: any[], excelFileName: string) {
        console.log("YOU ARE ON MEDIUM METHOD");
        
        var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        this.wrapAndCenterCell(worksheet.B2);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles:true });
        
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private wrapAndCenterCell(cell: XLSX.CellObject) {
        const wrapAndCenterCellStyle = { alignment: { wrapText: true, vertical: 'center', horizontal: 'center' } };
        this.setCellStyle(cell, wrapAndCenterCellStyle);
      }

      private setCellStyle(cell: XLSX.CellObject, style: {}) {
        cell.s = style;
      }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        console.log("YOU´RE ON LATEST-LATEST:");
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + EXCEL_EXTENSION);   
    }
    
    public exportHistoricCsv(items, fileTitle, header){

      console.log("YOU´RE EXPORT HISTORIC CSV"); 

    var csv = this.convertToCSV(items,header);

    //var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
      console.log("CSV READY TO DOWNLOAD: ",csv);
    var exportedFilenmae = fileTitle + '_export_' + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(blob,exportedFilenmae);      
  }

  public convertToCSV(items, header?){

    // var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var obj = items;
    var str = '';
    var arr = [];   
     console.log("YOU`RE CONVERT OBJ TO STR ",obj);

    obj.map((item)=>{
        arr = []
        for(let i in item){
          arr.push(item[i]);
        }
        arr.map( (item, index) => {
          var line = item;
          if(index == arr.length-1){

          }
          else {
            line+= ",";
          }
         str += line;
        })

        str += '\n';
    })
    
    str = header + '\n' + str;
    
    return str;

  }
}
