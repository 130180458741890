import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment';
/**
 * Created by Julian Montes 
 */

@Injectable()
export class SharedService {
  public UsId = '';
  public UsParameters = [];
  public Beacons = null;
  public FollowingBeacons = null;
  public Beacons2 = "llllllllllllllllllllllllllQffffXXX";
  public Followers = [];
  public Following = [];
  public FollowersRequest = [];

  public myDomain = environment.enterprise;

  public domains = {
    "tigo": {
      name: 'Tigo Une',
      subcolor: '#1565C0',
      color: '#0D47A1',
      border: '2px #1565C0 solid'
    },
    "evolucion": {
      name: "Evolucion",
      subcolor : '#689F38',
      color: '#558B2F',
      border: '2px #558B2F solid'
    }
  }
}
 
