import { Component } from "@angular/core";
import { CognitoCallback, LoggedInCallback, UserLoginService, UserParametersService, Callback, UserRegistrationService } from "../../service/cognito.service";
import { Router } from "@angular/router";

import { SharedService } from "../../service/global.service";
import { DynamoDBIOTService } from "../../service/ddbiot.service";
import { CompleterService, CompleterData } from 'ng2-completer';


declare var AWS: any;
import { v4 as uuidv4 } from 'uuid';
import { GraphQLService } from "app/service/graphql.service";
export class RegistrationUser {
    name: string;
    email: string;
    password: string;
}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './admin.html',
    styleUrls: ['./admin.css']
})


export class AdminComponent implements LoggedInCallback, CognitoCallback {


    tiposEquipos = [
        {
            id: "1",
            name: "delsat"
        },
        {
            id: "2",
            name: "gunnebo"
        },
        {
            id: "3",
            name: "glory"
        }
    ]
    registrationUser: RegistrationUser;
    router: Router;
    errorMessage: string;

    iotAccount: boolean = false;
    adminAccount: boolean = false;

    enterprises = [];
    enterprise = "";
    userParameters: any;

    regionals = [];
    cities = [];
    devices = [];

    regionales = [];
    ciudades = [];

    firstPushEnterprises = true;
    usersPermissions = [];

    myFormedData = {};
    loadingInfoUser = false;

    users = []
    controlPoints = [];

    nombreCiudad;

    nombreRegional;

    isModalActive = false;

    isModalEditActive = false;

    isModalEditUserActive = false;


    isModalDeletePointActive = false;
    isModalDeleteUserActive = false;

    isModalCreateClient = false;
    isModalCreateCity = false;
    isModalCreateRegional = false;

    pointToEdit;

    editingUser;

    public cognitoId: String;

    task: any = {
        name: 'Marcar todos/ Desmarcar todos',
        completed: true,
        color: 'green',
        subtasks: [

        ]
    };


    newpoint = {
        DgIdTr: null,
        mac: null,
        name: null,
        id: null,
        direction: null,
        id_tr: null,
        nombre_tr: null,

        climit: null,
        cvalue: null

    }



    permissionsTask: any = {
        name: 'Marcar todos/ Desmarcar todos',
        completed: false,
        color: 'green',
        subtasks: [
             {
                type: 7,
                name: "SOLO VISUALIZAR TRANSMISIÓN DE DISPOSITIVOS",
                completed: false,
            },
            {
                type: 0,
                name: "ADMINISTRADOR DE USUARIOS",
                completed: false,
            },
            {
                type: 1,
                name: "RETIROS",
                completed: true,
            },
            {
                type: 2,
                name: "DEPÓSITOS",
                completed: true,
            },
            {
                type: 3,
                name: "ÙLTIMAS COMMUNICACIONES",
                completed: true,
            },
            {
                type: 4,
                name: "ESTADO DISPOSITIVO",
                completed: true,
            },
            {
                type: 5,
                name: "ABONO EFECTIVO VALIDADO",
                completed: true,
            },
            {
                type: 6,
                name: "FIN DE DÍA",
                completed: true,
            }

        ]
    };

    allComplete: boolean = true;
    allCompletePermissions: boolean = true;


    nombreCliente;


    selectedCity;
    selectedRegional;
    selectedDevice;
    selectedEnterprise;
    selectedTipoEquipo;

    public searchStr: string;
    public searchStrCities: string;
    public searchStrRegional: string;
    public searchStrDevices: string;
    public searchStrTipoEquipo: string;
    searchStrEnterprise: string;

    showUserPermissions = false;
    actualEnterprise;
    companies = [];

    clients = [];


    pointToDelete = null;
    userToDelete = null;

    public dataService: CompleterData;
    public dataServiceCities: CompleterData;
    public dataServiceRegional: CompleterData;
    public dataServiceDevices: CompleterData;
    public dataServiceEnterprise: CompleterData;
    public dataServiceTipoEquipo: CompleterData;


    selectedCitiesArr = [];


    newPointName = "";
    checkIfAdmin(user){
     
        //console.log("actualUserrrr!", user);

        var admin = false;
        user.userPermissions.user.map( (item)=>{
            if(item.name == 'ADMINISTRADOR DE USUARIOS'){
                if(item.completed == true){
                    //console.log("admin!");
                    this.iotAccount = true;
                    admin = true;
                }
            }
        })

        if(admin == false){

            //console.log("no es iot account!!!!!!!")
            this.router.navigate(['/securehome']);
        }
        else {
            //console.log("SI ES IOT ACCOUNT!!!", user)
        }

    }

    checkIfDevices() {
        var actualUser = localStorage.getItem("actualUser");

        actualUser = JSON.parse(actualUser);


        //console.log("ACtual USER!!!!!", actualUser)

    }

    openModalDeletePoint(point) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        this.isModalDeletePointActive = true;

        this.pointToDelete = point;
    }

    openModalDeleteUser(user) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        this.isModalDeleteUserActive = true;

        this.userToDelete = user;
    }

    deletePoint() {

        this.ddb.deletePoint(this.pointToDelete, (err, data) => {
            if (!err) {

                this.graphqlService.deleteRedshiftPoint(this.pointToDelete).subscribe((khee) => {
                    //console.log("kheee?", khee)
                })

                this.getAllPoints()

                alert("Punto de venta eliminado")



            }
            else {
                alert("Error eliminando punto de venta")
            }
        })

    }



    deleteUser() {

        this.ddb.deleteUser(this.userToDelete, (err, data) => {
            if (!err) {

                this.getAllUsers();

                alert("usuario eliminado")



            }
            else {
                alert("Error eliminando ")
            }

            this.disableModalDeleteUser();
        })

    }


    disableModalDeletePoint() {
        this.isModalDeletePointActive = false;
    }
    disableModalDeleteUser() {
        this.isModalDeleteUserActive = false;
    }
    openModal() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.isModalActive = true;
    }

    openModalCreateClient() {

        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.isModalCreateClient = true;
    }
    disableModalClient() {
        this.isModalCreateClient = false;
    }

    openModalCreateCity() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.isModalCreateCity = true;
    }
    disableModalCity() {
        this.isModalCreateCity = false;
    }

    openModalCreateRegional() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.isModalCreateRegional = true;
    }

    disableModalRegional() {
        this.isModalCreateRegional = false;
    }


    addClient() {

        if (this.nombreCliente) {
            this.ddb.addClient(this.nombreCliente, (err, data) => {
                if (!err) {

                    var _newItem = {
                        cl_codigo: data.newId,
                        cl_nombre: this.nombreCliente

                    }
                    this.graphqlService.addCompanyRedshift(_newItem).subscribe((khee) => {
                        //console.log("kheee?", khee)
                    })




                    var newId = data.newId;

                    //   this.getAllClients();

                    this.ddb.getAllClients((err, data) => {
                        if (!err) {
                            var clients = {};
                            data.Items.map((item) => {
                                // if(item.id == 'felipe.castillo@azlogica.com' ||  item.id == 'pipech161@gmail.com'  ||  item.id == 'pipech1616@gmail.com'  ||  item.id == 'pipech2020@gmail.com' ){

                                //}
                                //else {
                                clients[item.id] = item;
                                // }
                            })

                            clients[newId] = {
                                id: newId,
                                name: this.nombreCliente
                            };

                            // this.clients = clients;

                            this.ddb.editUserEnterprises(clients, (err, data) => {

                                if (!err) {

                                    this.getAllClients();

                                }
                                else {

                                }

                            });









                        }
                    }, [], null)

                    alert("Añadido con exito"); +
                        this.disableModalClient();
                }
                else {
                    alert("Error en la creación");
                }
            })
        }
        else {
            alert("Rellena los campos solicitados")
        }


    }

    addCity() {
        if (this.nombreCiudad && this.nombreCiudad.length >= 1) {

            this.ddb.addCity(this.nombreCiudad, (err, data) => {
                if (!err) {

                    var _newItem = {
                        cd_codigo: data.newId,
                        cd_nombre: this.nombreCiudad

                    }
                    this.graphqlService.addCityRedshift(_newItem).subscribe((khee) => {
                        //console.log("kheee?", khee)
                    })


                    this.getAllCities();

                    alert("Añadido con exito");

                    this.disableModalCity();
                }
                else {
                    alert("Error en la creación");
                }
            })


        }

        else {
            alert("Rellena los campos solicitados")
        }

    }

    addRegional() {

        if(this.nombreRegional  && this.nombreRegional.length >= 1){
        if (this.searchStrEnterprise && this.selectedCitiesArr.length >= 1) {
            var selectedClientId;


            this.clients.map((item) => {
                if (item.name == this.searchStrEnterprise) {
                    selectedClientId = item.id;
                }
            })


            var newArrItemList = [];

            this.ciudades.map((item) => {
                this.selectedCitiesArr.map((cityStr) => {
                    if (item.name == cityStr.value) {
                        newArrItemList.push(item.id);
                    }
                });
            })

            //console.log("selectedCLientÇId", selectedClientId)



            //console.log("arrList", this.selectedCitiesArr);

            //console.log("newwArrList", newArrItemList)

            this.ddb.addRegional(selectedClientId, this.nombreRegional, newArrItemList, (err, data) => {
                if (!err) {

                    var _newItem = {
                        rg_codigo: data.newId,
                        rg_nombre: this.nombreRegional,
                        cl_codigo: selectedClientId,
                        cd_codigos: newArrItemList

                    }
                    this.graphqlService.addRegionalRedshift(_newItem).subscribe((khee) => {
                        //console.log("kheee?", khee)
                    })


                    this.getAllRegionals();

                    alert("Añadido con exito");

                    this.disableModalRegional();
                }
                else {
                    alert("Error en la creación");
                }
            })


        }
        else {
            alert("Debes seleccionar una empresa y por lo menos una ciudad.")
        }
        
        }
        else {
            alert("Rellena los campos solicitados");

        }
    }

    selectMultipleCities() {

        this.selectedCitiesArr.push({ value: null });

    }
    editUser() {


        var newEnterprises = {};

        this.task.subtasks.map((item) => {
            if (item.completed == true) {
                newEnterprises[item.id] = item;
            }
        })


        //console.log("editEnterprise", newEnterprises)

        console.log("My Item Enterprise", newEnterprises);

        var _newEnterprises = [];
        for (let item in newEnterprises) {

            var _item = newEnterprises[item];

            _item.regionals.regionals.map((regionalItem, regionalIdx) => {
                if (regionalItem.completed == false   || regionalItem.completed == undefined ) {
                    newEnterprises[item].regionals.allComplete = false;
                }

                regionalItem.cities.cities.map((cityItem, cityIdx) => {
                    if (cityItem.completed == false  ||  cityItem.completed == undefined) {
                        newEnterprises[item].regionals.regionals[regionalIdx].cities.allComplete = false;
                    }

                    cityItem.devices.devices.map((deviceItem) => {
                        if (deviceItem.completed == false || deviceItem.completed == undefined ) {
                            newEnterprises[item].regionals.regionals[regionalIdx].cities.cities[cityIdx].devices.allComplete = false;
                        }
                    })
                })
            })

        }

        for (let item in newEnterprises) {

            var _item = newEnterprises[item];

            _item.regionals.regionals.map((regionalItem, regionalIdx) => {

                regionalItem.cities.cities.map((cityItem, cityIdx) => {

                    if (cityItem.devices.allComplete == true) {

                        newEnterprises[item].regionals.regionals[regionalIdx].cities.cities[cityIdx].devices.devices = [];
                    }
                    else {
                        var newDevices = [];

                        cityItem.devices.devices.map((deviceItem) => {
                            if (deviceItem.completed) {
                                newDevices.push(deviceItem)
                            }
                        });

                        newEnterprises[item].regionals.regionals[regionalIdx].cities.cities[cityIdx].devices.devices = newDevices;


                    }


                })
            })

        }


        //console.log("new Enterprises!", newEnterprises);
        //console.log("new Permissions!", this.permissionsTask.subtasks);

        this.ddb.editUser(this.editingUser.id, newEnterprises, { user: this.permissionsTask.subtasks }, (err, data) => {

            if (!err) {
                this.resetUser();
                this.disableModalEditUser();

                /*
                this.users.map( ()=>{
                    
                })

                */

                this.getAllUsers();

                alert("Usuario actualizado con éxito");
            }
            else {
                alert("Error actualizando usuario. " + err);
            }

        });



    }

    resetUser() {
        this.permissionsTask = {
            name: 'Marcar todos/ Desmarcar todos',
            completed: false,
            color: 'green',
            subtasks: [
                {
                    type: 7,
                    name: "SOLO VISUALIZAR TRANSMISIÓN DE DISPOSITIVOS",
                    completed: false,
                },
                {
                    type: 0,
                    name: "ADMINISTRADOR DE USUARIOS",
                    completed: false,
                },
                {
                    type: 1,
                    name: "RETIROS",
                    completed: true,
                },
                {
                    type: 2,
                    name: "DEPÓSITOS",
                    completed: true,
                },
                {
                    type: 3,
                    name: "ÙLTIMAS COMMUNICACIONES",
                    completed: true,
                },
                {
                    type: 4,
                    name: "ESTADO DISPOSITIVO",
                    completed: true,
                },
                {
                    type: 5,
                    name: "ABONO EFECTIVO VALIDADO",
                    completed: true,
                },
                {
                    type: 6,
                    name: "FIN DE DÍA",
                    completed: true,
                }

            ]
        };

        this.task = {
            name: 'Marcar todos/ Desmarcar todos',
            completed: true,
            color: 'green',
            subtasks: [

            ]
        };

        this.firstPushEnterprises = true;

        this.getEnterprises();
    }




    openModalEditUser(user) {


        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.showUserPermissions = false;
        this.isModalEditUserActive = true;


        this.editingUser = user;
        //console.log("user", user);

        this.permissionsTask.subtasks.map((item) => {
            user.userPermissions.user.map((userItem) => {
                if (item.type == userItem.type) {
                    item.completed = userItem.completed
                }
            })
        })


        //console.log("task", this.task)

        var tempTask = this.task;

        this.task.subtasks.map((item, idx) => {

            tempTask.subtasks[idx].completed = false;
            // item.completed = false;
            tempTask.subtasks[idx].allComplete = false;
            //  item.regionals.allComplete = false;

            item.regionals.regionals.map((regional, idxRegional) => {
                //regional.completed = false;

                tempTask.subtasks[idx].regionals.regionals[idxRegional].completed = false;
                tempTask.subtasks[idx].regionals.regionals[idxRegional].cities.allComplete = false;

                item.regionals.regionals[idxRegional].cities.cities.map((city, idxCity) => {


                    tempTask.subtasks[idx].regionals.regionals[idxRegional].cities.cities[idxCity].completed = false;
                    tempTask.subtasks[idx].regionals.regionals[idxRegional].cities.cities[idxCity].devices.allComplete = false;

                    
                    item.regionals.regionals[idxRegional].cities.cities[idxCity].devices.devices.map((device, idxDevice) => {

                        tempTask.subtasks[idx].regionals.regionals[idxRegional].cities.cities[idxCity].devices.devices[idxDevice].completed = false;
                               

                    });


                });

            })

            console.log("user!", user)

            for (let i in user.companies) {
                var companyItem = user.companies[i];




                if (item.id == i && companyItem.regionals) {
                    //console.log("yes!");

                    //console.log("companyItem", companyItem);
                    //console.log("item", item)
                    //item.completed = true;

                    tempTask.subtasks[idx].completed = companyItem.regionals.completed;
                    tempTask.subtasks[idx].allComplete = companyItem.regionals.allComplete;


                    //item.regionals.allComplete = companyItem.regionals.allComplete;
                    //item.regionals.completed = companyItem.regionals.completed;


                    item.regionals.regionals.map((regional, idxRegional) => {

                        tempTask.subtasks[idx].regionals.regionals[idxRegional].completed = false;

                        regional.completed = false;
                        companyItem.regionals.regionals.map((regionalUser) => {
                            if (regional.id == regionalUser.id) {

                                tempTask.subtasks[idx].regionals.regionals[idxRegional].completed = regionalUser.completed;
                                // regional.completed = regionalUser.completed
                            }

                        })


                        item.regionals.regionals[idxRegional].cities.cities.map((city, idxCity) => {

                            if (companyItem.regionals.regionals[idxRegional]) {

                                companyItem.regionals.regionals[idxRegional].cities.cities.map((cityItem) => {

                                    if (city.id == cityItem.id) {
                                        tempTask.subtasks[idx].regionals.regionals[idxRegional].cities.cities[idxCity].completed = cityItem.completed;
                                    }
                                })


                                item.regionals.regionals[idxRegional].cities.cities[idxCity].devices.devices.map((device, idxDevice) => {



                                    if (companyItem.regionals.regionals[idxRegional].cities.cities[idxCity]) {
                                        device.completed = false;


                                        companyItem.regionals.regionals[idxRegional].cities.cities[idxCity].devices.devices.map((deviceItem) => {

                                            if (device.DgMac == deviceItem.DgMac) {
                                                tempTask.subtasks[idx].regionals.regionals[idxRegional].cities.cities[idxCity].devices.devices[idxDevice].completed = deviceItem.completed;
                                            }
                                        })
                                    }

                                })
                            }

                        })
                    })


                }


            }

        });


        this.task = tempTask;


        this.showUserPermissions = true;
        //console.log("this.newTask", this.task);
    }

    openModalEdit(point) {

        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.isModalEditActive = true;

        this.pointToEdit = point;

        if(point.DgDeviceType && point.DgDeviceType != undefined){

        
        this.onSelectedTipoEquipo({ item: { name: point.DgDeviceType}, originalObject:  { name: point.DgDeviceType} })
        this.searchStrTipoEquipo = point.DgDeviceType

    }

        this.ddb.getTransportadora(point.DgIdTr, (err, data) => {

            if (!err) {

                var _item = data.Items[0];
                this.newpoint = {
                    DgIdTr: point.DgIdTr,
                    mac: point.DgMac,
                    name: point.DgName,
                    direction: point.DgAddress,
                    id: point.DgAnclaPointId,
                    id_tr: _item.id_tr,
                    nombre_tr: _item.name,
                    cvalue: point.DgAttributes["cantacumbilletes"]["advertencia"].value,
                    climit: point.DgAttributes["cantacumbilletes"]["advertencia"].limit
                }

            
            }
        }, [], null)


        this.enterprises.map((item) => {
            if (item.id == point.DgCompany) {
                this.onSelectedEnterprise({ ...item, originalObject: item })
                this.searchStrEnterprise = item.name
            }
        })

        setTimeout(() => {


            this.regionals.map((item) => {

                if (item.id == point.DgRegionalId) {
                    this.onSelectedRegional({ ...item, originalObject: item })
                    this.searchStrRegional = item.name
                }
            })
        }, 2000);

        setTimeout(() => {

            this.cities.map((item) => {

                if (item.id == point.DgCityId) {
                    this.onSelectedCities({ ...item, originalObject: item })
                    this.searchStrCities = item.name
                }
            })
        }, 4000);

    }

    disableModalEditUser() {
        this.isModalEditUserActive = false;

        this.resetUser();
    }

    disableModalEdit() {
        this.isModalEditActive = false;
    }

    disableModal() {
        this.isModalActive = false;
    }

    resetNewPoint() {
        this.newpoint = {
            DgIdTr: null,
            mac: null,
            name: null,
            id: null,
            direction: null,
            id_tr: null,
            nombre_tr: null,
            climit: null,
            cvalue: null
        }
    }

    editPoint() {

        //console.log("tipoEquipo", this.selectedTipoEquipo)
        if (this.selectedEnterprise && this.selectedRegional && this.selectedCity
            && this.newpoint.id && this.newpoint.mac && this.newpoint.name && this.newpoint.direction && this.newpoint.id_tr && this.newpoint.nombre_tr
            && this.newpoint.cvalue && this.newpoint.climit && this.selectedTipoEquipo
        ) {
            //console.log("paso todo!");

            this.newpoint.id_tr = this.newpoint.id_tr.toUpperCase();

            var idTr = this.pointToEdit.DgIdTr;

            var trParams = {
                id: idTr,
                id_tr: this.newpoint.id_tr,
                name: this.newpoint.nombre_tr
            }

            this.ddb.getIdTr(this.newpoint.id_tr.toUpperCase(), (err, data) => {

                //console.log("data items idtr", data);
                if (!err) {

                    if (data.Items[0] && data.Items[0].id != idTr ) {

              

                        this.ddb.getDevice(data.Items[0].id , (err, dataDevice) => {

                            if(!err){

                                
                                alert("Este ID TRANSPORTADORA ya se encuentra asignada al dispositivo con Nombre: " + dataDevice.Items[0].DgName + " , MAC: " + dataDevice.Items[0].DgMac)

                            }
                            else {
                                alert("error buscando id transportadora");
                            }
                        });

                        
                    
                    }
                    else {


                        this.ddb.editTransportadora(trParams, (err, data) => {


                            //console.log("err", err);
                            //console.log("data", data);
                            if (!err) {

                                console.log("selectedCity!!!!", this.selectedCity)


                                var params = {
                                    "DgAddress": this.newpoint.direction,
                                    "DgDeviceType": this.selectedTipoEquipo.name,
                                    "DgAlias": this.newpoint.name.toUpperCase(),
                                    "DgAnclaPointId": this.newpoint.id,
                                    "DgCityId": this.selectedCity.id,
                                    "DgCompany": this.selectedEnterprise.id,
                                    "DgMac": this.newpoint.mac.toLowerCase(),
                                    "DgName": this.newpoint.name.toUpperCase(),
                                    "DgRegionalId": this.selectedRegional.id,

                                    "mac": this.pointToEdit.DgMac,

                                    "cvalue": this.newpoint.cvalue,
                                    "climit": this.newpoint.climit,
                                    "DgIdTr": this.newpoint.DgIdTr
                                }

                                //console.log("gonna update device now!")

                                this.ddb.updateDevice(params, (err, data) => {

                                    //console.log("err", err);
                                    //console.log("data", data);
                                    if (!err) {
                                        // this.controlPoints.push(params)



                                        var _newItem = {
                                            name: params.DgName,
                                            address: params.DgAddress,
                                            id: params.DgAlias,
                                            id_tr: trParams.id_tr,
                                            nombre_tr: trParams.name,
                                            id_transportadora: trParams.id,
                                            mac: params.mac,

                                            regional_id: params.DgRegionalId,
                                            enterprise_id: params.DgCompany,
                                            city_id: params.DgCityId,

                                            cvalue: params.cvalue,
                                            climit: params.climit,

                                            device_type: params.DgDeviceType

                                        }
                                        this.graphqlService.updateRedshiftDevice(_newItem).subscribe((khee) => {
                                            //console.log("kheee?", khee)
                                        })



                                        this.resetNewPoint();

                                        this.disableModalEdit()

                                        this.getAllPoints();



                                        alert("Punto de venta actualizado con éxito");



                                    }
                                    else {

                                        //console.log("err!!!", err);
                                        alert("Error actualizando el punto de venta. " + err)
                                    }
                                })

                            }

                        });

                    }

                }
            }, [], null);



        }
        else {



            alert("Falta llenar 1 o más campos, todos los campos son obligatorios.")
        }






    }


    addPoint() {



        //console.log("newPoint", this.newpoint);
        if (this.selectedEnterprise && this.selectedRegional && this.selectedCity
            && this.newpoint.id && this.newpoint.mac && this.newpoint.name && this.newpoint.direction && this.newpoint.id_tr && this.newpoint.nombre_tr
            && this.newpoint.climit && this.newpoint.cvalue
        ) {
            //console.log("paso todo!");

            this.newpoint.id_tr = this.newpoint.id_tr.toUpperCase();
            this.ddb.getIdTr(this.newpoint.id_tr.toUpperCase(), (err, data) => {


                if (!err) {

                    if (data.Items[0]) {
                        alert("Este ID TRANSPORTADORA ya se encuentra asignada a un dispositivo.")

                    }
                    else {


                        var idTr = uuidv4();

                        var trParams = {
                            id: idTr,
                            id_tr: this.newpoint.id_tr,
                            name: this.newpoint.nombre_tr
                        }


                        this.ddb.addTransportadora(trParams, (err, data) => {

                            if (!err) {
                                var params = {
                                    "DgAddress": this.newpoint.direction,
                                    "DgAlias": this.newpoint.name.toUpperCase(),
                                    "DgAnclaPointId": this.newpoint.id,
                                    "DgDeviceType": this.selectedTipoEquipo.name,

                                    "DgAttributes": {

                                        "cantacumbilletes": {
                                            "advertencia": {
                                                "limit": this.newpoint.climit,
                                                "limitPercentage": "70%",
                                                "renderExcessColor": "red",
                                                "value": this.newpoint.cvalue
                                            },
                                            "alias": "% de bolsa",
                                            "factorConversion": "1*{valor}",
                                            "maxRango": 600,
                                            "minRango": 20,
                                            "render": "texto",
                                            "renderRealtime": "line",
                                            "representacion": "Decimal",
                                            "text": true,
                                            "unidad": " "
                                        },
                                        "cantBilletes": {
                                            "alias": "Cantidad de billetes",
                                            "factorConversion": "1*{valor}",
                                            "maxRango": 600,
                                            "minRango": 20,
                                            "render": "texto",
                                            "renderRealtime": "line",
                                            "representacion": "Decimal",
                                            "text": true,
                                            "unidad": " "
                                        },
                                        "CURRENCY_CODE": {
                                            "alias": "Código moneda",
                                            "factorConversion": "1*{valor}",
                                            "maxRango": 600,
                                            "minRango": 20,
                                            "render": "texto",
                                            "renderRealtime": "line",
                                            "representacion": "Decimal",
                                            "text": true,
                                            "unidad": " "
                                        },
                                        "denominations": {
                                            "alias": "Denominaciones de moneda",
                                            "factorConversion": "1*{valor}",
                                            "maxRango": 600,
                                            "minRango": 20,
                                            "render": "texto",
                                            "renderRealtime": "line",
                                            "representacion": "Decimal",
                                            "text": true,
                                            "unidad": " "
                                        },
                                        "monto": {
                                            "alias": "Moneda",
                                            "factorConversion": "1*{valor}",
                                            "maxRango": 600,
                                            "minRango": 20,
                                            "render": "texto",
                                            "renderRealtime": "line",
                                            "representacion": "Decimal",
                                            "text": true,
                                            "unidad": " "
                                        },
                                        "nameUser": {
                                            "alias": "Usuario",
                                            "factorConversion": "1*{valor}",
                                            "maxRango": 600,
                                            "minRango": 20,
                                            "render": "texto",
                                            "renderRealtime": "line",
                                            "representacion": "Decimal",
                                            "text": true,
                                            "unidad": " "
                                        },
                                        "number": {
                                            "alias": "Id. Movimiento",
                                            "factorConversion": "1*{valor}",
                                            "maxRango": 600,
                                            "minRango": 20,
                                            "render": "texto",
                                            "renderRealtime": "line",
                                            "representacion": "Decimal",
                                            "text": true,
                                            "unidad": " "
                                        }
                                    },
                                    "DgCityId": this.selectedCity.id,
                                    "DgCompany": this.selectedEnterprise.id,
                                    "DgGroup": 1,
                                    "DgIdTr": idTr,
                                    "DgImage": " ",
                                    "DgImageColor": "rgba(222,184,135,1.000)",
                                    "DgLatitude": "0",
                                    "DgLongitude": "0",
                                    "DgMac": this.newpoint.mac.toLowerCase(),
                                    "DgName": this.newpoint.name.toUpperCase(),
                                    "DgRegionalId": this.selectedRegional.id,
                                    "DgTz": "America/Bogota",
                                    "DgCreationDate": new Date().toString()
                                };

                                this.ddb.addDevice(params, (err, data) => {
                                    if (!err) {
                                        this.controlPoints.push(params);



                                        var _newItem = {
                                            name: params.DgName,
                                            address: params.DgAddress,
                                            id: params.DgAlias,
                                            id_tr: trParams.id_tr,
                                            nombre_tr: trParams.name,
                                            id_transportadora: trParams.id,
                                            mac: params.DgMac,

                                            regional_id: params.DgRegionalId,
                                            enterprise_id: params.DgCompany,
                                            city_id: params.DgCityId,
                                            device_type: params.DgDeviceType

                                        }
                                        this.graphqlService.addRedshiftDevice(_newItem).subscribe((khee) => {
                                            //console.log("kheee?", khee)
                                        })


                                        this.resetNewPoint();
                                        alert("Punto de venta creado con éxito");

                                        this.disableModal();
                                    }
                                    else {
                                        alert("Error creando el punto de venta.")
                                    }
                                })
                            }


                        })

                    }
                }
            }, [], null);
        }
        else {


            alert("Falta llenar 1 o más campos, todos los campos son obligatorios.")
        }
        /*
        {
            "DgAddress": "",
            "DgAlias": "MER0416",
            "DgAnclaPointId": "1326",
            "DgAttributes": {
              "cantBilletes": {
                "alias": "Cantidad de billetes",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
              },
              "CURRENCY_CODE": {
                "alias": "Código moneda",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
              },
              "denominations": {
                "alias": "Denominaciones de moneda",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
              },
              "monto": {
                "alias": "Moneda",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
              },
              "nameUser": {
                "alias": "Usuario",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
              },
              "number": {
                "alias": "Id. Movimiento",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
              }
            },
            "DgCityId": "450afa8a-e06c-4911-84db-65d9a4425d85",
            "DgCompany": "ee61ddcb-6701-45f4-a487-a4b52dcdb708",
            "DgGroup": 1,
            "DgIdTr": "9cb8cb5c-97d8-4e9f-9a90-2d267572976f",
            "DgImage": " ",
            "DgImageColor": "rgba(222,184,135,1.000)",
            "DgLatitude": "0",
            "DgLongitude": "0",
            "DgMac": "00-50-c2-59-c9-e9",
            "DgName": "GUADUAS",
            "DgRegionalId": "26551700-04db-4ef6-8da8-69f0718dbca9",
            "DgTz": "America/Bogota"
          }

          */



    }


    getAllClients() {
        this.ddb.getAllClients((err, data) => {
            if (!err) {
                var clients = [];
                data.Items.map((item) => {
                    // if(item.id == 'felipe.castillo@azlogica.com' ||  item.id == 'pipech161@gmail.com'  ||  item.id == 'pipech1616@gmail.com'  ||  item.id == 'pipech2020@gmail.com' ){

                    //}
                    //else {
                    clients.push(item)
                    // }
                })
                this.clients = clients;


                this.clients.sort((a, b) => a.name.localeCompare(b.name))



                //console.log("clientsssss", this.clients);
                this.dataServiceEnterprise = this.completerService.local(this.clients, 'name', 'name');

                this.getAllCities();

            }
        }, [], null)


    }

    getAllCities() {
        this.ddb.getAllCities((err, data) => {
            if (!err) {
                var ciudades = [];
                data.Items.map((item) => {

                    var _item = item;


                    ciudades.push(_item)

                })
                this.ciudades = ciudades;


                this.dataServiceCities = this.completerService.local(this.ciudades, 'name', 'name');


                this.getAllRegionals();


            }
        }, [], null)
    }

    getAllRegionals() {
        this.ddb.getAllRegionals((err, data) => {
            if (!err) {
                var regionales = [];
                data.Items.map((item) => {

                    var _item = item;

                    this.clients.map((clientItem) => {
                        if (clientItem.id == item.id_company) {

                            //console.log("si entro!");
                            _item.enterprise = clientItem;
                        }
                    })

                    regionales.push(_item)

                })
                this.regionales = regionales;


            }
        }, [], null)
    }



    getAllPoints() {
        this.ddb.getAllControlPoints((err, data) => {
            if (!err) {
                var controlPoints = [];
                data.Items.map((item) => {
                    // if(item.id == 'felipe.castillo@azlogica.com' ||  item.id == 'pipech161@gmail.com'  ||  item.id == 'pipech1616@gmail.com'  ||  item.id == 'pipech2020@gmail.com' ){

                    //}
                    //else {
                    controlPoints.push(item)
                    // }
                })
                this.controlPoints = controlPoints;


                //   this.pushEnterprises();

            }
        }, [], null)
    }

    getAllUsers() {
        this.ddb.getAllUsers((err, data) => {
            if (!err) {
                var newUsers = [];
                data.Items.map((item) => {
                    if (item.id == 'felipe.castillo@azlogica.com' || item.id == 'pipech161@gmail.com' || item.id == 'pipech1616@gmail.com' || item.id == 'pipech2020@gmail.com') {
                        newUsers.push(item)
                    }
                    else {
                        newUsers.push(item)
                    }
                })
                this.users = newUsers;
            }
        }, [], null)
    }


    updateAllCompletePermissions(item, allComplete) {

        if (item) {

        }
        else {
            this.allCompletePermissions = this.permissionsTask.subtasks != null && this.permissionsTask.subtasks.every(t => t.completed);
        }

    }




    someCompletePermissions(): boolean {
        if (this.task.subtasks == null) {
            return false;
        }
        return this.permissionsTask.subtasks.filter(t => t.completed).length > 0 && !this.allCompletePermissions;
    }

    setAllPermissions(completed: boolean, other) {

        if (this.permissionsTask.subtasks == null) {
            return;
        }

        if (other) {

        }
        else {
            this.permissionsTask.subtasks.forEach(t => t.completed = completed);
        }

    }

    updateAllComplete(item, subtask, checked) {

        if (subtask) {

            this.setAll(checked, subtask)

        }

        if (item) {

        }
        else {
            this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
        }

    }

    someComplete(): boolean {
        if (this.task.subtasks == null) {
            return false;
        }
        return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
    }


    setAllDevices(completed, other, regional, city) {
        //console.log("regional", regional);
        //console.log("other, ", other)
        var subIndex;
        this.task.subtasks.map((subtask, _subIndex) => {
            if (other && other.id == subtask.id) {
                subIndex = _subIndex;
            }
        })
        var _index;



        //console.log("sub", subIndex)

        //console.log("index", this.task.subtasks);
        this.task.subtasks[subIndex].regionals.regionals.map((item, idx) => {
            if (item.id == regional.id) {
                _index = idx;
            }
        });

        var indexDevice;
        this.task.subtasks[subIndex].regionals.regionals[_index].cities.cities.map((item, idx) => {
            if (item.id == city.id) {
                indexDevice = idx;
            }
        });



        this.task.subtasks[subIndex].regionals.regionals[_index].cities.cities[indexDevice].devices.devices.forEach(t => t.completed = completed);


    }

    setAllCities(completed, other, regional) {
        //console.log("regional", regional);
        //console.log("other, ", other)
        var subIndex;
        this.task.subtasks.map((subtask, _subIndex) => {
            if (other && other.id == subtask.id) {
                subIndex = _subIndex;
            }
        })
        var _index;



        //console.log("sub", subIndex)

        //console.log("index", this.task.subtasks);
        this.task.subtasks[subIndex].regionals.regionals.map((item, idx) => {
            if (item.id == regional.id) {
                _index = idx;
            }
        });




        this.task.subtasks[subIndex].regionals.regionals[_index].cities.cities.forEach(t => t.completed = completed);

    }

    setAll(completed: boolean, other) {
        this.allComplete = completed;

        var subIndex;
        this.task.subtasks.map((subtask, _subIndex) => {
            if (other && other.id == subtask.id) {
                subIndex = _subIndex;
            }
        })

        //console.log("subIndex", subIndex)
        if (other) {


            this.task.subtasks[subIndex].allComplete = completed
        }

        if (this.task.subtasks == null) {
            return;
        }

        if (other) {
            this.task.subtasks[subIndex].regionals.regionals.forEach(t => t.completed = completed);
        }
        else {
            this.task.subtasks.forEach(t => t.completed = completed);
        }

    }

    changeUserDisabled(val, user) {

        var _val = !val;
        this.ddb.updateUserDisabled(_val, user, (err, data) => {

        })
    }

    constructor(public graphqlService: GraphQLService, private completerService: CompleterService, public userRegistration: UserRegistrationService, router: Router, private SharedService: SharedService, public ddb: DynamoDBIOTService, private UserParametersService: UserParametersService) {

        this.router = router;

        this.dataServiceTipoEquipo = this.completerService.local(this.tiposEquipos, 'name', 'name');

        this.getActualEnterprise();
        this.onInit()


        this.checkIfDevices();

        this.getAllUsers();

        this.getAllPoints();

        this.getAllClients();

 

        this.setEnterprise = this.setEnterprise.bind(this);
        this.setUser = this.setUser.bind(this);
        this.getEnterprises();
        //   this.UserParametersService.getParameters(this.parametersCallback);
        this.UserParametersService.getParameters(this.setParameters.bind(this));

        //console.log(this.SharedService.UsParameters);

        this.iotAccount = true;
        if (this.SharedService.UsId == 'iot@azlogica.com' || this.SharedService.UsId == 'pipech161@gmail.com') {
            this.iotAccount = true;

        }
    }








    getActualEnterprise() {
        //console.log("obteniendo actual user!!!")
        this.ddb.getActualUser(this.SharedService.UsId, this.actualUserSuccess.bind(this), [], null);
    }

    actualUserSuccess(err, data) {
        //console.log("mi dataaa from actual user", data);

        if (err) {
            //console.log("Err retriving user", err);

            var admin = false;
            if(admin == false){

                //console.log("no es iot account!!!!!!!")
                this.router.navigate(['/securehome']);
            }
            else {
              
            }
            
        }
        else {
            if (data.Items[0]) {
                
                this.checkIfAdmin(data.Items[0]);

                localStorage.setItem("actualUser", JSON.stringify(data.Items[0]))

                this.companies = [];

                var comps = data.Items[0].companies;

                for (let key in comps) {
                    this.companies.push(comps[key]);
                }

                this.companies.sort((a, b) => a.name.localeCompare(b.name))



                this.dataServiceEnterprise = this.completerService.local(this.companies, 'name', 'name');

                this.getSavedEnterprise(data.Items[0].companies);


            }
            else {
                var admin = false;
                if(admin == false){

                    //console.log("no es iot account!!!!!!!")
                    this.router.navigate(['/securehome']);
                }
                else {
                  
                }
            }
        }
    }

    getSavedEnterprise(companies) {



        var _savedEnterprise = localStorage.getItem("savedEnterprise");

        if (_savedEnterprise) {

            this.actualEnterprise = JSON.parse(_savedEnterprise);
        }
        else {

            var _first = true;
            for (let key in companies) {

                if (_first) {

                    this.actualEnterprise = companies[key]


                    localStorage.setItem("savedEnterprise", JSON.stringify(this.actualEnterprise));

                    _first = false;
                }
            }
            //this.actualEnterprise = companies[0]
        }

        //console.log("ACTUAL ENTERPRISE!", this.actualEnterprise);

    }

    onSelectedEnterprise(evt) {
        //console.log("selected!", evt)

        this.selectedEnterprise = null;


        setTimeout(() => {


            this.selectedEnterprise = evt.originalObject;

            //console.log("changeEnterprise");



            if (this.selectedEnterprise) {

                //console.log("changeEnterprise2");

                this.actualEnterprise = this.selectedEnterprise;

                //console.log("actualEnterprise", this.actualEnterprise);

                localStorage.setItem("savedEnterprise", JSON.stringify(this.actualEnterprise));


                this.searchStrRegional = null;
                this.selectedRegional = null;

                this.searchStrCities = null;
                this.selectedCity = null;


                this.searchStrDevices = null;
                this.selectedDevice = null;

                this.getRegionals();

                //  this.selectedEnterprise = null;

                //   location.reload();

            }

        }, 1000);
    }
    getRegionals() {
        this.ddb.getRegionals(this.onGetRegionals.bind(this), [], null)
    }
    onGetRegionals(err, data) {
        if (err) {

        }
        else {
            if (data.Items) {


                data.Items.sort((a, b) => a.name.localeCompare(b.name))



                //console.log("ongetcities Success", data);
                this.regionals = [
                    /*
                    {
                        id: "none",
                        name: "TODOS",
                        all: true
                    },
                    */
                    ...data.Items
                ]

                this.dataServiceRegional = this.completerService.local(this.regionals, 'name', 'name');
            }
        }
    }

    onSelectedRegional(evt) {

        if (evt && evt.originalObject) {




            this.selectedRegional = evt.originalObject;

            this.searchStrCities = null;
            this.selectedCity = null;


            this.searchStrDevices = null;
            this.selectedDevice = null;



            this.dataServiceCities = null;

            this.ddb.getCities(this.selectedRegional, this.onGetCities.bind(this));


        }

    }

    onGetCities(err, data) {
        if (err) {

        }
        else {

            //console.log("data!!!", data);
            //  if (data.Items) {

            data.Responses.city.sort((a, b) => a.name.localeCompare(b.name))


            //console.log("ongetcities Success", data);
            this.cities = [
                /*
                {
                    id: "none",
                    name: "TODAS",
                    all: true
                },
                */
                ...data.Responses.city
            ]

            this.dataServiceCities = this.completerService.local(this.cities, 'name', 'name');
            //   }
        }
    }
    onSelectedCities(evt) {
        //console.log("Selected city", evt);

        if (evt && evt.originalObject) {

            this.selectedDevice = null;

            //console.log("Selected city", evt);

            setTimeout(() => {

                this.selectedCity = evt.originalObject;

                console.log("selected City", this.selectedCity)
            }, 1000);
        }
    }

    onSelectedTipoEquipo(evt){
        //console.log("selected tipo equipo, ", evt);

        if(evt && evt.originalObject){
            this.selectedTipoEquipo = evt.originalObject;
        }
    }

































    setParameters(data) {
        //console.log("this.SharedService.UsId ", this.SharedService.UsId);
        //console.log("paramsfromset!", data);
        if (data[5].Value) {
            this.enterprise = data[5].Value;
        }

        this.userParameters = data;

    }
    getEnterprises() {
        this.enterprises = [];
        //console.log("getEnterprises");
        this.ddb.getEnterprises(this.enterprises, this.enterprisesSuccess.bind(this), [], null);

    }
    returnActualEnterprise(enterprise) {

        if (enterprise.IcEmail == this.enterprise) {
            return true;
        }
        else {
            return false;
        }
    }

    pushEnterprises() {
        if (this.firstPushEnterprises == true) {
            this.enterprises.forEach((data) => {




                var regionals = [];
                for (let _enterprise in this.myFormedData) {


                    if (data.id == _enterprise) {
                        regionals = this.myFormedData[_enterprise];

                        //     //console.log("found!!!!!", _enterprise)
                    }
                }

                regionals.map((_regional) => {
                    _regional.completed = true


                    var _cities = [];

                    _regional.id_cities.map((item) => {

                        this.cities.map((city) => {
                            if (city.id == item) {

                                var devices = [];

                                var newCity = {
                                    ...city,
                                    allComplete: true,
                                    completed: true,
                                    devices: {
                                        allComplete: true,
                                        completed: true,
                                        devices: devices
                                    }


                                }


                                this.controlPoints.map((device) => {

                                    // //console.log("device!!!!!!", device);

                                    if (device.DgCityId == city.id && device.DgRegionalId == _regional.id) {
                                        //      //console.log("yes!!!!!!!!!!!!!!!!!!!!!", device)
                                        devices.push({ ...device, completed: true })
                                    }
                                })

                                _cities.push(newCity)
                            }
                        })
                    })


                    _regional.cities = {
                        allComplete: true,
                        completed: true,
                        cities: _cities
                    }
                })


                //   //console.log("-----------------regional---------------------", regionals);
                data.regionals = {
                    allComplete: true,
                    completed: true,

                    regionals
                };

                //  //console.log("enterprieITEM!", data);
                this.task.subtasks.push({ ...data, completed: true });
            }, this);


            //console.log("finishedCheck", this.task);

            this.firstPushEnterprises = false;

            //  //console.log("taskFormed", this.task);

            this.loadingInfoUser = true;
        }


    }
    enterprisesSuccess() {

        this.ddb.getAllRegionals(this.regionalsSuccess.bind(this), [], null);



        //console.log("enterpriseSuccess");
        //console.log(this.enterprises);
        this.enterprises.forEach((data) => {

            //console.log("enterprieITEM!", data);
            if (data.IcEmail == this.SharedService.UsId) {
                this.adminAccount = true;
            }


        }, this);

    }

    regionalsSuccess(err, data) {
        if (!err) {
            this.regionals = data.Items;

            this.ddb.getAllCities(this.onCities.bind(this), [], null);

        }
    }

    onCities(err, data) {
        if (!err) {

            this.cities = data.Items;

            this.transformData();
        }
    }



    transformData() {

        this.loadingInfoUser = true;

        //console.log("enterprises", this.enterprises);

        this.enterprises.map((_enterprise) => {

            this.myFormedData[_enterprise.id] = []

        })

        //regionals
        this.regionals.map((_regional) => {

            //  _regional.cities = [];

            _regional.id_cities.map((id_city) => {

                this.cities.map((_city) => {

                    if (_city.id == id_city) {
                        //   _regional.cities.push(_city)
                    }

                })
            })

            //console.log("regional!!!", _regional)
            //console.log("formedData", this.myFormedData)

            this.myFormedData[_regional.id_company].push(_regional)

        });

        this.ddb.getAllControlPoints((err, data) => {
            if (!err) {
                var controlPoints = [];
                data.Items.map((item) => {
                    // if(item.id == 'felipe.castillo@azlogica.com' ||  item.id == 'pipech161@gmail.com'  ||  item.id == 'pipech1616@gmail.com'  ||  item.id == 'pipech2020@gmail.com' ){

                    //}
                    //else {
                    controlPoints.push(item)
                    // }
                })
                this.controlPoints = controlPoints;



                this.pushEnterprises();
                //   this.pushEnterprises();

            }
        }, [], null)

        //console.log("formed Data!", this.myFormedData);





    }

    returnUsers(data) {

        var _string = "";
        data.map((item) => {
            _string += item + " , "
        })
        return _string;
    }
    onInit() {
        this.registrationUser = new RegistrationUser();
        this.errorMessage = null;
    }

    /*

    onRegister() {
        this.errorMessage = null;
        this.ddb.registerUser(this.registrationUser.email, this.onRegistered.bind(this));
        this.userRegistration.adminRegister(this.registrationUser, this);

        //this.userRegistration.register(this.registrationUser, this);
    }

    */


    onRegister() {
        this.errorMessage = null;
        this.ddb.registerEnterprise(this.registrationUser.name, this.onRegistered.bind(this));
        // this.userRegistration.adminRegister(this.registrationUser, this);

        //    this.userRegistration.register(this.registrationUser, this);
    }

    onRegisterUser() {


        this.errorMessage = null;
        //console.log(this.task);

        var newEnterprises = {};

        this.task.subtasks.map((item) => {
            if (item.completed == true) {
                newEnterprises[item.id] = item;
            }
        })

        for (let item in newEnterprises) {

            var _item = newEnterprises[item];

            _item.regionals.regionals.map((regionalItem, idx) => {
                if (regionalItem.completed == false) {
                    newEnterprises[item].regionals.allComplete = false;
                }


                var newCities = [];

                regionalItem.cities.cities.map((cityItem, indexCity) => {
                    if (cityItem.completed == false) {
                        newEnterprises[item].regionals.regionals[idx].cities.allComplete = false;
                    }
                    else {
                        newCities.push(cityItem);

                    }


                })

                if (newEnterprises[item].regionals.regionals[idx].cities.allComplete == true) {
                    newCities = [];
                }



                newEnterprises[item].regionals.regionals[idx].cities.cities = newCities;



            })

        }

        for (let item in newEnterprises) {

            var _item = newEnterprises[item];

            _item.regionals.regionals.map((regionalItem, idx) => {
                regionalItem.cities.cities.map((cityItem, indexCity) => {

                    var newDevices = [];

                    cityItem.devices.devices.map((deviceItem) => {
                        if (deviceItem.completed == false) {
                            newEnterprises[item].regionals.regionals[idx].cities.cities[indexCity].devices.allComplete = false;
                        }
                        else {
                            newDevices.push(deviceItem);
                        }
                    })
                    if (newEnterprises[item].regionals.regionals[idx].cities.cities[indexCity].devices.allComplete == true) {
                        newDevices = []
                    }
                    newEnterprises[item].regionals.regionals[idx].cities.cities[indexCity].devices.devices = newDevices;

                });

            });
        }
        //console.log("new Enterprises!", newEnterprises);
        //console.log("new Permissions!", this.permissionsTask.subtasks);

        this.ddb.registerUser(this.registrationUser.email, newEnterprises, { user: this.permissionsTask.subtasks }, this.onRegistered.bind(this));
        this.userRegistration.adminRegisterUser(this.registrationUser, this.SharedService.UsId, this.enterprise, this);

    }
    onRegistered() {
        this.getEnterprises();
    }

    checkUserPermissions(user) {

        if (!this.usersPermissions[user]) {
            this.ddb.getUserPermissions(user, this.userSuccess.bind(this), user, [], null);
        }


    }
    userSuccess(err, data, user) {
        if (!err) {
            if (data.Items[0]) {
                //console.log("entreeee");
                //console.log(data.Items[0]);
                this.usersPermissions[user] = data.Items[0].IpPermissions;
            }

        }

    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { //error
            this.errorMessage = message;
            //console.log("result: " + this.errorMessage);
        } else { //success
            //console.log(result);
            //move to the next step

            if (this.iotAccount) {
                alert("Añadido con éxito")
                //    this.ddb.setEnterprise(this.registrationUser.name, this.registrationUser.email.toLowerCase(), this.setEnterprise);
            }

            if (this.adminAccount) {
                alert("Añadido con éxito")
                this.ddb.setUserEnterprise(this.userParameters[2].Value, this.registrationUser.email.toLowerCase(), this.setUser);
            }
            //console.log("redirecting");
            //  this.router.navigate(['/home/confirmRegistration', result.user.username]);
        }
    }

    setEnterprise(err, data) {
        //console.log(err);
        //console.log(data);

        this.getEnterprises();
    }
    setUser(err, data) {
        this.getEnterprises();
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);


        } else {
            //console.log(this.SharedService.UsId);



        }
    }

    parametersCallback(data) {
        //console.log("parameterrrs!");
        //console.log(data);
    }
}


/*

{
  "IcCompany": "AZLOGICA",
  "IcEmail": "iotempresa@azlogica.com",
  "IcUsers": [
    "alexander.ortiz@azlogica.com",
    "maria.luque@azlogica.com",
    "julian.montes@azlogica.com",
    "aescobar@anditel.com",
    "glopez@anditel.com",
    "garias@anditel.com",
    "diana.zapata@azlogica.com",
    "krystian.marentes@azlogica.com",
    "felipe.zuniga@azlogica.com",
    "jairo.vera@azlogica.com",
    "juan.dionisio@azlogica.com",
    "oscar.otalora@azlogica.com",
    "lisbeth.gomez@azlogica.com",
    "patricia.fontalvo@azlogica.com",
    "juan.zapata@sed.international",
    "raul.martinez@azlogica.com",
    "manuel.flechas@azlogica.com",
    "jrsoto@campoestrategico.com",
    "daniela.torres@azlogica.com",
    "angela.velosa@azlogica.com",
    "alejandra.orozco@azlogica.com",
    "jose.echeverria@azlogica.com",
    "cristian.matallana@azlogica.com",
    "augusto.peredo@azlogica.com",
    "miguel.marin@phaxsi-solutions.com",
    "fernando.gazaffi@azlogica.com",
    "mauricio@cleverideas.com.mx",
    "claudia.gonzalez@azlogica.com",
    "felipe.castillo@azlogica.com"
  ]
}


{
  "IcCompany": "Elite Flower",
  "IcEmail": "palvarez@eliteflower.com",
  "IcUsers": [
    "juan.zapata@sedinternational"
  ]
}


{
  "IcCompany": "COLOMBIA FARM",
  "IcEmail": "jrsoto@campoestrategico.com",
  "IcUsers": []
}


*/

