import { Component, OnInit, Input, Output } from '@angular/core';

declare var $: any;

@Component({
    selector: 'compasscard-item',
    templateUrl: 'compasscard.html',
    styleUrls: ['./compasscard.css']

})

export class CompassCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;

    constructor() { }


    ngOnInit() {

        var rotation = 0;
        

       let _value  :string = this.value.trim();
        console.log(_value);

        if (_value == "N") {
      //      console.log("entree");
        }
        else if (_value == "NNE") {
          
            rotation = 22;
        }
        else if(_value == 'NE'){
            rotation = 60;
        }

        else if(_value == 'ENE'){
            rotation = 70;
        }

        else if (_value == "E") {
            rotation = 90
        }
        else if (_value == "ESE") {
            rotation = 112;
        }
        else if (_value == "SE") {
            rotation = 134;
        }
        else if (_value == "SSE") {
            rotation = 166;
        }
        else if (_value == "S") {
           
            rotation = 180;
        }
        else if (_value == "SSW") {
            rotation = 192;
        }
        else if (_value == "SW") {
            rotation = 214;
        }
        else if (_value == "WSW") {
            rotation = 236;
        }
        else if (_value == "W") {
            rotation = 270;
        }
        else if (_value == "WNW") {
            rotation = 292;
        }
        else if (_value == "NW") {
            rotation = 314;
        }
        else if (_value == "NNW") {
            rotation = 334;
        }
        else {
    //        console.log("invalido");
        }

   //     console.log(rotation);

        deviceOrientationHandler(null, null, rotation);


        function deviceOrientationHandler(tiltLR, tiltFB, dir) {
            var compassDisc = $("#arrow")[0];
        //    console.log(compassDisc);
            compassDisc.style.webkitTransform = "rotate(" + dir + "deg)";
            compassDisc.style.MozTransform = "rotate(" + dir + "deg)";
            compassDisc.style.transform = "rotate(" + dir + "deg)";
        }




    }
}