import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { LoggedInCallback, UserLoginService } from "../../service/cognito.service";
import { Router , ActivatedRoute} from "@angular/router";

import { DynamoDBIOTService } from "../../service/ddbiot.service";
import { Observable } from "rxjs";
import * as fromRoot from '../../common/index';
import * as layout from '../../common/layout/layout.actions';
import * as games from '../../common/games/games.actions';
import * as devices from '../../common/devices/devices.actions';

import { Store } from "@ngrx/store";
import timeago from 'timeago.js';

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { MatSort, MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material';

import { utils } from '../../lib/utils';

import { isEqual, differenceWith } from 'lodash';

declare var $: any;
declare var require: any;
var json = require('../../lib/form_ProyectoTorres.json');

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './form.html',
    styleUrls: ['./form.css']
})
export class FormComponent implements LoggedInCallback, OnInit {

    public inModal$: Observable<boolean>;
    public devices$: Observable<any>;
    selectedImage;
    tableColumns = [];
    inImageModal = false;
    mac;
    tstamp;
    form = null;
    actualDevice;
    willUpdate = false;
    private sub: any;

    constructor( public route: ActivatedRoute, public ddb: DynamoDBIOTService,  public dialog: MatDialog, private _utils: utils, private store: Store<fromRoot.AppState>, private el: ElementRef, public router: Router, public userService: UserLoginService) {

        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.inModal$ = store.select(fromRoot.getLayoutModalIsOpen);
        this.userService.isAuthenticated(this);

        this.onFormsData = this.onFormsData.bind(this);
        this.filter = this.filter.bind(this);
        
        this.store.dispatch(new layout.CloseModalAction(true));

        this.sub = this.route.params.subscribe(params => {
            this.mac = params['mac'];
            this.tstamp = params['tstamp'];

            this.actualDevice = {
                DgMac: ""
            }
            var comp = this;
            this.devices$.forEach((data) => {
                if (data != undefined) {
                    if (data[this.mac]) {
                        comp.actualDevice = data[this.mac]
                    }
                }
            })

            this.ddb.getDeviceFormsByTimestamp(this.mac,this.tstamp, this.onFormsData);
        });


        var test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];
        };
        timeago.register('test_local', test_local_dict);

    }

    downloadImage(item){

        console.log('start download:',item);
       // var url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = item.image;;
        a.target = "_blank";
        a.download = item.image;
        a.click();
        window.URL.revokeObjectURL(item.image);
        a.remove(); // remove the element
    
      //  const fileURL = window.URL.createObjectURL(item.image);
        //window.location.href = item.image;
    }

    extendImage(item){
        this.selectedImage = item.image;
        this.inImageModal = true;
    }
    filter(obj1: any, obj2: any) {
        let changes = differenceWith(obj1, obj2);
        console.log("changeees");
        console.log(changes);
    }

    closeModal(){
        this.inImageModal = false;
    }

    onFormsData(err, data){
        console.log(err);
        console.log(data);
        if(!err && data){
            this.form = data;
            /*this.form = {
                "FrName": "Formulario Proyecto Torres",
                "FrContent":   json,
                "TipoFormulario": data.TipoFormulario
            }; */
            console.log("new Form");
            this.migrateData();
        }
    }
    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, "test_local"); // returns '11 hours ago'
        // console.log(time);
        return time;
    }

    checkAllTableFields(){
        this.form.FrContent.content.map( (item)=>{
            if(item.type == 'Table'){
                console.log("table!!");
                item.value.content.map( (value)=>{

                    value.values.map( (col)=>{
                        if(col.type == 'date'){
                            console.log("encontre date!!!");
                            //console.log(col.value);
                            if(col.value){
                                col.value = col.value.toString();
                            }
                           
                        }
                    })
                  
                });
            }
        })
        this.willUpdate = false;
    }
    updateDeviceForm(){
        this.willUpdate = true;
        this.checkAllTableFields();
       
        this.ddb.updateAssignedForm(this.form, this.onUpdatedDeviceForm.bind(this));
    }
    onUpdatedDeviceForm(err,data){
        if(err){
            alert("Error actualizando formulario, intente de nuevo");
        }
        else {
            alert("Formulario Actualizado con éxito");
        }
    }
    migrateData(){

        //CHECK FIRST VALUE TITLES, TABLES, ETC...
        this.form.TipoFormulario.TfFormulario.content.map( (item, index)=>{
            if(!this.form.FrContent.content[index]){
                this.form.FrContent.content.push(item);
            }
            if(this.form.FrContent.content[index].id !=  item.id){
                this.form.FrContent.content.splice( index, 0 , item);
            }
        }); 
        //CHECK TABLES 
        this.form.TipoFormulario.TfFormulario.content.map( (item, index)=>{
            if(item.type == 'Table'){
                //CHECK FILES TITLES 
                this.form.FrContent.content[index].value.titles = item.value.titles;
            
                item.value.content.map( (file, fileI)=>{

                    //CHECK ROWS
                    if(!this.form.FrContent.content[index].value.content[fileI]){
                        this.form.FrContent.content[index].value.content[fileI] = file;
                    }

                    //CHECK FILES
                    file.values.map( (fileItem, fileIndex)=>{
                        if(!this.form.FrContent.content[index].value.content[fileI].values[fileIndex]){
                            this.form.FrContent.content[index].value.content[fileI].values[fileIndex] = fileItem
                        }
                    });

                    
                   

                }); 
            }
        }); 
        console.log("finisheeeed");
        console.log(this.form)

    }

    valuechange(value, i , j ,k){
       console.log(value);
        this.form.FrContent.content[i].value.content[j].values[k].value = value.toString();
        console.log(this.form);
    }

    ngOnInit(): void {
    }

  
    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            console.log("scanning Devices");
            //GET
        }
    }



}

