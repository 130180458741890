import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, deviceActual: any): any[] {
    if(!items) return [];
    if(!searchText) return items;

    console.log(deviceActual);
    searchText = searchText.toLowerCase();

   

return items.filter( it => {
    console.log("it");
        console.log(it);
        var name = it.variable;
        var n = "";
        if (deviceActual.DgAttributes) {
            if (deviceActual.DgAttributes[name]) {
                n = deviceActual.DgAttributes[name].alias;
            }
        }
        
        return n.toLowerCase().includes(searchText);
    });
   }
}