import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { catchError, retry, map } from 'rxjs/operators';
//import { pipe } from '@angular/core/src/render3/pipe';

var Firebase = require('firebase');

//INSERT FIREBASE  RECORD (REALTIME DATABASE)
const config = {
  apiKey: "AIzaSyDsFNqW6CCTbRSsyyaI35PHfMICv7mgKGQ",
  authDomain: "iot-gpio.firebaseapp.com",
  databaseURL: "https://iot-gpio.firebaseio.com",
  projectId: "iot-gpio",
  storageBucket: "iot-gpio.appspot.com",
  messagingSenderId: "362455784399"
};


Firebase.initializeApp(config);

declare var AWS: any;
declare var AWSCognito: any;

@Injectable()
export class GraphQLService {

  url = 'https://api3.azlogica.com/graphql';
  urlServer3 = 'https://sns4.azlogica.com/graphql';
  clientId = "e700697c-e007-4f18-9993-08f9e0a3cde8";

  constructor(public http: HttpClient) { }


  generateToken(report_id, token, embedData) {


    const formData = {
      "datasets": [{
        "id": embedData['datasetId'] //dataSetId
      }],
      "reports": [{
        "id": embedData['id'] //id
      }]
    };

    let httpOptions = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + token
      })


    };

    return this.http.post<any>(`https://api.powerbi.com/v1.0/myorg/GenerateToken`,


      formData,
      httpOptions)
      .pipe(catchError(this.handleErrorTwo))



    /*
    let httpOptions = {
  
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
  
  
    };
  
    return this.http.post<any>('https://api.powerbi.com/v1.0/myorg/GenerateToken',
   {
      "datasets": [
        {
          "id": "e31996c6-66a7-4492-86fa-f645afc26859"
        }
        
      ],
      "reports": [
        {
          "id": "A91C9E3A-E5F6-4428-9BA8-0B7AAF940320"
        }
      ],
    },
      httpOptions)
      .pipe(catchError(this.handleErrorTwo))
      */
  }

  getReport(token, reportId) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + token
      })
    };

    console.log("paramssss!", httpOptions)
    return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/reports/' + reportId, httpOptions)
      .pipe(
        catchError(this.handleErrorTwo)
      )
  }

  getReportEmbedDetails(token, group) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + token
      })
    };

    console.log("paramssss!", httpOptions)
    return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/' + group + '/reports', httpOptions)
      .pipe(
        catchError(this.handleErrorTwo)
      )
  }
  getReportsByGroup(token: any, group: any) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + token
      })
    };

    console.log("paramssss!", httpOptions)
    return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/' + group + '/reports', httpOptions)
      .pipe(
        catchError(this.handleErrorTwo)
      )
  }

  getReports(token: any, group: any) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + token
      })
    };

    console.log("paramssss!", httpOptions)
    return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/reports', httpOptions)
      .pipe(
        catchError(this.handleErrorTwo)
      )
  }

  //http://localhost:12345/?code=0.AAAAlVw_5wT75U-N0CrFbPOy8nxpAOcH4BhPmZMI-eCjzeg0AM8.AQABAAIAAABeStGSRwwnTq2vHplZ9KL4vCVOMioMcREbOYtAX7O5WVyhC-mi_3KbPLDdlyYrxmuOVp9HA9PY5-_XWzTnvGKZzGu_MfikvVh_kApIfYv9UP58fvjkH0hLOXimFU0PMk7PL5ysWyvLpZDpDFkgQD1QDQiZ6Xqud6KQOiP0y7BtqxIF9EXDoaUCa5mon7Vg-pnkON5_GgB31LXWCNd0L59n0lgc9BJvjKuRkUiN4La2HlwxlLl2uEr1NqzcbJt_1Gr8GBdmDETEQG04NQvxbnI5GIbiz7K2cP88p3pKHdhIhJQfjKXkcYmrC9Qgt8uY1Szxhu6YXMxYVU-tYM7NlbEhJ5uuam3kFMtPcV1I8uau6u_q9EsN-SKngyAzML2-g_IjgcuH5jQQOYv6-yW0TBz37Nx1CAzFYbNMB5T2-8WmB7-8qHOsuS8hgpUU4IPsaYT7UHwwjqaS0Rvxl9nYChFFWZ8PSs15k3ahP9t3DHtKfZkgrUPJ3m6ttBDRkV-UDEn5d5InB_80uZkNLbEQDsD2dd4T4wHpOeqvgiiToF2OcCAA&state=12345&session_state=4713b55f-6ca3-4648-84ce-632ba76f94f3#



  getAzureToken() {


    const query = `
  mutation azureTokenAnclaReporteria($clientId: String!){
    azureTokenAnclaReporteria(clientId: $clientId)
}
`;


/*
const query = `
  mutation azureTokenTemporal($clientId: String!){
    azureTokenTemporal(clientId: $clientId)
}
`;
*/

    let httpOptions = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })


    };
    return this.http.post<any>(this.url,
      JSON.stringify({
        query: query,
        variables: {
          clientId: this.clientId
        }
      }), httpOptions)
      .pipe(catchError(this.handleErrorTwo))
  }





  encenderEscrituraComando(command: any, command2: any, inverted: any, decodeData: any) {

    console.log("encender Comandoooo!");

    var reportes_query = `
                mutation encenderEscrituraComando( $Valor: Int, $Valor2: String) {
                    encenderEscrituraComando(  Valor: $Valor, Valor2: $Valor2)
                }
        `;

    var data = {
      // query: reportes_query,
      variables: {
        Valor: command,
        Valor2: command2
      }
    };

    var newItem = decodeData;


    newItem.DdgData.map((item) => {
      if (command2 == item.variable) {

        if (inverted) {
          item.valor = command == 1 ? 0 : 1;
          //  command == 0 ? newValue = 1 : newValue = 0;
        }
        else {
          item.valor = command;
        }



        var newValue = item.valor;



        if (command2 == 'o2') {
          if (newValue == 0) {
            newItem.DdgMotivo = 10003
          }
          else {
            newItem.DdgMotivo = 10002
          }
        }
        else if (command2 == 'o1') {
          if (newValue == 0) {
            newItem.DdgMotivo = 10000;
          }
          else {
            newItem.DdgMotivo = 10001;
          }
        }
      }

    })

    var d = new Date();

    //d.setHours(d.getHours() - 5);


    var yy = d.getFullYear();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();

    var hh = d.getHours();
    var min = d.getMinutes();
    var ss = d.getSeconds();


    var newDate = yy + "-" + mm + "-" + dd + " " + hh + ":" + min + ":" + ss;


    newItem.DdgTstamp = d.getTime()
    newItem.DdgDatetimeServer = newDate;



    var params = {
      TableName: 'decode-dataGPIO',
      Item: newItem
    }

    console.log("params: ", params);

    var docClient = new AWS.DynamoDB.DocumentClient();
    docClient.put(params, (err, data) => {
      console.log("eeeer:", err);
      console.log("dataaaa:", data)

      var newData = params.Item;
      //  newData.DdgTstamp =  new Date( ).getTime();
      //  newData.DdgDatetimeServer  = new Date().toString();

      if (Firebase.apps.length === 0) {
        Firebase.initializeApp(config);
      }
      Firebase.database().ref().child("decodedataGPIO") // creates a key called hello
        .push(params.Item)                            // sets the key value to world
        .then(function (snap) {
          console.log('Insercion exitosa en Firebase');
          // return snap;
          //     console.log('Insercion exitosa en Firebase');
        })
        .catch(function (error) {
          console.log('Firebase error: ', error);
          //         console.log('Firebase error: ', error);
          //  return err;
        });


    });

    return this.http.post<any>(this.urlServer3, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("usseeeer", user);




          /* if (user && user.token) {
             // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
             localStorage.setItem('currentUser', JSON.stringify(user));
           }*/

          return user;
        }
      );

  }

  deleteRedshiftPoint(item) {

    console.log("deletepointRedshift");

    var delete_mutation = `
    mutation deleteRedshiftPoint( $id : String, $id_tr: String) {
      deleteRedshiftPoint(id: $id , id_tr : $id_tr )
    }
`;

    var data = {
      query: delete_mutation,
      variables: {
        id: item.DgMac,
        id_tr: item.DgIdTr
      }
    };


    console.log("data, ", data);


    return this.http.post<any>(this.url, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("pointtttt", user);
          /* if (user && user.token) {
             // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
             localStorage.setItem('currentUser', JSON.stringify(user));
           }*/

          return user;
        }
      );

  }

  addRedshiftDevice(item) {

    console.log("addRedshiftDevice");

    var update_mutation = `
                mutation addRedshiftDevice($device_type: String, $name: String, $address: String, $id: String, $id_tr: String, $nombre_tr: String, $id_transportadora: String, $mac: String, $regional_id: String, $enterprise_id: String,  $city_id: String) {
                  addRedshiftDevice(device_type: $device_type, name :$name, address :$address, id :$id, id_tr :$id_tr, nombre_tr :$nombre_tr, id_transportadora :$id_transportadora, mac :$mac, regional_id :$regional_id, enterprise_id :$enterprise_id, city_id :$city_id  )
                }
        `;

    var data = {
      query: update_mutation,
      variables: item
    };


    console.log("data, ", data);


    return this.http.post<any>(this.url, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("usseeeer", user);
          /* if (user && user.token) {
             // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
             localStorage.setItem('currentUser', JSON.stringify(user));
           }*/

          return user;
        }
      );
  }

  addRegionalRedshift(item){

    var update_mutation = `
    mutation addRegionalRedshift($rg_codigo: String, $rg_nombre: String, $cl_codigo: String, $cd_codigos: [String]) {
      addRegionalRedshift(rg_codigo: $rg_codigo, rg_nombre: $rg_nombre,   cl_codigo: $cl_codigo, cd_codigos: $cd_codigos  )
    }
`;

    var data = {
      query: update_mutation,
      variables: item
    };


    console.log("data, ", data);


    return this.http.post<any>(this.url, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("usseeeer", user);
          /* if (user && user.token) {
           // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
           localStorage.setItem('currentUser', JSON.stringify(user));
          }*/

          return user;
        }
      );

  }
  addCityRedshift(item) {

    var update_mutation = `
    mutation addCityRedshift($cd_codigo: String, $cd_nombre: String) {
      addCityRedshift(cd_codigo: $cd_codigo, cd_nombre: $cd_nombre )
    }
`;

    var data = {
      query: update_mutation,
      variables: item
    };


    console.log("data, ", data);


    return this.http.post<any>(this.url, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("usseeeer", user);
          /* if (user && user.token) {
           // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
           localStorage.setItem('currentUser', JSON.stringify(user));
          }*/

          return user;
        }
      );

  }
  addCompanyRedshift(item) {


    var update_mutation = `
                mutation addCompanyRedshift($cl_codigo: String, $cl_nombre: String) {
                  addCompanyRedshift(cl_codigo: $cl_codigo, cl_nombre: $cl_nombre )
                }
        `;

    var data = {
      query: update_mutation,
      variables: item
    };


    console.log("data, ", data);


    return this.http.post<any>(this.url, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("usseeeer", user);
          /* if (user && user.token) {
             // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
             localStorage.setItem('currentUser', JSON.stringify(user));
           }*/

          return user;
        }
      );
  }
  updateRedshiftDevice(item) {

    console.log("encender Comandoooo!");

    var update_mutation = `
                mutation updateRedshiftDevice($device_type: String, $cvalue: String, $climit: String, $name: String, $address: String, $id: String, $id_tr: String, $nombre_tr: String, $id_transportadora: String, $mac: String, $regional_id: String, $enterprise_id: String,  $city_id: String) {
                  updateRedshiftDevice(device_type: $device_type, cvalue: $cvalue, climit: $climit, name :$name, address :$address, id :$id, id_tr :$id_tr, nombre_tr :$nombre_tr, id_transportadora :$id_transportadora, mac :$mac, regional_id :$regional_id, enterprise_id :$enterprise_id, city_id :$city_id  )
                }
        `;

    var data = {
      query: update_mutation,
      variables: item
    };


    console.log("data, ", data);


    return this.http.post<any>(this.url, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("usseeeer", user);
          /* if (user && user.token) {
             // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
             localStorage.setItem('currentUser', JSON.stringify(user));
           }*/

          return user;
        }
      );
  }

  encenderComando(command: any, decodeData: any) {


    console.log("encender Comandoooo!");

    var reportes_query = `
                mutation encenderComando($CoMovil: String, $Valor: Int) {
                    encenderComando(CoMovil :$CoMovil,  Valor: $Valor)
                }
        `;

    var data = {
      query: reportes_query,
      variables: {
        CoMovil: '4634108855',
        Valor: command
      }
    };

    var newItem = decodeData;

    var d = new Date();


    var yy = d.getFullYear();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();

    var hh = d.getHours();
    var min = d.getMinutes();
    var ss = d.getSeconds();


    var newDate = yy + "-" + mm + "-" + dd + " " + hh + ":" + min + ":" + ss;


    newItem.DdgTstamp = d.getTime()
    newItem.DdgDatetimeServer = newDate;

    newItem.DdgData.map((item) => {
      if (item.variable == 'GPS') {
        item.valor = command;
      }
    })

    if (command == 1) {
      newItem.DdgMotivo = 10004
    }
    else {
      newItem.DdgMotivo = 10005
    }


    var params = {
      TableName: 'decode-dataGPIO',
      Item: newItem
    }

    console.log("params: ", params);

    var docClient = new AWS.DynamoDB.DocumentClient();
    docClient.put(params, (err, data) => {
      console.log("eeeer:", err);
      console.log("dataaaa:", data)

      var newData = params.Item;
      //  newData.DdgTstamp =  new Date( ).getTime();
      //  newData.DdgDatetimeServer  = new Date().toString();

      if (Firebase.apps.length === 0) {
        Firebase.initializeApp(config);
      }
      Firebase.database().ref().child("decodedataGPIO") // creates a key called hello
        .push(params.Item)                            // sets the key value to world
        .then(function (snap) {
          console.log('Insercion exitosa en Firebase');
          // return snap;
          //     console.log('Insercion exitosa en Firebase');
        })
        .catch(function (error) {
          console.log('Firebase error: ', error);
          //         console.log('Firebase error: ', error);
          //  return err;
        });
    });

    return this.http.post<any>(this.url, data)
      .map(
        user => {
          // login bem-sucedido se houver um token jwt na resposta

          console.log("usseeeer", user);
          /* if (user && user.token) {
             // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
             localStorage.setItem('currentUser', JSON.stringify(user));
           }*/

          return user;
        }
      );
  }



  private handleErrorTwo(error: HttpErrorResponse) {

    console.log("error!!", error)
    if (error.error instanceof ErrorEvent) {
      throw error.error.message;
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return _throw(error);
  }


  private handleError(error: any, caught) {
    if (error.error instanceof ErrorEvent) {
      throw error.error.message;
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    /*    return throwError(
            'Something bad happened; please try again later.');*/
  }
}

/*
screenshoots de variables cambiables

marcar con flechas

comparar mediciones con 4 pm y hace 5 min  */