import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import {
  LoggedInCallback,
  UserLoginService,
} from "../../service/cognito.service";
import { Router } from "@angular/router";

import { DynamoDBIOTService } from "../../service/ddbiot.service";

import { Observable } from "rxjs";
import * as fromRoot from "../../common/index";
import * as layout from "../../common/layout/layout.actions";
import * as games from "../../common/games/games.actions";
import * as devices from "../../common/devices/devices.actions";

import { Store } from "@ngrx/store";
import timeago from "timeago.js";

import { CompleterService, CompleterData } from "ng2-completer";

import { VisualizeJS } from "../../visualize/vjs.main.component";
import { GraphQLService } from "../../service/graphql.service";
import * as pbi from "powerbi-client";
import { SharedService } from "app/service/global.service";

declare var $: any;

@Component({
  selector: "awscognito-angular2-app",
  templateUrl: "./reports.html",
  styleUrls: ["./reports.css"],
})
export class ReportsComponent implements LoggedInCallback, OnInit {
  report;
  public actualDevice: any = {
    DdgMacDV: "",
    DgMac: "",
  };
  decodeDataDevice: any[] = [];
  newData: any[] = [];
  searchStr: any;

  public isDevice = false;

  public inModal$: Observable<boolean>;
  public devices$: Observable<any>;
  public dataService: CompleterData;
  PowerBiKey = "";
  showNoData: boolean = false;
  public screenHeight: number;
  public onlyRead= false;
  dowloadDisabled: boolean = true;
  isSubmitDisabled: boolean = true;
  selectedReport: any;
  reportsBI: any[] = [];
  idReport: any;
  embedUrl: any;
  name: any;
  azureToken: string;
  @ViewChild("reportContainer") reportContainer: ElementRef;
  ngOnInit() {
    this.getAzureToken();
    console.log("entra reports component");
    var s;
    this.devices$.forEach((data) => {
      s = data;
    });
    var z = [];
    for (let key in s) {
      z.push(s[key]);
    }

    this.devices$.subscribe((data) => {
      var z = [];
      for (let key in data) {
        z.push(s[key]);
      }
      this.dataService = this.completerService.local(z, "DgAlias", "DgAlias");
    });
    this.dataService = this.completerService.local(z, "DgAlias", "DgAlias");

    this.ddb.getPowerBiId("", this.onPowerBiData.bind(this));
    this.getActualEnterprise();

  }

  getActualEnterprise() {

    this.ddb.getActualUser(this.sharedService.UsId, this.actualUserSuccess.bind(this),[], null);
}
actualUserSuccess(err, data) {
  console.log("mi dataaa from actual user", data);

  if (err) {
      console.log("Err retriving user", err);
  }
  else {
      if (data.Items[0]) {
          this.checkIfAdmin(data.Items[0]);

      }
  }
}
checkIfAdmin(user){
     
  console.log("actualUserrrr!", user);

  var admin = false;
  user.userPermissions.user.map( (item)=>{

      if(item.type == 7){
          if(item.completed == true){
              this.onlyRead = true;

              this.router.navigate(["/home/login"]);
          }
      }
  })

}

  constructor(
    public ddb: DynamoDBIOTService,
    private store: Store<fromRoot.AppState>,
    private el: ElementRef,
    public router: Router,
    public userService: UserLoginService,
    private completerService: CompleterService,
    private _reports: GraphQLService,
    public sharedService: SharedService
  ) {
    this.devices$ = store.select(fromRoot.getDevicesEntities);
    this.inModal$ = store.select(fromRoot.getLayoutModalIsOpen);
    this.userService.isAuthenticated(this);

    this.store.dispatch(new layout.CloseModalAction(true));
  }

  onPowerBiData(err, data) {
    if (!err) {
      if (data.Items[0]) {
        this.PowerBiKey = data.Items[0].key;
      }
    }

    // this.getAzureToken();
  }

  onSelected(item) {
    console.log(item);
    if (item) {
      this.isDevice = false;
      var mac = item.originalObject.DgMac;

      //get actual device
      this.actualDevice = {
        DgMac: "",
      };
      var comp = this;
      this.devices$.forEach((data) => {
        if (data != undefined) {
          if (data[mac]) {
            comp.actualDevice = data[mac];
          }
        }
      });
      // get historic data from DynamoDB
      this.ddb.getDeviceHistoric(mac, this.onHistoricData.bind(this));
    }
  }

  selectedReportBI(event: {}) {
    if (typeof event === "object" && Object.keys(event).length !== 0) {
      this.isSubmitDisabled = false;
    } else {
      this.isSubmitDisabled = true;
    }
  }

  createReportBI() {
    this.dowloadDisabled = false;
    this.isSubmitDisabled = true;
    if (Object.keys(this.selectedReport).length !== 0) {
      this._reports
        .getReport(
          localStorage.getItem("azureToken"),
          this.selectedReport["id"]
        )
        .subscribe(
          (report) => {
            console.log("res get report", report);
            this.selectedReport = "";
            this._reports
              .generateToken(
                report.id,
                localStorage.getItem("azureToken"),
                report
              )
              .subscribe(
                (token) => {
                  this.showReport(token.token, report.id, report.embedUrl);
                },
                (err) => console.error(err)
              );
          },
          (err) => console.error(err)
        );
    }
  }
   get_visual_filters() {
    // Get all the pages of the report
    /*
    const pages = await report.getPages();
  
    // Find required page
    const page = pages.find((p) => p.name === "ReportSection1"); // The page in which visual is present
  
    // Get the required visual.
    const visuals = await page.getVisuals();
  
    // Find the visual using Guid
    const visual = visuals.find((v) => v.name === "VisualContainer1");
  
    // Retrieve the filter of visual:VisualContainer1
    const filters = await visual.getFilters();
    console.log("The filters applied on the visual are: ", filters);
    */
  }

  getAzureToken() {
    console.log("entra ger azure token");
    this._reports.getAzureToken().subscribe((res) => {
      console.log("res get azure token", res);
      var newData = JSON.parse(res.data.azureTokenAnclaReporteria);
      //var newData = JSON.parse(res.data.azureTokenTemporal);

      localStorage.setItem("azureToken", newData.access_token);
      //   localStorage.setItem("azureToken", newData.access_token)

     var group = 'ca6c2a6b-e3ac-4acb-8201-ea8dc381b1e9'; //PROD

   //   var group = "9b947876-31a0-451f-91de-b0099a5cbc77"; //TEST

      this._reports
        .getReportsByGroup(newData.access_token, group)
        .subscribe((res: []) => {
          //    this._reports.getReportsByGroup(newData.access_token, this.PowerBiKey).subscribe((res: []) => {
          //     this._reports.getReportsByGroup(newData.access_token, '6eb92c22-0f73-4b45-8b9e-f9ac812a43d6').subscribe((res: []) => {
          console.log("clg getReportsByGroup", res);
          if (res["value"].length > 0) this.reportsBI = res["value"];
          // https://api.powerbi.com/v1.0/myorg/GenerateToken
          /*    
                NewwData.map(item => {
                  this.reportsBI.push({
                    ...item
                  });
                });
              })
              */
          // this._reports.generateToken(NewwData[0].id, newData.access_token, res).subscribe(_res => {

          //     console.log("generate embed", _res);

          //     this.showReport(_res.token, NewwData[0].id, NewwData[0].embedUrl);

          // });
        });
    });
  }

  showReport(accessToken, id, url) {
    let config = {
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: url,
      id: id,
      settings: {},
      filters: [],
    };

    console.log("ID", id);

    //if (id === "a91c9e3a-e5f6-4428-9ba8-0b7aaf940320") {
    let dataCompaniesJoin: pbi.models.IAdvancedFilterCondition[] = [];
    let dataCityJoin: pbi.models.IAdvancedFilterCondition[] = [];
    let dataRegionalJoin: pbi.models.IAdvancedFilterCondition[] = [];
    let dataDeviceJoin: pbi.models.IAdvancedFilterCondition[] = [];
    // Embed URL
    //  let embedUrl = POWERBI.BASE_URL + POWERBI.REPORT_ID + "&groupId=" + POWERBI.GROUP_ID;
    //   let embedReportId = POWERBI.REPORT_ID;
    console.log(
      "get empresa",
      JSON.parse(localStorage.getItem("savedEnterprise")).name
    );
    if (localStorage.getItem("savedEnterprise")) {
      let dataComp = JSON.parse(localStorage.getItem("actualUser")).companies;
      for (const data in dataComp) {
        if (Object.keys(dataComp[data]).length > 0 && dataComp[data].name) {
          dataCompaniesJoin.splice(0, 0, {
            operator: "Contains",
            value: dataComp[data].name,
          });

          console.log("enterprisee!", dataComp[data]);

          var _item = dataComp[data];
          if (_item.regionals && _item.regionals.allComplete == false) {
            _item.regionals.regionals.map((regionalItem) => {
              if (regionalItem.completed) {
                dataRegionalJoin.splice(0, 0, {
                  operator: "Contains",
                  value: regionalItem.name,
                });

                if (regionalItem.cities && regionalItem.cities.cities) {
                  regionalItem.cities.cities.map((cityItem) => {
                    if (cityItem.completed) {
                      if (cityItem.devices && cityItem.devices.devices) {
                        cityItem.devices.devices.map((deviceItem) => {
                          if (deviceItem.completed) {
                            console.log("deviceFound,", deviceItem);
                            dataDeviceJoin.splice(0, 0, {
                              operator: "Contains",
                              value: deviceItem.DgName,
                            });
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          }
        }
      }
      // console.log("dataCompanies", dataCompanies);
    }
    const filter: pbi.models.IAdvancedFilter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
      //  table: "tconsola PQ",
        //column: "Cliente.",
            table: "tconsola",
         column: "Cliente"
      },
      logicalOperator: "Or",
      conditions: dataCompaniesJoin,
      filterType: 0,
      displaySettings: {
        isLockedInViewMode: true,
      },
      // operator: "In",
      // values: [dataCompaniesJoin],
      // filterType: 1, // pbi.models.FilterType.BasicFilter,
      // displaySettings: {
      //     isLockedInViewMode: true
      // }
    };

    const filterRegional: pbi.models.IAdvancedFilter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
       // table: "Regional",
          table: "Cliente",
        column: "Regional",
      },
      logicalOperator: "Or",
      conditions: dataRegionalJoin,
      filterType: 0,
      displaySettings: {
        isLockedInViewMode: true,
      },
      // operator: "In",
      // values: [dataCompaniesJoin],
      // filterType: 1, // pbi.models.FilterType.BasicFilter,
      // displaySettings: {
      //     isLockedInViewMode: true
      // }
    };

    const filterDevices: pbi.models.IAdvancedFilter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
      //  table: "tconsola PQ",
          table: "tconsola",
        column: "Nombre Punto de venta",
      },
      logicalOperator: "Or",
      conditions: dataDeviceJoin,
      filterType: 0,
      displaySettings: {
        isLockedInViewMode: true,
      },
      // operator: "In",
      // values: [dataCompaniesJoin],
      // filterType: 1, // pbi.models.FilterType.BasicFilter,
      // displaySettings: {
      //     isLockedInViewMode: true
      // }
    };

    if (filterRegional.conditions.length > 0) {
      config.filters = [filter, filterRegional];
    } else {
      config.filters = [filter];
    }

    if (filterDevices.conditions.length > 0) {
      config.filters = [filterDevices];
    }
    // };

    console.log("config", config);
    let reportContainer = this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    powerbi.reset(reportContainer);
    this.report = powerbi.embed(reportContainer, config);

    this.report.on("dataSelected", function(e){
       // alert("dataSelected!");
    });

    this.report.on("rendered", function(event){
     //   alert("rendered!");
    });

    /*
    this.report.off("loaded");
    this.report.on("loaded", () => {
      console.log("Loaded");
    });
      this.report.on("error", () => {
        //    this.getToken();
      });
   
      this.report.on("dataSelected", () => {
        //    this.getToken();

        console.log("dataSelected");
      });

  
      this.report.on("filtersApplied", () => {
        //    this.getToken();

        console.log(
          "filtersAppliedfiltersAppliedfiltersAppliedfiltersAppliedfiltersApplied"
        );
      });

  
      this.report.on("buttonClicked", () => {
        //    this.getToken();

        console.log("buttonClicked");
      });

  
      this.report.on("commandTriggered", () => {
        //    this.getToken();

        console.log("commandTriggered");
      });



      this.report.off("pageChanged");
      this.report.on("pageChanged", (event) => {
        console.log("Page changed:", event);

        this.report = event.detail.newPage.name;
      });
      */
   
  }

  onHistoricData(err, data) {
    console.log(err);
    console.log(data);
    this.decodeDataDevice = [];
    this.newData = [];
    if (!err) {
      this.newData = data.Items;

      if (this.newData.length == 0) {
        this.showNoData = true;
      } else {
        this.showNoData = false;
      }
      data.Items.map((item) => {
        this.decodeDataDevice.push(item);
      });
    }
    this.isDevice = true;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(["/home/login"]);
    } else {
      console.log("scanning Devices");
      //GET
    }
  }
}
