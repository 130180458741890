import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { LoggedInCallback, UserLoginService } from "../../service/cognito.service";
import { Router } from "@angular/router";


import { DynamoDBIOTService } from "../../service/ddbiot.service";


import { BucketBeaconsService } from "../../service/bucket.beaconsimages.service";

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';


import * as fromRoot from '../../common/index';
import * as layout from '../../common/layout/layout.actions';
import * as games from '../../common/games/games.actions';
import * as devices from '../../common/devices/devices.actions';

import { Store } from "@ngrx/store";
import timeago from 'timeago.js';

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { MatSort, MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material';

import { utils } from '../../lib/utils';


import { CompleterService, CompleterData } from 'ng2-completer';
import { HistoricExport } from '../../service/historicExport.service';
import { JSONP_ERR_NO_CALLBACK } from "@angular/common/http/src/jsonp";


import { Observable } from 'rxjs/Observable';
import { SharedService } from "app/service/global.service";


declare var $: any;

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './historic.html',
    styleUrls: ['./historic.css', './searchbar.css']
})
export class HistoricComponent implements LoggedInCallback, OnInit {

    public searchStr: string;
    public searchStrCities: string;
    public searchStrRegional: string;
    public searchStrDevices: string;
    public onlyRead= false;
    public captain: string;

    public dataService: CompleterData;
    public dataServiceCities: CompleterData;
    public dataServiceRegional: CompleterData;
    public dataServiceDevices: CompleterData;
    public dataServiceEnterprise: CompleterData;

    decodeDataDevice: any[] = [];
    newData: any[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    public actualDevice: any = {
        DdgMacDV: "",
        DgMac: "",
        DgAttributes: {

            "cantBilletes": {
                "alias": "Cantidad de billetes",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
            },
            "CURRENCY_CODE": {
                "alias": "Código moneda",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
            },
            "monto": {
                "alias": "Moneda",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
            },
            "nameUser": {
                "alias": "Usuario",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
            },
            "number": {
                "alias": "Id. Movimiento",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
            },
            "loginId": {
                "alias": "Código Usuario",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " "
            },
            "cantacumbilletes": {
                "alias": "% de bolsa",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " ",

                "advertencia": {
                    "value": 20,
                    "limit": 600,
                    "limitPercentage": 300,
                    "renderExcessColor": "red"
                }
            },

            "depositValueType": {
                "alias": "Tipo de Depósito",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " ",
                "customValues": {
                    "1": {
                        "value": "Deposito validado",

                    },
                    "2": {
                        "value": "Deposito no validado",

                    }
                }
            },

            "denominations": {
                "alias": "Denominaciones",
                "factorConversion": "1*{valor}",
                "maxRango": 600,
                "minRango": 20,
                "render": "texto",
                "renderRealtime": "line",
                "representacion": "Decimal",
                "text": true,
                "unidad": " ",

            },

        }
    };

    public inModal$: Observable<boolean>;
    public devices$: Observable<any>;

    public isDevice = false;
    public isDeviceForGraphs = false;

    public isAllDevices = true;

    public initDate: any;
    public endDate: any;
    public _initDate: any;
    public _endDate: any;

    public ranged = false;
    savedEvtDevice
    public infoRangedText = "";

    public device;

    public actualData = [];

    public onRangedHistoric = false;
    onRangedAllDevices = false;

    actualEnterprise;
    companies = [];

    // displayedColumns = ['date', 'event', 'ubication'];
    displayedColumns = ['date', 'event'];
    //exampleDatabase = new ExampleDatabase();
    dataSource: ExampleDataSource | null;
    decodeDataItems;

    decodeDataDeviceReversed = [];

    coordsToShow = [];

    selected: boolean = false;
    typeArchive: boolean = false;
    showNoData: boolean = false;

    noCoords = false;

    cities = [];
    regionals = [];
    devices = [];


    selectedCity;
    selectedRegional;
    selectedDevice;

    selectedEnterprise;

    actualCompany;

    deviceInfo: any = {};

    timeItem;

    checkIfDevices = false;

    loadingQuery = false;
    public latitude = 4.623739;

    public longitude = -73.700035;
    public zoom = 6;

    public polyline = [];
    iotAccount: boolean = false;
    countt = 0;

    devicesList = [];
    //   "@agm/core": "^1.0.0",
    constructor(public sharedService: SharedService, private db: AngularFireDatabase, public ddb: DynamoDBIOTService, private historicExport: HistoricExport, public dialog: MatDialog, private completerService: CompleterService, private _utils: utils, private store: Store<fromRoot.AppState>, private el: ElementRef, public router: Router, public userService: UserLoginService) {


        this.getAllDevicesList();
        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.inModal$ = store.select(fromRoot.getLayoutModalIsOpen);
        this.userService.isAuthenticated(this);

        this.store.dispatch(new layout.CloseModalAction(true));


        var actualCompany: any = localStorage.getItem("savedEnterprise");

        this.actualCompany = JSON.parse(actualCompany);

        this.deviceInfo.company = this.actualCompany.name;

        var test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];
        };
        timeago.register('test_local', test_local_dict);

    }




    checkIfAdmin(user){
     
        console.log("actualUserrrr!", user);

        var admin = false;
        user.userPermissions.user.map( (item)=>{

            if(item.type == 7){
                if(item.completed == true){
                    this.onlyRead = true;
                    console.log("read Onlyyyy!!!!");
                }
            }
            if(item.name == 'ADMINISTRADOR DE USUARIOS'){
                if(item.completed == true){
                    console.log("admin!");
                    this.iotAccount = true;
                }
            }
        })

    }

    realtimeHistoric() {
        console.log("realtimeHistoric");

        //reconnect: true,
        console.log("inittttttt");
        this.decodeDataItems = this.db.list('decodedataGPIO', ref => ref.limitToLast(1));
        this.decodeDataItems.snapshotChanges(['child_added'])
            .subscribe(action => {
                console.log("actioooon");
                console.log(action);

                var newAction = action[0];



                let child = newAction.payload.val();
                console.log("child");
                console.log(child);
                // observer.next(action[0]);

                if (this.actualDevice && this.actualDevice.DgMac == child.DdgMacDV) {
                    console.log("same");

                    if (!this.ranged) {
                        // this.decodeDataDevice = [];
                        this.decodeDataDevice.unshift(child);
                        this.isDevice = false;

                        setTimeout(() => {
                            this.isDevice = true;
                        }, 10);
                    }

                }
                /*action.forEach(_child => {
                    if(_child.type == 'value'){
                        observer.next(_child); 
                    }
                    
                });*/

            });

    }


    getEstadoCaja(): void {
        this.ddb.getEstadoCaja();
        
    }
    
    ngOnInit(): void {


        this.getEstadoCaja();
        this.realtimeHistoric();
        this.prepareSearchBar();


        //this.dataSource = new ExampleDataSource(this, this.paginator);

        this.dataSource = new ExampleDataSource(this, this.paginator);

        var s;
        this.devices$.forEach((data) => { s = data });
        var z = [];
        for (let key in s) {
            z.push(s[key]);
        }

        this.devices$.subscribe((data) => {
            var z = [];
            for (let key in data) {
                z.push(s[key]);
            }
            console.log("Devices$");
            console.log(data);
            this.dataService = this.completerService.local(z, 'DgAlias', 'DgAlias');

        });
        this.dataService = this.completerService.local(z, 'DgAlias', 'DgAlias');
        this.getActualEnterprise();

        // this.getCities();
        // this.getPoints();

    }

    getActualEnterprise() {

        this.ddb.getActualUser(this.sharedService.UsId, this.actualUserSuccess.bind(this),[], null);
    }

    actualUserSuccess(err, data) {
        console.log("mi dataaa from actual user", data);

        if (err) {
            console.log("Err retriving user", err);
        }
        else {
            if (data.Items[0]) {

                localStorage.setItem("actualUser", JSON.stringify(data.Items[0]))

                this.companies = [];

                var comps = data.Items[0].companies;

                for (let key in comps) {
                    this.companies.push(comps[key]);
                }

                this.companies.sort((a, b) => a.name.localeCompare(b.name))



                this.dataServiceEnterprise = this.completerService.local(this.companies, 'name', 'name');

                this.getSavedEnterprise(data.Items[0].companies);

                this.checkIfAdmin(data.Items[0]);

            }
        }
    }

    returnBackgroundColor(val, item, position) {

        var color = 'none';

        if (val.value.advertencia) {

            if (this.onRangedHistoric) {

            }



            var device = this.actualDevice;

            var _item: any = 'N/A';




            item.DdgData.map((item) => {

                if(item){
                if (item.variable == position) {

                    _item = item.valor;



                }
            }
            });

            var newValue;


            var myItem;

            this.devicesList.map((__item, idx) => {
                if (__item.DgMac == item.DdgMacDV) {

                    if (__item.DgAttributes[val.key]) {
                        myItem = __item.DgAttributes[val.key]


                        newValue = __item.DgAttributes[val.key].advertencia.limit;
                    }

                }
            })




            if (newValue != undefined && _item != "N/A") {
                _item = parseFloat(_item);
                newValue = parseFloat(newValue);


                if (_item > newValue) {
                    color = myItem.advertencia.renderExcessColor;

                }




            }


        }
        else {
            color = 'none';
        }

        return color;
    }


    onSelectedEnterprise(evt) {
        console.log("selected!", evt)

        this.selectedEnterprise = null;

        this.isDevice = false;

        setTimeout(() => {


            this.selectedEnterprise = evt.originalObject;

            console.log("changeEnterprise");



            if (this.selectedEnterprise) {

                console.log("changeEnterprise2");

                this.actualEnterprise = this.selectedEnterprise;

                this.deviceInfo.company = this.actualEnterprise.name;

                console.log("actualEnterprise", this.actualEnterprise);

                localStorage.setItem("savedEnterprise", JSON.stringify(this.actualEnterprise));


                this.searchStrRegional = null;
                this.selectedRegional = null;

                this.searchStrCities = null;
                this.selectedCity = null;


                this.searchStrDevices = null;
                this.selectedDevice = null;


                if (this.checkIfDevices == false) {




                    if (this.initDate && this.endDate) {
                        this.setRangedHistoric();
                    }
                    else {
                        this.getAllDevices();
                    }

                }

                this.getRegionals();

                //  this.selectedEnterprise = null;

                //   location.reload();

            }

        }, 1000);
    }
    getSavedEnterprise(companies) {

        this.checkIfDevices = false;
        for (let key in companies) {



            if (companies[key].regionals && companies[key].regionals.regionals) {

                console.log("companies[key]")
                companies[key].regionals.regionals.map((regionalItem) => {

                    if (regionalItem.cities && regionalItem.cities.cities) {

                        console.log("cities[key]")
                        regionalItem.cities.cities.map((cityItem) => {


                            if (cityItem.devices && cityItem.devices.devices) {

                                console.log("devices[key]")

                                if (cityItem.devices.allComplete == false) {

                                    console.log("HEYYYYYY!!!!!!", cityItem)

                                    cityItem.devices.devices.map((deviceItem) => {

                                        if (deviceItem.completed) {
                                            console.log("DEVICE ACCOUNT!", deviceItem);

                                            this.checkIfDevices = true;
                                        }
                                    })
                                }


                            }
                        })
                    }
                })
            }


        }




        var _savedEnterprise = localStorage.getItem("savedEnterprise");

        if (_savedEnterprise) {

            this.actualEnterprise = JSON.parse(_savedEnterprise);
        }
        else {

            var _first = true;
            for (let key in companies) {







                if (_first) {

                    this.actualEnterprise = companies[key]


                    localStorage.setItem("savedEnterprise", JSON.stringify(this.actualEnterprise));

                    _first = false;
                }
            }
            //this.actualEnterprise = companies[0]
        }

        console.log("ACTUAL ENTERPRISE!", this.actualEnterprise);

    }

    getAllDevicesList() {

        this.ddb.getAllDevices((err, data) => {


            if (!err) {
                this.devicesList = data.Items;
            }
        }, [], null);

    }


    getAllDevices() {


        this.ddb.getAllLastState(null, null, this.onGetAllDevices.bind(this))


        this.displayedColumns = ['name', 'ubication', 'date', 'event'];
        for (let key in this.actualDevice.DgAttributes) {
            console.log(key);

            if (!this.actualDevice.DgAttributes[key].hideInHistoric) {
                this.displayedColumns.push(key);
            }
        }
    }

    onGetAllDevices(err, data, noSort) {
        if (!err) {

            this.zoom = 6;
            this.latitude = 4.623739;
            this.longitude = -73.700035;

            this.isAllDevices = false;
            setTimeout(() => {


                console.log("allDEvicesss!", data);




                console.log("onGetAllDevices");

                if (noSort) {

                }
                else {
                    data.Items.sort((a, b) => a.DdgNameDV.localeCompare(b.DdgNameDV))
                }



                data.Items.map((item) => {
                    item.DdgLatDV = parseFloat(item.DdgLatDV);
                    item.DdgLongDV = parseFloat(item.DdgLongDV);
                });

                this.decodeDataDevice = data.Items;


                this.decodeDataDeviceReversed = data.Items;

                this.isAllDevices = true;

                this.loadingQuery = false;

                if (this.onRangedAllDevices && !noSort) {

                    console.log("loop!")
                    if (this.selectedDevice) {

                    }
                    else if (this.selectedCity) {
                        /*
                                                console.log("loop 2");
                                                this.isAllDevices = false;
                                                this.onRangedAllDevices = true;
                                                */
                        // this.ddb.getRangedHistoricFromFilterBatch(this.selectedRegional, this.selectedCity,this._initDate, this._endDate, this.onGetAllDevices.bind(this), this.decodeDataDevice);

                    }
                    else if (this.selectedRegional) {
                        /*
                                                console.log("loop 2");
                        this.isAllDevices = false;
                        this.onRangedAllDevices = true;
                        */
                        //    this.ddb.getRangedHistoricFromFilterBatch(this.selectedRegional, null, this._initDate, this._endDate, this.onGetAllDevices.bind(this), this.decodeDataDevice);

                    }
                }
            }, 1000);

        }
    }
    /*
    getCities() {

        this.ddb.getCities(this.onGetCities.bind(this));

    }*/

    getRegionals() {
        this.ddb.getRegionals(this.onGetRegionals.bind(this), [], null)
    }

    onSelectedCities(evt) {
        console.log("Selected city", evt);

        if (evt && evt.originalObject) {



            this.selectedDevice = null;


            console.log("Selected city", evt);

            setTimeout(() => {



                this.selectedCity = evt.originalObject;

                this.searchStrDevices = null;
                this.selectedDevice = null;

                this.isDevice = false;

                this.dataServiceDevices = null;


                if (this.initDate && this.endDate) {
                    this.setRangedHistoric();
                }
                else {

                    this.ddb.getAllLastState(this.selectedRegional, this.selectedCity, this.onGetAllDevices.bind(this))

                }


                this.ddb.getDevices(this.selectedCity, this.selectedRegional, this.onGetDevices.bind(this), [], null);
            }, 1000);
        }
    }


    /*
    onSelectedPoint(evt) {

        if (evt && evt.originalObject) {

            this.selectedPoint = null;


            console.log("Selected city", evt);

            setTimeout(() => {



                this.selectedPoint = evt.originalObject;

                this.searchStrDevices = null;
                this.selectedDevice = null;


                this.ddb.getDevices(this.selectedCity, this.selectedPoint, this.onGetDevices.bind(this));
            }, 1000);
        }

    }


*/
    checkIfCanView() {


    }
    onSelectedRegional(evt) {

        if (evt && evt.originalObject) {




            this.selectedRegional = evt.originalObject;

            this.searchStrCities = null;
            this.selectedCity = null;


            this.searchStrDevices = null;
            this.selectedDevice = null;

            this.isDevice = false;


            if (this.checkIfDevices == false) {

                if (this.initDate && this.endDate) {
                    this.setRangedHistoric();
                }
                else {


                    this.ddb.getAllLastState(this.selectedRegional, null, this.onGetAllDevices.bind(this))

                }

            }

            this.dataServiceCities = null;

            this.ddb.getCities(this.selectedRegional, this.onGetCities.bind(this));


        }

    }

    selectedDeviceFromTable(item) {

        // this changes the scrolling behavior to "smooth"
        window.scrollTo({ top: 0, behavior: 'smooth' });

        console.log("selectedDeviceFromTable", item);

        this.ddb.getDevice(item.DdgMacDV, (err, data) => {
            if (!err) {
                if (data.Items[0]) {

                    var newItem = data.Items[0];
                    newItem.originalObject = newItem;


                    this.ddb.getRegional(newItem.DgRegionalId, (err, dataRegional) => {
                        if (!err) {
                            if (dataRegional.Items[0]) {
                                this.selectedRegional = dataRegional.Items[0];

                                this.ddb.getCity(newItem.DgCityId, (err, dataCity) => {
                                    if (!err) {
                                        if (dataRegional.Items[0]) {
                                            this.selectedCity = dataCity.Items[0];


                                            this.onSelectedDevice(newItem);
                                        }
                                    }

                                }, [], null);

                            }
                        }
                    }, [], null)
                }

            }
        })
    }

    onSelectedDevice(evt) {

        if (evt && evt.originalObject) {

            this.isAllDevices = false;

            this.savedEvtDevice = evt;

            this.isDevice = false;

            this.infoRangedText = "Datos de todos los tiempos";


            console.log("Selected city", evt);

            this.selectedDevice = evt.originalObject;

            this.actualDevice = evt.originalObject;

            this.ddb.getTransportadora(this.selectedDevice.DgIdTr, this.onTransportadoraData.bind(this), [], null);

            if (this.initDate && this.endDate) {
                this.isDevice = false;

                this.ddb.getDeviceRangedHistoric(this.actualDevice.DgIdTr, this._initDate, this._endDate, this.onHistoricData.bind(this));
            }
            else {

                this.ddb.getDeviceHistoric(this.selectedDevice.DgIdTr, this.onHistoricData.bind(this));

                this.ddb.getDeviceHistoricTime(this.selectedDevice.DgIdTr, this.onHistoricTimeData.bind(this));
            }

        }

    }



    onGetDevices(err, data) {

        if (err) {

        }
        else {
            if (data.Items) {



                data.Items.sort((a, b) => a.DgName.localeCompare(b.DgName))


                console.log("ongetDEvices Success", data);
                this.devices = [
                    /*
                    {
                        id: "none",
                        name: "TODOS",
                        all: true
                    },
                    */
                    ...data.Items
                ]

                this.dataServiceDevices = null;

                this.dataServiceDevices = this.completerService.local(this.devices, 'DgName', 'DgName');
            }
        }

    }

    onGetCities(err, data) {
        if (err) {

        }
        else {

            console.log("data!!!", data);
            //  if (data.Items) {

            data.Responses.city.sort((a, b) => a.name.localeCompare(b.name))


            console.log("ongetcities Success", data);

            console.log("ACTUALREGIONAL", this.selectedRegional)
            console.log("actualkEnterprise", this.selectedEnterprise);

            var actualCompany: any = localStorage.getItem("savedEnterprise");

            actualCompany = JSON.parse(actualCompany);
    
            console.log("actualComapny", actualCompany)

            //NEWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
            var foundAllComplete = false;
            
            if(actualCompany && 
                actualCompany.regionals && 
                actualCompany.regionals.regionals 
                
                ){
                    actualCompany.regionals.regionals.map( (regional)=> {
                        if(regional && regional.id == this.selectedRegional.id && regional.cities && regional.cities.allComplete){
                            foundAllComplete = true;
                        }
                    })
            }
            else {
                console.log("a?????")
                foundAllComplete = true;
            }
            if(foundAllComplete){
                this.cities = [
                    {
                        id: "none",
                        name: "TODAS",
                        all: true
                    },
                    ...data.Responses.city
                ]
            }
            else {
                this.cities = [
                    ...data.Responses.city
                ]
            }

           

            this.dataServiceCities = this.completerService.local(this.cities, 'name', 'name');
            //   }
        }
    }

    getPoints() {
        //  this.ddb.getPoints( this.onGetPoints.bind(this));
    }

    onGetRegionals(err, data) {
        if (err) {

        }
        else {
            if (data.Items) {


                data.Items.sort((a, b) => a.name.localeCompare(b.name))



                console.log("ongetcities Success", data);
                this.regionals = [
                    /*
                    {
                        id: "none",
                        name: "TODOS",
                        all: true
                    },
                    */
                    ...data.Items
                ]

                this.dataServiceRegional = this.completerService.local(this.regionals, 'name', 'name');
            }
        }
    }
    /*
    onGetPoints(err, data) {
        if (err) {

        }
        else {
            if (data.Items) {

                console.log("ongetcities Success", data);
                this.points = [
                    {
                        id: "none",
                        name: "TODOS",
                        all: true
                    },
                    ...data.Items
                ]

                this.dataServicePoints = this.completerService.local(this.points, 'name', 'name');
            }
        }

    }
    */

    onSelected(item) {


        this.ranged = false;

        console.log(item);
        if (item) {
            this.isDevice = false;
            var mac = item.originalObject.DgMac;
            var _DgIdTr = item.originalObject.DgIdTr;
            //get actual device
            this.actualDevice = {
                DgMac: ""
            }
            var comp = this;
            this.devices$.forEach((data) => {
                if (data != undefined) {
                    if (data[mac]) {
                        comp.actualDevice = data[mac]
                    }
                }
            })
            this.infoRangedText = "Datos de todos los tiempos";
            // get historic data from DynamoDB
            this.ddb.getDeviceHistoric(_DgIdTr, this.onHistoricData.bind(this));
        }

    }


    updateData() {

        if (this.loadingQuery == false) {
            this.isDevice = false;


            if (this.ranged) {
                this.setRangedHistoric();
            }
            else {
                this.onSelectedDevice(this.savedEvtDevice);
            }
        }
    }
    intervalData() {

        var mac = this.actualDevice.DgMac;


        setInterval(() => {

            var changed = false;

            var _actualData = this.actualData;

            this.ddb.getDeviceHistoric(this.actualDevice.DgIdTr, (err, data) => {


                if (!err) {
                    data.Items.map((newItem) => {

                        var found = false;
                        var foundItem = null;

                        this.actualData.map((oldItem) => {
                            if (newItem.DdgTstamp == oldItem.DdgTstamp) {
                                found = true;
                                foundItem = newItem;
                            }
                        })

                        if (found == false) {
                            _actualData.push(foundItem);
                        }
                    })
                }

            });

            if (changed) {
                this.onHistoricData(false, { Items: _actualData })
            }

        }, 120000)
    }
    cancelRangedHistoric() {

        if (this.loadingQuery == false) {

            this.initDate = null;
            this.endDate = null;
            this.onRangedAllDevices = false;


            if (this.selectedDevice) {
                this.ddb.getDeviceHistoric(this.selectedDevice.DgIdTr, this.onHistoricData.bind(this));
                this.ddb.getDeviceHistoricTime(this.selectedDevice.DgIdTr, this.onHistoricTimeData.bind(this));
            }
            else if (this.selectedCity) {

                this.onRangedAllDevices = false;
                this.ddb.getAllLastState(this.selectedRegional, this.selectedCity, this.onGetAllDevices.bind(this))
            }
            else if (this.selectedRegional) {
                this.onRangedAllDevices = false;
                this.ddb.getAllLastState(this.selectedRegional, null, this.onGetAllDevices.bind(this))
            }
        }

    }

    setRangedHistoric() {

        if (this.loadingQuery == false) {

            if (this.initDate && this.endDate) {

                this._initDate = new Date(this.initDate).getTime();
                this._endDate = new Date(this.endDate).getTime();


                var _initDate = this._initDate;
                var _endDate = this._endDate;

                if (_initDate > _endDate) {
                    alert("Digite fechas válidas");
                }
                else {

                    this.loadingQuery = true;



                    this.onRangedHistoric = true;
                    this.ranged = true;
                    this.infoRangedText = "Datos desde  " + this.initDate + "  " + "  hasta  " + this.endDate;
                    console.log(this.initDate);
                    console.log(this.endDate)


                    if (this.checkIfDevices == false) {


                        if (this.selectedDevice) {

                            // get historic data from DynamoDB
                            this.isDevice = false;

                            this.ddb.getDeviceRangedHistoric(this.actualDevice.DgIdTr, _initDate, _endDate, this.onHistoricData.bind(this));
                        }
                        else if (this.selectedCity) {
                            this.isAllDevices = false;
                            this.onRangedAllDevices = true;
                            this.ddb.getRangedHistoricFromFilter(this.selectedRegional, this.selectedCity, this.initDate, this.endDate, this.onGetAllDevices.bind(this));
                            //      this.ddb.getRangedHistoricFromFilterBatch(this.selectedRegional, this.selectedCity,_initDate, _endDate, this.onGetAllDevices.bind(this), this.decodeDataDevice);
                        }
                        else if (this.selectedRegional) {
                            this.isAllDevices = false;
                            this.onRangedAllDevices = true;

                            this.ddb.getRangedHistoricFromFilter(this.selectedRegional, null, this.initDate, this.endDate, this.onGetAllDevices.bind(this));
                            //this.ddb.getRangedHistoricFromFilterBatch(this.selectedRegional, null, _initDate, _endDate, this.onGetAllDevices.bind(this), this.decodeDataDevice);
                        }
                        else if (this.selectedEnterprise) {

                            this.isAllDevices = false;
                            this.onRangedAllDevices = true;

                            console.log("selectedEnterprise!");
                            //alert("No se puede filtrar rango de fechas por empresa");
                            this.ddb.getRangedHistoricFromFilter(null, null, this.initDate, this.endDate, this.onGetAllDevices.bind(this))
                        }
                    }


                    else {
                        if (this.selectedDevice) {

                            // get historic data from DynamoDB
                            this.isDevice = false;

                            this.ddb.getDeviceRangedHistoric(this.actualDevice.DgIdTr, _initDate, _endDate, this.onHistoricData.bind(this));
                        }
                    }
                }

            }
        }

    }
    onTransportadoraData(err, data) {
        if (!err) {

            console.log("onhistorictimedata", data);
            if (data.Items[0]) {
                this.deviceInfo.transportadora = data.Items[0];
            }
        }
    }

    onHistoricTimeData(err, data) {

        if (!err) {

            console.log("onhistorictimedata", data);
            if (data.Items[0]) {
                this.timeItem = data.Items[0];
            }
        }

    }
    onHistoricData(err, data) {

        this.loadingQuery = false;

        this.isDeviceForGraphs = false;
        console.log(err);
        console.log(data);
        this.decodeDataDevice = [];
        this.newData = [];
        if (!err) {
            this.newData = data.Items;

            if (this.newData.length == 0) {
                this.showNoData = true;
            } else {
                this.showNoData = false;
            }


            this.device = this.actualDevice;

            this.device.realtime = {};

            for (let key in this.device.DgAttributes) {

                this.device.realtime[key] = [];
            }

            data.Items = data.Items.reverse();

            this.actualData = data.Items;

            data.Items.map((item) => {
                this.decodeDataDevice.push(item);


                for (let key in this.device.realtime) {

                    var found = null;

                    item.DdgData.map((dataItem) => {

                        if (this.device.realtime[dataItem.variable] && key == dataItem.variable) {


                            found = {
                                valor: dataItem.valor,
                                date: item.DdgDatetimeServer
                            };



                        }


                    })

                    if (found) {
                        this.device.realtime[key].push({
                            valor: found.valor,
                            date: item.DdgDatetimeServer
                        })
                    }
                    else {
                        this.device.realtime[key].push({
                            valor: "0",
                            date: item.DdgDatetimeServer
                        })
                    }



                }





            })

            this.decodeDataDeviceReversed = [];

            this.decodeDataDeviceReversed = this.decodeDataDevice.reverse();

            var newRealtime = this.device.realtime;

            for (let key in this.device.realtime) {
                if (this.device.realtime[key].length >= 1) {

                }
                else {
                    delete newRealtime[key]
                }
            }
            this.device.realtime = newRealtime;


            console.log("device", this.device);

            console.log("HISTORIC DATA!", data);
            console.log("actual DEvice", this.device);


            this.displayedColumns = ['name', 'ubication', 'date', 'event'];
            // this.displayedColumns = ['date', 'event'];
            for (let key in this.actualDevice.DgAttributes) {
                console.log(key);

                if (!this.actualDevice.DgAttributes[key].hideInHistoric) {
                    this.displayedColumns.push(key);
                }
            }

        }

        if (!this.onRangedHistoric) {
            this.intervalData();
        }
        this.isDevice = true;
        this.isDeviceForGraphs = true;

        this.getDirections();
    }

    onMouseOver(infoWindow, gm) {

        if (gm.lastOpen != null) {
            gm.lastOpen.close();
        }

        gm.lastOpen = infoWindow;

        infoWindow.open();
    }

    getDirections() {

        var newCoords = []

        this.decodeDataDevice.map((item) => {

            var passed = true;

            item.DdgData.map((dataItem) => {

                if (dataItem.variable && dataItem.variable == "fixValid") {
                    if (dataItem.valor == "0" || dataItem.valor == 0) {
                        passed = false;
                    }
                }
            })

            if (item.DdgLatDV && item.DdgLongDV) {

                if (passed) {

                    console.log("PASEEEEEEEEEEED!", item);
                    newCoords.push({
                        lat: item.DdgLatDV,
                        lng: item.DdgLongDV
                    })
                }
            }
        })

        this.coordsToShow = newCoords;

        if (newCoords.length == 0) {
            this.noCoords = true;
        }

        var query = "query getDirections($coords: String) { getDirections(coords: $coords) { info { duration distance }  directions { lat lng } }  }";

        var _data = {
            query: query,
            variables: {
                coords:
                    JSON.stringify({
                        "data": {
                            "coords": newCoords
                        }
                    })

            }
        };

        console.log("Query _data", _data);


        fetch('https://api3.azlogica.com/graphql',

            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },

                body: JSON.stringify(_data) // body data type must match "Content-Type" header
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data);

                if (data.data.getDirections) {

                    var newPoly = [];

                    data.data.getDirections.directions.map((item, index) => {

                        if (index == 0) {
                            this.latitude = parseFloat(item.lat);
                            this.longitude = parseFloat(item.lng);
                            this.zoom = 17;
                        }

                        newPoly.push({
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng)
                        })
                    })

                    this.polyline = newPoly;
                    // this.polyline = data.data.getDirections.directions;
                }
            });

    }

    onFirebaseData() {


    }

    openDialog() {

        if (this.loadingQuery == false) {
            const dialogRef = this.dialog.open(ShowDialogComponent, {
                height: '350px'
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log(`Dialog result: ${result}`);
                if (`${result}` == 'true') {
                    this.typeArchive = true;

                } else {
                    this.typeArchive = false;
                }
                this.export(this.typeArchive);
            });
        }


    }

    export(typeArchive) {
        console.log("YOU`RE ON FIRST EXPORT METHOD");
        console.log("HOW ARRIVE EXPORT TYPEARCHIVE: ", typeArchive);
        if (typeArchive != undefined) {

            let data = [];
            let obj = {};
            var date: any;
            var motive: any;
            var attributes: any;
            var device: any = this.actualDevice.DgAlias;
            var header = ["Dispositivo", "Fecha", "Evento"];
            var attrs = {};
            for (let j in this.decodeDataDevice) {

                date = this.decodeDataDevice[j].DdgDatetimeServer;
                motive = this.returnStateInfoText(this.decodeDataDevice[j].DdgMotivo);
                attributes = this.decodeDataDevice[j].DdgData;

                attributes.map((item, i) => {

                 
                    if(item.variable == 'denominations' && item.variable != 'N/A'){
                     
                        let val = this.returnAttrValue(attributes, item.variable);
                        console.log("vallll", val)
                        if(val != 'N/A'){

                            val = JSON.parse(val);

                            console.log("!val!", val);

                            attrs['Billete 1000'] = 0;
                            attrs['Billete 2000'] = 0;
                            attrs['Billete 5000'] = 0;
                            attrs['Billete 10000'] = 0;
                            attrs['Billete 20000'] = 0;
                            attrs['Billete 50000'] = 0;
                            attrs['Billete 100000'] = 0;
                    //       "1000":0,"2000":0,"5000":0,"10000":0,"20000":5,"50000":0,"100000":0
                            for (let denom in val){

                                if(denom == '1000'){
                                    attrs['Billete 1000'] = val[denom];
                                }
                                else if (denom == '2000'){
                                    attrs['Billete 2000'] = val[denom];
                                }
                                else if (denom == '5000'){
                                    attrs['Billete 5000'] = val[denom];
                                }
                                else if (denom == '10000'){
                                    attrs['Billete 10000'] = val[denom];
                                }
                                else if(denom == '20000'){
                                    attrs['Billete 20000'] = val[denom];
                                }
                                else if(denom == '50000'){
                                    attrs['Billete 50000'] = val[denom];
                                }
                                else if (denom == '100000'){
                                    attrs['Billete 100000'] = val[denom];
                                }
                            }
                        }
                    }
                    else {

                

                    if (this.actualDevice.DgAttributes[item.variable]) {


                        let vari = this.actualDevice.DgAttributes[item.variable].alias;

                        let val = this.returnAttrValue(attributes, item.variable);

                        attrs[vari] = val;
                    }

                }


                });

                obj = { Dispositivo: this.decodeDataDevice[j].DdgNameDV, Fecha: date, Evento: motive, ...attrs }

                data.push(obj);
            }
            for (let i in attrs) {
                header.push(i);
            }

            if (typeArchive) {
                console.log("DATAAAAAA EXCEL: ", data);
                this.historicExport.exportHistoricExcel(data, "Historico");
            } else {
                console.log("DATAAAAAA CSV: ", data);
                this.historicExport.exportHistoricCsv(data, "Historico", header.toString());

            }
        }

    }

    prepareSearchBar() {
        $(document).ready(function () {

            var $searchTrigger = $('[data-ic-class="search-trigger"]'),
                $searchInput = $('[data-ic-class="search-input"]'),
                $searchClear = $('[data-ic-class="search-clear"]');

            $searchTrigger.click(function () {

                var $this = $('[data-ic-class="search-trigger"]');
                $this.addClass('active');
                $searchInput.focus();

            });

            $searchInput.blur(function () {

                if ($searchInput.val().length > 0) {

                    return false;

                } else {

                    $searchTrigger.removeClass('active');

                }

            });

            $searchClear.click(function () {
                $searchInput.val('');
            });

            $searchInput.focus(function () {
                $searchTrigger.addClass('active');
            });

        });

    }
    data() {
        return this.decodeDataDeviceReversed;
    }
    returnSensorsCount(device) {
        //  console.log(device);
        var j = 0;
        for (let i in device.value.DgAttributes) {
            j++;
            //  console.log(i);
        }
        return j;
    }

    returnStateInfoText(val) {
        return this._utils.getStateReason(val).text;
    }
    setActualDevice(trama) {


        this.actualDevice = {
            DgMac: ""
        }
        var comp = this;
        this.devices$.forEach((data) => {

            console.log(data);
            console.log(trama.DdgMacDV);
            if (data != undefined) {
                console.log("passed")
                if (data[trama.DdgMacDV]) {
                    console.log("passed2");
                    comp.actualDevice = data[trama.DdgMacDV]
                }
            }

        })
        this.store.dispatch(new layout.OpenModalAction(true));
    }

    returnDeviceImage(mac) {

        return "https://s3.amazonaws.com/azcognito-iot/" + mac;
    }

    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, "test_local"); // returns '11 hours ago'
        // console.log(time);
        return time;
    }
    returnAttrValue(DdgData, position) {

        var device = this.actualDevice;


        var trueItem;
        var _item: any = 'N/A';

        if (this.onRangedHistoric && this.loadingQuery == false) {
          //  console.log("datafromddgdata", DdgData)
        }

        DdgData.map((item) => {

            if (item) {


                if (item.variable == position) {

                    _item = item.valor;
                    trueItem = item.valor;
                }
            }
        });

        if (_item == null || _item == undefined || _item == "{}") {
            _item = "N/A";
        }

        if (_item != 'N/A') {

            var newVal: any = _item;

            if (device.DgAttributes[position].text == true) {
                _item = _item;
            }

            else {



                /*this.devices$.forEach((data) => {
                    device = data[this.deviceMac];
                }) */
                if (device.DgAttributes[position].representacion == 'Hex') {
                    _item = parseInt(_item, 16);

                }

                if (_item && _item != " ") {
                    var factorConversion = device.DgAttributes[position].factorConversion;
                    factorConversion = factorConversion.replace("{valor}", _item);
                    newVal = eval(factorConversion);
                }
                var _length = newVal.toString().length;



                if (_length >= 5 && device.DgAttributes[position].unidad != 'V') {
                    if (device.DgAttributes[position].textoLargo) {

                    }
                    else {
                        newVal = newVal.toString().slice(0, 5);
                    }
                }


                if (_length >= 5 && device.DgAttributes[position].render != 'texto') {
                    if (device.DgAttributes[position].textoLargo) {

                    }
                    else {
                        newVal = newVal.toString().slice(0, 5);
                    }

                }

                _item = newVal;

                if (device.DgAttributes[position].unidad == 'dec' || device.DgAttributes[position].unidad == 'b') {

                }
                else {
                    _item = device.DgAttributes[position].unidad ? _item + " " + device.DgAttributes[position].unidad : _item;
                }

                if (device.DgAttributes[position].render == 'switch') {
                    _item = _item == 1 ? 'On' : 'Off';
                }
            }
        }

        if (device.DgAttributes[position].advertencia && trueItem) {

            var percentage = trueItem / device.DgAttributes[position].advertencia.value;

            _item = percentage.toFixed(0);

            _item = _item + " %";

        }

        if (device.DgAttributes[position].customValues && trueItem) {

            _item = device.DgAttributes[position].customValues[trueItem.toString()].value;

        }


        return _item


    }
    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            console.log("scanning Devices");
            //GET
        }
    }



}


export interface UserData {
    id: string;
    name: string;
    progress: string;
    color: string;
}
@Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: './modal.html',
    styleUrls: ['./historic.css'],
})
export class ShowDialogComponent { }

export class ExampleDataSource extends DataSource<any> {

    firstData = true;

    myPaginator = null;
    myData = null;

    constructor(private _exampleDatabase: HistoricComponent, private _paginator: MatPaginator) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UserData[]> {

        const displayDataChanges = [
            this._exampleDatabase.devices$,
            this._paginator.page,
        ];

        return Observable.merge(...displayDataChanges).map(() => {

            var edata = this._exampleDatabase.data();
            var _data = [];

            console.log("this.myPaginator.pageIndex", this._paginator.pageIndex);
            var _posCount = 0;
            var count = 0;

            edata.map((item) => {

                if (!_data[_posCount]) {
                    _data[_posCount] = [];
                }


                _data[_posCount].push(item);


                count++;

                if (count == 99) {

                    _posCount++;

                    count = 0;

                }


            });

            return _data[this._paginator.pageIndex];




            var newData = this.myData;

            console.log("ISFIRST DATA!", this.firstData);
            if (this.firstData) {
                newData = this._exampleDatabase.data();



                if (newData.length >= 1) {

                    this.myData = newData;
                    this.myPaginator = this._paginator;
                    this.firstData = false;

                }
            }
            else {

            }

            const data = newData;
            console.log(data);
            //  return data;
            // Grab the page's slice of data.
            //     const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            const startIndex = this.myPaginator.pageIndex * this.myPaginator.pageSize;

            console.log("myPagunator!", this.myPaginator);

            var _tempData = data;
            return _tempData.splice(startIndex, this.myPaginator.pageSize);
        });
    }

    disconnect() { }
}

