import { Component, OnInit, Input,  Output } from '@angular/core';

@Component({
    selector: 'profilecard-item',
    templateUrl: 'profilecard.html',
    styleUrls: ['./profilecard.css']
 
})

export class ProfileCardComponent implements OnInit {

    @Input() device: any;
    @Input() image: any;



    public inImageModal =  false;
    public selectedImage = null;




    constructor(){}
    ngOnInit(){}



    closeModal() {
        this.inImageModal = false;
    }

    openImage(imageMac) {

        this.inImageModal = true;

        this.selectedImage = imageMac;

 
    }



}