import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.css']
})
export class TextBoxComponent implements OnInit {

  @Input() attributtes: any;
  @Input() other: any
  
  format: any = {
    "40011": "V",
    "40087": "S",
    "40103": "S"
  };

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){}

  parseData(item: any){
    return (this.format[item.variable]) ?  item.valor + " " + this.format[item.variable] : item.valor;
  }




}
