import { Component, OnInit, Input,  Output } from '@angular/core';

@Component({
    selector: 'trafficlightcard-item',
    templateUrl: 'trafficlightcard.html',
    styleUrls: ['./trafficlightcard.css']
 
})

export class TrafficLightCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;
    @Input() icon: any;

    constructor(){}
    ngOnInit(){

        console.log("Traffic value name:" + this.name + "   , value:" + this.value )
    }
}