import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from "rxjs";
import { Color, BaseChartDirective } from 'ng2-charts';
import { Store } from "@ngrx/store";

import * as fromRoot from '../../../common/index';
import * as layout from '../../../common/layout/layout.actions';
import * as devices from '../../../common/devices/devices.actions';
import { DynamoDBIOTService } from "../../../service/ddbiot.service";



@Component({
    selector: 'attributeschart-realtime',
    templateUrl: 'attributeschart.html',
    styleUrls: ['./attributeschart.css']

})

export class AttributesChartComponent implements OnInit {

    @ViewChild(BaseChartDirective, { read: false }) chart: BaseChartDirective;
    @Input() mac: any;
    @Input() type: any;
    @Input() device: any;
    firstInit: boolean = true;

    chartOptions = {
        responsive: true,
        maintainAspectRatio: false,

        elements: {
            line: {
                    fill: false
            }
        }
    };

    chartData = [];


    randomColor = {
        backgroundColor: '#4DB6AC',
        borderColor: 'rgba(77,83,96,1)'
    };

    chartLabels = [];
    realLength;


    realtimeData: any = [];



    myDataSaved = null;

    public devices$: Observable<any>;
    constructor(public db: DynamoDBIOTService, private store: Store<fromRoot.AppState>) {
        this.devices$ = store.select(fromRoot.getDevicesEntities);




    }


    // events
    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
       

        setTimeout(() => {


            var items = [];

            this.chart.chart.config.data.datasets.map((dataItem) => {

            

                var hidden = false;

                var foundInMeta= false;
                for (let i in dataItem._meta) {

                    if (dataItem._meta[i].hidden != null) {
                        hidden = dataItem._meta[i].hidden;
                        foundInMeta = true;
                    }
                

                }

                if(!foundInMeta){
                    hidden = dataItem.hidden;
                }
                items.push({
                    label: dataItem.label,
                    key: dataItem.key,
                    hidden
                })
            })


            localStorage.setItem('graphicSaved' + this.mac, JSON.stringify(items))
        }, 100);


    }

    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    public hideOne() {



        console.log("HIDE ONE!", this.chart);

        this.chart.chart.config.data.datasets[2].hidden = true;
        this.chart.datasets.map((item, i) => {
            item.display = false;
        })

        this.chart.chart.update();
    }

    changeChart(value) {

        var params = {
            "DgMac": this.mac,
            //"AttrName": this.attrname,
            "NewRealtimeChart": value
        }

        this.store.dispatch(new devices.UpdateAttrRealtimeChartAction(params));
        // this.db.updateAttributeRealtimeChart(this.mac, this.attrname, value, this.onChangedChart.bind(this));

    }

    onChangedChart() {

    }

    onChartClick(event) { }

    newDataPoint(dataArr = [100, 100, 100], label) {

        this.chartData.forEach((dataset, index) => {
            this.chartData[index] = Object.assign({}, this.chartData[index], {
                data: [...this.chartData[index].data, dataArr[index]]
            });
        });

        this.chartLabels = [...this.chartLabels, label];

    }
    returnDataSet() {

        if (this.chartData.length >= 1) {
            var le = this.device.realtime[this.chartData[0].key].length;
            var data = this.device.realtime[this.chartData[0].key];
            if (le != this.realLength) {
                var dataLength = 20;
                if (data.length >= dataLength) {
                    this.chartData[0].data.splice(0, 1);
                    this.chartLabels.splice(0, 1);
                }

                for (let key in this.device.realtime) {

                    if (this.device.DgAttributes[key]) {

                        let _index = this.device.DgAttributes[key].index;
                        this.device.realtime[key].map((value, index) => {

                            if (index == le - 1) {

                                this.chartData[_index] = Object.assign({}, this.chartData[_index], {
                                    data: [...this.chartData[_index].data, value.valor]
                                });

                                if (_index == 0) {

                                    this.chartLabels = [...this.chartLabels, value.date];
                                }
                            }
                        });
                    }
                }
                this.realLength = le;
            }
        }


        return this.chartData;
    }
    async ngOnInit() {

        this.firstInit = true;
        this.realLength;

        this.chartData = [];
        this.chartLabels = [];


        var dataSaved = null;
        if (this.mac) {
            var graphicSaved = await localStorage.getItem('graphicSaved' + this.mac);

            if (graphicSaved) {
               
                dataSaved = JSON.parse(graphicSaved);
                this.myDataSaved = dataSaved;
            }
        }

       


        let i = 0;
        for (let key in this.device.realtime) {
            if (this.device.DgAttributes[key] != undefined) {

                var hidden = false;

                if (dataSaved) {
                    dataSaved.map((savedItem) => {
                        if (savedItem.label == this.device.DgAttributes[key].alias) {
                            if (savedItem.hidden) {
                                hidden = true;
                            }
                        }
                    })
                }

                this.chartData.push({
                    data: [],
                    label: this.device.DgAttributes[key].alias,
                    key: key,
                    hidden
                })
                this.device.DgAttributes[key].index = i;
                i++;
            }
        }

        for (let key in this.device.realtime) {

            if (this.device.DgAttributes[key]) {

                let _index = this.device.DgAttributes[key].index;

                this.device.realtime[key].map((value, index) => {

                    this.chartData[_index] = Object.assign({}, this.chartData[_index], {
                        data: [...this.chartData[_index].data, value.valor]
                    });

                    if (_index == 0) {

                        this.chartLabels = [...this.chartLabels, value.date];
                    }

                });
            }

        }


        // this.chartLabels = [...this.chartLabels, label];






        /*    this.chartData[0].label = this.deviceName;
            this.realLength;
    
            var le = this.realtimeData.length;
            var arrd = [0];
            var dataLength = 20;
    
            this.realtimeData.map((value, index) => {
    
                arrd.push(value.valor);
                this.chartData[0] = Object.assign({}, this.chartData[0], {
                    data: [...this.chartData[0].data, value.valor]
                });
    
                this.chartLabels = [...this.chartLabels, value.date];
            })*/


    }
}