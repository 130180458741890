// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  azbeaconsPage: true,

  production: false,

  enterprise : 'evolucion',
  region: 'us-east-1',

  urlGraph:'https://api3.azlogica.com/graphql',
  AzureclientId : '7adb412d-615e-42e9-8e93-b9912fe1627c',
  

  identityPoolId: 'us-east-1:b24b722d-db5a-47a6-8ccf-f0c952ae6dab',
  userPoolId: 'us-east-1_ZOOc3mUiu',
  clientId: '4s9k9ojptc50dou6l5lp6t07ve',


  rekognitionBucket: 'bluz.azlogica.com',
  albumName: "usercontent",
  bucketRegion: 'us-east-1',
  ddbDecodeDataTableName: 'decode-data',
  ddbBeaconsTableName: 'ibeacons',
  ddbTableName: 'LoginTrailbudilovdelete',
  bucketBeaconsImages: 'prueba',

  firebase: {
    apiKey: "AIzaSyDsFNqW6CCTbRSsyyaI35PHfMICv7mgKGQ",
    authDomain: "iot-gpio.firebaseapp.com",
    databaseURL: "https://iot-gpio.firebaseio.com",
    projectId: "iot-gpio",
    storageBucket: "iot-gpio.appspot.com",
    messagingSenderId: "362455784399"
  }
};

/*
 identityPoolId: 'us-east-1:3d066282-9a20-4bdb-a04f-6c09b64bc22a',
  userPoolId: 'us-east-1_9D36GHiz9',
  clientId: '283v8959frsjisguu1k0mk4mq0',

  */