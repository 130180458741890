import { Component, OnInit, Input,  Output } from '@angular/core';
import { Router } from "@angular/router";
import { DynamoDBIOTService } from "../../service/ddbiot.service";
import { utils } from '../../lib/utils';

@Component({
    selector: 'tagcard-item',
    templateUrl: 'tagcard.html',
    styleUrls: ['./tagcard.css']
 
})

export class TagCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;
    @Input() item: any;

    motivo = null;

    constructor(public router: Router, public db: DynamoDBIOTService, private _utils: utils){}
    ngOnInit(){

      //  console.log("itemmmmtagcard");
       // console.log(this.item);
        if( this.item && this.item.DdgMotivo!= 1){
            this.motivo = this._utils.getStateReason(this.item.DdgMotivo).text;
        }
    }

    getForm(){
        this.db.getSingleForm('Formulario seguridad vial_'+ this.value, this.onFormClick.bind(this) );
    }

    onFormClick(err,data){
        if(!err){
            if(data.Items[0]){
                var item = data.Items[0];
              //  console.log("yeeeeees");
             //   console.log(item);
                this.router.navigate(['/securehome/forms', item.FrDeviceMac, item.FrTstamp]);
            }
        }
    }
}