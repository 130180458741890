import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";

import { AppComponent } from "./app.component";


import { HomeLandingComponent, AboutComponent, HomeComponent } from "./public/home.component";
import { LoginComponent } from "./public/auth/login/login.component";
import { RegisterComponent } from "./public/auth/register/registration.component";
import { ForgotPassword2Component, ForgotPasswordStep1Component } from "./public/auth/forgot/forgotPassword.component";
import { RegistrationConfirmationComponent, LogoutComponent } from "./public/auth/confirm/confirmRegistration.component";
import { ResendCodeComponent } from "./public/auth/resend/resendCode.component";


import {HomeDashboardComponent} from "./secure/home/home.component";
import {SecureHomeComponent} from "./secure/landing/securehome.component";
import {NewsComponent} from "./secure/news/news.component"
import {DevicesComponent} from "./secure/devices/devices.component"
import {AdminComponent} from "./secure/admin/admin.component";
import {MapComponent} from "./secure/map/map.component";
import {EquipmentInLineComponent} from './secure/equipmentinline/equipmentinline.component';
import {EquipmentComponent} from './secure/equipment/equipment.component';
import { RealtimeComponent } from './secure/realtime/realtime.component';
import { HistoricComponent } from './secure/historic/historic.component';
import {ReportsComponent} from './secure/reports/reports.component'
import { FormsComponent } from "./secure/forms/forms.component";
import { FormComponent } from "./secure/form/form.component";
import { TerminalComponent } from './secure/terminal/terminal.component';



const homeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        children: [
            { path: 'about', component: AboutComponent },
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent },
            { path: 'resendCode', component: ResendCodeComponent },
            { path: 'forgotPassword/:email', component: ForgotPassword2Component },
            { path: 'forgotPassword', component: ForgotPasswordStep1Component },
            { path: '', component: HomeLandingComponent }
        ]
    },
];

const secureHomeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/securehome',
        pathMatch: 'full'
    },
    {
      path: 'securehome', component: SecureHomeComponent, children: [
            {path: 'news', component: NewsComponent},
            {path: 'devices', component: DevicesComponent},
            {path: 'admin', component: AdminComponent},
            {path : 'map', component: MapComponent},
            {path: 'equipmentinline', component: EquipmentInLineComponent},
            {path: 'equipment', component: EquipmentComponent},
            {path: 'realtime' , component: RealtimeComponent},
            {path: 'historic' , component: HistoricComponent},
            {path: 'reports' , component : ReportsComponent },
            {path: 'forms/:mac/:tstamp' , component : FormComponent },
            {path: 'forms' , component : FormsComponent },
            {path: 'terminal' , component : TerminalComponent },

            {path: '', component: HomeDashboardComponent}
      ]
    }

];

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        children: [
            ...homeRoutes,
            ...secureHomeRoutes,
            {
                path: '',
                component: HomeComponent
            }
        ]
    },
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true } );