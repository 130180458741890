import { Component, OnInit, Input,  Output } from '@angular/core';

@Component({
    selector: 'mapcard-item',
    templateUrl: 'mapcard.html',
    styleUrls: ['./mapcard.css']
 
})

export class MapCardComponent implements OnInit {

    @Input() item: any;

    constructor(){}
    ngOnInit(){
       
    }
}