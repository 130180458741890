import { HostListener, Injectable, Component, OnInit, Input, EventEmitter, Output, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import {
    DomSanitizer,
    SafeHtml,
    SafeUrl,
    SafeStyle,
    SafeResourceUrl
} from '@angular/platform-browser';

import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { SharedService } from "../../service/global.service";
import { Store } from "@ngrx/store";
import * as fromRoot from '../../common/index';
import * as layout from '../../common/layout/layout.actions';
import * as devices from '../../common/devices/devices.actions';

import { utils } from '../../lib/utils';
import { DynamoDBIOTService } from "../../service/ddbiot.service";
import timeago from 'timeago.js';

import { DynamoDBService } from "../../service/ddb.service";
import { MatDialog } from '@angular/material';

declare var AWS: any;

@Injectable()
export class WindowRef {
    constructor() { }

    getNativeWindow() {
        return window;
    }
}
interface MyWindow extends Window {
    myFunction(): void;
}

declare var window: MyWindow;

@Component({
    selector: 'device-item',
    templateUrl: 'device.html',
    styleUrls: ['./device.css', './flat.css', './buttons.css']

})
export class DeviceComponent implements OnInit {

    @Input() image: string;
    @Input() deviceMac: any;
    @HostListener('scroll', ['$event'])
    cameraImages = [];
    nativeWindow: any;
    lastPhoto;
    lastKey = "";
    maxSize = 25;
    wouldFetchMore = true;
    inImageModal = false;
    selectedImage = null;
    isFecthing = false;
    firsTime = true;
    calderaHistoric = false;

    ngOnChanges(changes: SimpleChanges) {
        // console.log("changeeeeees");
        // console.log("Copitas ????", this.deviceMac);
        // this.doSomething(changes.categoryId.currentValue);
        // You can also use categoryId.previousValue and 
        // categoryId.firstChange for comparing old and new values

        setTimeout(() => {


            this.devices$.forEach((data) => {
                // console.log(this.deviceMac);
                // console.log(data);
                // console.log(data[this.deviceMac]);
                this.cameraImages = [];
                this.deviceActual = data[this.deviceMac];
                this.checkIfCamera(this.deviceActual);
                console.log("Copas el device acual", this.deviceActual);
            })


        }, 500);

    }

    deviceActual: any = {};
    s3;
    values = [];

    public actualDomain;
    public devices$: Observable<any>;

    objectKeys = Object.keys;


    constructor(public router: Router, private winRef: WindowRef, private sanitization: DomSanitizer,
        public dialog: MatDialog, public sharedService: SharedService,
        public db: DynamoDBIOTService, private _utils: utils, private store: Store<fromRoot.AppState>) {
        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.nativeWindow = winRef.getNativeWindow();
        // the local dict example is below.
        var test_local_dict = function (number, index, total_sec) {
            // number: the timeago / timein number;
            // index: the index of array below;
            // total_sec: total seconds between date to be formatted and today's date;
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];


        };
        // register your locale with timeago
        timeago.register('test_local', test_local_dict);
        console.log("1")

        setTimeout(() => {

            var device;
            this.devices$.forEach((data) => {
                device = data[this.deviceMac];
                console.log("dataaaa");
                console.log(data);
                console.log(this.deviceMac);
                // this.checkIfCamera(device);
            })
        }, 1000);

    }
    checkIfCamera(device) {
        if (device.DgDeviceType == 'camara') {
            this.cameraImages = [];
            this.lastPhoto = null;
            this.lastKey = "";
            this.wouldFetchMore = true;
            this.isFecthing = false;

            this.getImages();

            this.firsTime = true;
            var s = setInterval(() => {
                this.getImages();
            }, 60000);
        }
    }
    onScroll(event: any) {
        var newScrollLeft = event.target.scrollLeft;
        var width = event.target.clientWidth;
        var scrollWidth = event.target.scrollWidth;

        if (scrollWidth - newScrollLeft == width) {
            //alert('right end');
            if (this.wouldFetchMore && this.deviceActual.DgDeviceType == 'camara' && !this.isFecthing) {
                this.fetchMore();
            }
        }

    }
    close() {
        console.log("closssssseeer")
        console.log(this.store);
        this.store.dispatch(new layout.CloseModalAction(false));
    }
    returnGG(name) {
        //  console.log("holinguiiiiiiiiiiiis");
        var device = this.deviceActual;
        //console.log(device.realtime.Ird.length);
        return device.realtime.Ird
    }

    ngOnInit() {
        this.actualDomain = this.sharedService.domains[this.sharedService.myDomain];
        console.log("get Imagesssssssssssssssssssssssss");
        AWS.config.update({
            region: 'us-east-1',

        });

        this.s3 = new AWS.S3({
            region: 'us-east-1',
            apiVersion: '2006-03-01',
            credentials: new AWS.Credentials({
                accessKeyId: 'AKIAIML5VHZTZZJYO2YA',
                secretAccessKey: '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'
            })
            //params: {Bucket: environment.rekognitionBucket}
        });
    }
    deviceExist(name) {
        var device;
        this.devices$.forEach((data) => {
            device = data[this.deviceMac];
        })
        if (device.DgAttributes[name]) {
            return true;
        }
        else {
            return false;
        }
    }
    returnDevice() {
        var device = {
            DgAlias: "",
            DgName: ""
        };
        this.devices$.forEach((data) => {

            if (data != undefined) {
                if (this.deviceMac) {
                    if (data[this.deviceMac]) {
                        device = data[this.deviceMac];
                    }
                }
            }

        })
        return device;
    }


    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, "test_local"); // returns '11 hours ago'
        // console.log(time);
        return time;
    }

    returnSensorRender(name) {
        //console.log("Wtf", name);
        var value = ""
        if (this.deviceActual.DgAttributes) {
            if (this.deviceActual.DgAttributes[name]) {
                value = this.deviceActual.DgAttributes[name].render;
            }
        }
        return value;
    }

    getUrlByFileName(fileName) {
        return new Promise(
            (resolve, reject) => {
                this.s3.getObject({ Bucket: "azlogica-scouting-output", Key: fileName }, function (err, file) {

                    var data = file.Body;
                    var str = data.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
                    var fixed = btoa(str).replace(/.{76}(?=.)/g, '$&\n');
                    var result = "data:image/jpeg;base64," + fixed;
                    resolve(result)
                });
            }
        );
    }
    encode(data) {
        var str = data.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
        return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
    }

    openInNewTab(data) {
        console.log("dataaaaaaa", data);
        var html = '<html><head></head><body><img src="' + data + '" width="1000px" height="1000px"></body></html>';
        var uri = "data:text/html," + encodeURIComponent(html);
        var newWindow = window.open(uri);
        //var newTab = window.open('www.google.com');
        //new.location = '/#/link/' + res;
        //newTab.document.body.innerHTML = '<img src="'+data+'" width="1000px" height="1000px">';

    }
    fetchMore() {
        console.log(this.lastKey);
        this.isFecthing = true;
        var params = {
            Marker: this.lastKey,
            Bucket: "azlogica-scouting-output",
            Prefix: "images/" + this.deviceMac + "/small",
            MaxKeys: this.maxSize,

        };
        console.log(params);
        this.s3.listObjects(params, (err, data) => {

            if (err) {
                console.log(err, err.stack); // an error occurred
            }
            else {
                console.log("data", data);
                this.wouldFetchMore = data.IsTruncated;
                //   this.lastPhoto = data.Contents[data.Contents.length-1];
                this.lastKey = data.Contents[data.Contents.length - 1].Key;
                var dataContents = data.Contents.reverse();
                dataContents.map(async (item) => {
                    var newItem = item;
                    newItem.FixedDate = item.LastModified.toString().slice(0, 24);
                    newItem.Url = 'https://s3.amazonaws.com/azlogica-scouting-output/' + item.Key
                    var largeimage = item.Key.replace('small', 'large');
                    newItem.LargeUrl = 'https://s3.amazonaws.com/azlogica-scouting-output/' + largeimage;
                    var _data: any = await this.getUrlByFileName(item.Key);
                    //openInNewTab(data);
                    newItem.DataUrl = _data;
                    var dataa: SafeResourceUrl = this.sanitization.bypassSecurityTrustResourceUrl(_data);
                    newItem.Data = dataa;
                    this.cameraImages.push(newItem);

                    //

                    /*this.getUrlByFileName(item.Key).then((data:string) => {
                        //openInNewTab(data);
                        newItem.DataUrl = data;
                        var dataa :SafeResourceUrl = this.sanitization.bypassSecurityTrustResourceUrl(data);
                        newItem.Data = dataa;
                        
                        this.cameraImages.push(newItem);
                    }); */

                })
                this.isFecthing = false;

                console.log(this.cameraImages);

                //  this.orderArrayByDate();
            }
        });

    }

    orderArrayByDate() {
        this.cameraImages.sort((a: any, b: any) => {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            var bd: any = new Date(a.LastModified).getTime();
            var ad: any = new Date(b.LastModified).getTime();
            return ad - bd;
            //  var dateA = new Date(a.LastModified).getTime();
            //  var dateB = new Date(b.LastModified).getTime();
            //  return dateA > dateB ? 1 : -1;  

        });

        console.log("ordereeed");
        console.log(this.cameraImages);
        // this.cameraImages = this.cameraImages.reverse();



    }


    async  getImages() {
        // azlogica-scouting-output/images/0006/small
        this.isFecthing = true;
        var params = {
            Bucket: "azlogica-scouting-output",
            Prefix: "images/" + this.deviceMac + "/small",
            MaxKeys: this.maxSize,
            //   StartAfter: this.lastKey
        };
        var s = await this.s3.listObjects(params, (err, data) => {
            return new Promise(
                async (resolve, reject) => {
                    if (err) {
                        console.log(err, err.stack); // an error occurred
                    }
                    else {
                        console.log("response Images!");
                        // console.log(data);           // successful response
                        //this.cameraImages = data.Items;

                        this.wouldFetchMore = data.IsTruncated;
                        var update = false;

                        if (this.lastPhoto && this.lastPhoto.Key != data.Contents[data.Contents.length - 1].Key) {
                            update = true;
                        }


                        if (update) {
                            var fromIndex = false;
                            console.log("voy a actualizar");
                            await data.Contents.map(async (item, index) => {
                                if (fromIndex) {

                                    var newItem = item;
                                    newItem.FixedDate = item.LastModified.toString().slice(0, 24);
                                    newItem.Url = 'https://s3.amazonaws.com/azlogica-scouting-output/' + item.Key
                                    var largeimage = item.Key.replace('small', 'large');
                                    newItem.LargeUrl = 'https://s3.amazonaws.com/azlogica-scouting-output/' + largeimage;
                                    var _data: any = await this.getUrlByFileName(item.Key);
                                    //openInNewTab(data);
                                    newItem.DataUrl = _data;
                                    var dataa: SafeResourceUrl = this.sanitization.bypassSecurityTrustResourceUrl(_data);
                                    newItem.Data = dataa;
                                    this.cameraImages.unshift(newItem);
                                }

                                if (item.Key == this.lastPhoto.Key) {
                                    fromIndex = true;
                                }


                            })
                            this.lastPhoto = data.Contents[data.Contents.length - 1];
                            // this.lastKey =  data.Contents[data.Contents.length-1].Key;

                            //this.cameraImages = this.cameraImages.reverse();

                        }
                        else {
                            if (this.firsTime) {
                                this.lastPhoto = data.Contents[data.Contents.length - 1];
                                this.lastKey = data.Contents[data.Contents.length - 1].Key;

                                await data.Contents.map(async (item) => {
                                    var newItem = item;
                                    newItem.FixedDate = item.LastModified.toString().slice(0, 24);
                                    newItem.Url = 'https://s3.amazonaws.com/azlogica-scouting-output/' + item.Key
                                    var largeimage = item.Key.replace('small', 'large');
                                    newItem.LargeUrl = 'https://s3.amazonaws.com/azlogica-scouting-output/' + largeimage;
                                    var _data: any = await this.getUrlByFileName(item.Key);
                                    //openInNewTab(data);
                                    newItem.DataUrl = _data;
                                    var dataa: SafeResourceUrl = this.sanitization.bypassSecurityTrustResourceUrl(_data);
                                    newItem.Data = dataa;
                                    this.cameraImages.push(newItem);
                                });

                                this.firsTime = false;


                            }

                            // this.cameraImages = [];                    


                        }
                        this.isFecthing = false;

                    }
                }
            );

        });

        //      this.orderArrayByDate();
    }
    closeModal() {
        this.inImageModal = false;
    }

    openImageDevice(imageMac) {

        this.inImageModal = true;

        this.selectedImage = "https://s3.amazonaws.com/azcognito-iot/" + imageMac;

    }




    openImage(image) {

        this.inImageModal = true;

        console.log("get Imagesssssssssssssssssssssssss");
        console.log(image);
        AWS.config.update({
            region: 'us-east-1',

        });

        var s3 = new AWS.S3({
            region: 'us-east-1',
            apiVersion: '2006-03-01',
            credentials: new AWS.Credentials({
                accessKeyId: 'AKIAIML5VHZTZZJYO2YA',
                secretAccessKey: '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'
            })
            //params: {Bucket: environment.rekognitionBucket}
        });

        var imageKey = image.Key.replace('small', 'large');

        this.getUrlByFileName(imageKey).then((data: string) => {
            // this.openInNewTab(data);

            var dataa: SafeResourceUrl = this.sanitization.bypassSecurityTrustResourceUrl(data);
            this.selectedImage = dataa;
        });
    }
    returnDeviceAttributes(name) {
        var device;
        /*  this.devices$.forEach((data) => {
              device = data[this.deviceMac];
          }) */
        var n = ""
        if (this.deviceActual.DgAttributes) {
            if (this.deviceActual.DgAttributes[name]) {
                n = this.deviceActual.DgAttributes[name]
            }
        }
        return n


    }

    returnSensorName(name) {

        var device;
        /* this.devices$.forEach((data) => {
             device = data[this.deviceMac];
         }) */
        var n = ""
        if (this.deviceActual.DgAttributes) {
            if (this.deviceActual.DgAttributes[name]) {
                n = this.deviceActual.DgAttributes[name].alias;
            }
        }
        return n

    }
    returnSensorValue(name, val) {
        var device = this.deviceActual;
        var newVal = val;
        /*this.devices$.forEach((data) => {
            device = data[this.deviceMac];
        }) */

        if (device.DgAttributes[name].text == true) {
            return val;
        }
        else {


            if (device.DgAttributes[name].representacion == 'Hex') {
                val = parseInt(val, 16);

            }

            if (val && val != " ") {

                var factorConversion = device.DgAttributes[name].factorConversion;
                factorConversion = factorConversion.replace("{valor}", val);
                newVal = eval(factorConversion);
            }


            var _length = newVal.toString().length;
            if (_length >= 5 && device.DgAttributes[name].render != 'texto') {
                if (device.DgAttributes[name].textoLargo) {

                }
                else {
                    newVal = newVal.toString().slice(0, 5);
                }

            }




            if (device.DgAttributes[name].unidad == 'dec' || device.DgAttributes[name].unidad == 'b') {
                return newVal;
            }
            else {
                return device.DgAttributes[name].unidad ? (newVal + " " + device.DgAttributes[name].unidad) : newVal;
            }
        }
    }

    getNewLogData(DdgData) {
        // device.value.log[0].DdgData

        var groupItems: any = {

            noGroup: []
        };
        var newDdgData = [];


        var actualGroup = null;


        DdgData.map(item => {
            var attributes = this.deviceActual.DgAttributes[item.variable];

            if (attributes && attributes.group) {
                if (groupItems[attributes.group]) {
                    groupItems[attributes.group].push(item);
                }
                else {
                    groupItems[attributes.group] = [];

                    var groupItem = {
                        valor: attributes.group,
                        type: 'group'
                    }

                    groupItems[attributes.group].push(groupItem);

                    groupItems[attributes.group].push(item);
                }
            }
            else {
                groupItems.noGroup.push(item);
            }
        })

        if (this.deviceActual.DgAttributesGroups) {

            var newGroupItems = {};

            for (let i in groupItems) {

                if (this.deviceActual.DgAttributesGroups[i]) {

                    newGroupItems[this.deviceActual.DgAttributesGroups[i].position] = groupItems[i];

                }
            }

            groupItems = newGroupItems;

        }

        for (let i in groupItems) {

            var newItems = groupItems[i];

            newItems.map((myItem) => {
                newDdgData.push(myItem);
            })
        }
        /*
                DdgData.map( (item)=>{
                   var attributes = this.deviceActual.DgAttributes[item.variable];
        
                
        
                   if(attributes && attributes.group){
        
                  //  console.log("grouppppppppp:", attributes);
        
                        var groupItem = {
                            valor : attributes.group,
                            type : 'group'
                        }
        
                        if(!actualGroup){
                            actualGroup = attributes.group;
        
                            newDdgData.push(groupItem);
                        }
                        else {
                            if(actualGroup != attributes.group){
                                newDdgData.push(groupItem);
                            }
                        }
                   }
        
                   newDdgData.push(item);
         
                }) */

        return newDdgData;
    }
    returnSensorValueNumber(name, val) {
        var device = this.deviceActual;
        var newVal = val;
        /*   this.devices$.forEach((data) => {
               device = data[this.deviceMac];
           }) */
        if (device.DgAttributes[name].representacion == 'Hex') {
            val = parseInt(val, 16);

        }
        if (newVal.length >= 5) {
            //newVal = newVal.slice(0,5);
        }
        if (val && val != " ") {

            var factorConversion = device.DgAttributes[name].factorConversion;
            factorConversion = factorConversion.replace("{valor}", val);

            //    console.log("Val: ", val);

            //    console.log("factorConversion", factorConversion);
            newVal = eval(factorConversion);
        }

        return newVal;

    }
    returnCheckedSensor(name, val, inverted) {

        var valToReturn = true;
        if (inverted) {
            if (val == "1" || val == 1) {
                valToReturn = false;
            }
            else {
                valToReturn = true;
            }

        }
        else {
            if (val == "1") {
                valToReturn = true;
            }
            else {
                valToReturn = false;
            }
        }

        return valToReturn;
    }

    returnStateInfoText(val) {
        return this._utils.getStateReason(val).text;
    }

    onChangeAlias(data, key) {
        console.log(data);
        console.log(this.values);


        var params = {
            "DgMac": this.deviceMac,
            "AttrName": key,
            "NewAlias": this.values[data]
        }

        this.store.dispatch(new devices.UpdateAttrAliasAction(params));
        this.db.updateAttributeAlias(this.deviceMac, key, this.values[data], this.onChangedAlias.bind(this));

        this.values[data] = "";
    }

    onChangedAlias(err, data) {
        console.log(err);
        console.log(data);
    }

    openDialog(DdgData: any) {

        let newItems = [];
        DdgData.map(sensor => {
            if (this.returnSensorRender(sensor.variable) == 'textContainer') {
                newItems.push(sensor.valor);
            }
        });
        
        const dialogRef = this.dialog.open(PopupCaldera, {
            height: '300px',
            maxHeight: '300px',
            width: '400px',
            maxWidth: '400px',
            data: { variablesData: newItems }
        });
        // dialogRef.afterClosed().subscribe(result => {
        // });
    }

}

import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material';
import { Inject } from '@angular/core';
@Component({
    selector: 'popup_caldera',
    templateUrl: './popup.html',
    styleUrls: ['./device.css'],
})
export class PopupCaldera implements OnInit {

    public variablesData: any;
    @Output() submitClicked = new EventEmitter<any>();

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() { this.variablesData = this.data.variablesData; }
}

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
    transform(value, args: string[]): any {
        let keys = [];
        for (let key in value) {
            keys.push({ key: key, value: value[key] });
        }
        return keys;
    }
}

