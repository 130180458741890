import { Component, OnInit, Input,  Output } from '@angular/core';

@Component({
    selector: 'textcard-item',
    templateUrl: 'textcard.html',
    styleUrls: ['./textcard.css']
 
})

export class TextCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;
    @Input() icon: any;

    constructor(){}
    ngOnInit(){}
}