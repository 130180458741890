import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/switchMap';

import { Observable } from 'rxjs/Observable';
import { Injectable } from "@angular/core";
import * as devices from "./devices.actions";
import { Actions, Effect } from "@ngrx/effects";
import { DevicesService } from "./devices.service";
import { FirebaseService } from "./firebase.service";
import { LoadDevicesSuccessAction } from "./devices.actions";
import { SetDeviceAction } from "./devices.actions";
import { SetRealtimeDeviceAction } from "./devices.actions";
import { LoadDevicesFailedAction } from "./devices.actions";
import { Action } from '@ngrx/store';
import { defer } from 'rxjs/observable/defer';

import { SocketIOService } from "./socket.io.service"

@Injectable()
export class DeviceEffects {
    constructor(
        private _actions: Actions,
        private _service: DevicesService,
        private _firebase_service: FirebaseService,
        private _socketio_service: SocketIOService
    ) { }

    @Effect()
    loadDevices$ = this._actions.ofType(devices.DeviceActionTypes.LOAD)

        .switchMap(() => this._service.query()
            .then((data) => {
                var devices = {};
                return this._service.queryDdg(data).then((d) => {
                    return new LoadDevicesSuccessAction(d)
                })
                    .catch(() => Observable.of(new LoadDevicesFailedAction())
                    );

            }))
        .catch(() => Observable.of(new LoadDevicesFailedAction())
        );

    @Effect()

    loadFirebase$ = this._actions.ofType(devices.DeviceActionTypes.LOAD)
        //.switchMap(() => this._firebase_service.decodeDataItems.snapshotChanges(['child_added'])
          //  .subscribe((payload) => new SetDeviceAction(payload)))
        .switchMap(() => this._firebase_service.getData()
        .map( (payload)=> new SetDeviceAction(payload)));
        
    @Effect()

    loadSocketIO$ = this._actions.ofType(devices.DeviceActionTypes.LOAD)
        .switchMap(() => this._socketio_service.getMessages()
            .map((message) => {  return new SetRealtimeDeviceAction(message) })

        )



}
