import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

import { SharedService } from "./global.service";
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from "@angular/common/http";

declare var AWS: any;
declare var AWSCognito: any;


@Injectable()
export class DynamoDBIOTService {

    constructor(private SharedService: SharedService, private http: HttpClient) {
    ////console.log("DynamoDBIOTService: constructor");
    }
    getAWS() {
        return AWS;
    }



    getPowerBiId(email, callback) {
        var params = {
            TableName: 'PowerBiKeys',

            /*
            FilterExpression: "contains (#us, :user)",
            ExpressionAttributeValues: {
                ':user': email

            },
            ExpressionAttributeNames: {
                "#us": "users"
            }
            */
            /*
                        {
             "id": "bd027d1d-2d4b-4d9f-a939-29d1f76b36ac",
             "users": [
              "raulmartinez@usantotomas.edu.co",
              "soporteyserviciostecnicos@ramguz.com.co",
              "iot@azlogica.com"
             ],
             "key": "0f92842d-fdb4-4369-b19e-86469e585bfd",
             "name": "Ramguz"
            }
            */
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
               // //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });


    }

    updateCredentials() {
        var paramsCognito = localStorage.getItem("paramsCognito");

        if (paramsCognito) {
            ////console.log("paramsCognitoo!!!", paramsCognito);
            // AWS.config.credentials = new AWS.CognitoIdentityCredentials(JSON.parse(paramsCognito));
            AWS.config.update({
                accessKeyId: "AKIAZBLQDRDKQN5TLZVS",
                secretAccessKey: "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",
                // "region": "us-east-1" 
            });
            /*
        AWS.config.credentials.get(function (err,data) {
            //console.log("err!", err);
            //console.log("aws config credentials!", data);
            if (!err) {
                // var id = AWS.config.credentials.identityId;
             
            }
        });
        */

            /*            AWS.config.update({
            
                            credentials: new AWS.CognitoIdentityCredentials(JSON.parse(paramsCognito))
                            
                        });
            
                        */
        }
    }

    assignForm(device, item, company, callback) {
        this.updateCredentials();
        var params = {
            TableName: 'IotFormularios',
            Item: {
                "FrDeviceMac": device.DgMac,
                "FrTstamp": new Date().getTime(),
                "FrCompany": company,
                "FrContent": item.TfFormulario,
                "FrName": item.TfName
            }
        };

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, data);
        });

    }
    updateAssignedForm(item, callback) {

        this.updateCredentials();
        var params = {
            TableName: "IotFormularios",
            Key: {
                "FrDeviceMac": item.FrDeviceMac,
                "FrTstamp": item.FrTstamp
            },
            UpdateExpression: "SET FrContent = :newContent",
            ExpressionAttributeValues: {
                ":newContent": item.FrContent
            }
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
            } else {
                ////console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });
    }

    getAllLastState(regional, city, callback) {
        this.updateCredentials();
        ////console.log("getDevices!");
        var actualCompany: any = localStorage.getItem("savedEnterprise");
        actualCompany = JSON.parse(actualCompany);

        var params: any = {
        }

        /*
        if (!city) {
            params = {
                TableName: 'devicesGPIOv2',
                FilterExpression: "DgCompany = :DgCompany",
                ExpressionAttributeValues: {
                    ":DgCompany": actualCompany.id
                },

            };
        }
        else {
            params = {
                TableName: 'devicesGPIOv2',

                FilterExpression: "DgCityId = :DgCityId AND DgCompany = :DgCompany",
                ExpressionAttributeValues: {
                    ":DgCityId": city.id,
                    ":DgCompany": actualCompany.id

                },


            };

        }
        */


        var doQuery = true;
        if (regional && city) {
            params = {
                TableName: 'decode-lastStateV2',
                FilterExpression: "	DdgCompanyDV = :DdgCompanyDV AND DdgRegionalIdDV = :DdgRegionalIdDV AND DdgCityIdDV = :DdgCityIdDV",
                ExpressionAttributeValues: {
                    ":DdgCompanyDV": actualCompany.id,
                    ":DdgRegionalIdDV": regional.id,
                    ":DdgCityIdDV": city.id

                },
            };
        }
        else if (!city && regional) {
            params = {
                TableName: 'decode-lastStateV2',
                FilterExpression: "	DdgCompanyDV = :DdgCompanyDV AND DdgRegionalIdDV = :DdgRegionalIdDV",
                ExpressionAttributeValues: {
                    ":DdgCompanyDV": actualCompany.id,
                    ":DdgRegionalIdDV": regional.id

                },
            };
        }
        else if (!regional && !city) {

            if (actualCompany.regionals) {





                if (actualCompany.regionals && actualCompany.regionals.allComplete == true) {

                    params = {
                        TableName: 'decode-lastStateV2',
                        FilterExpression: "	DdgCompanyDV = :DdgCompanyDV",
                        ExpressionAttributeValues: {
                            ":DdgCompanyDV": actualCompany.id
                        },
                    };
                }
                else {



                    var keys = []

                    var filter = " AND (";

                    params = {
                        TableName: 'decode-lastStateV2',
                        FilterExpression: "	DdgCompanyDV = :DdgCompanyDV" + filter,
                        ExpressionAttributeValues: {
                            ":DdgCompanyDV": actualCompany.id
                        },
                    };


                    actualCompany.regionals.regionals.map((item, indx) => {

                        var firstEnter = true;

                        if (item.completed) {

                            var _idname = ":DdgRegionalIdDV" + indx;

                            params.ExpressionAttributeValues[_idname] = item.id

                            if (firstEnter) {

                                filter += 'DdgRegionalIdDV =' + _idname

                                firstEnter = false;

                            }

                            else {
                                filter += ' OR DdgRegionalIdDV =' + _idname;
                            }

                            if (item.completed) {


                                keys.push({ DdgCompanyDV: actualCompany.id, DdgRegionalIdDV: item.id })
                            }
                        }


                    });

                    params.FilterExpression = " DdgCompanyDV = :DdgCompanyDV" + filter + ")";





                    /*
                    var _params: any = {
                        "RequestItems": {
                            "decode-lastStateV2": {
                                "Keys": keys
                            }
                        }

                    };

                    //console.log("paramskey", _params);
                    var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
                    docClient.batchGet(_params, function (err, data) {
                        if (err) {
                            console.error("Unable to read item. Error JSON:", JSON.stringify(err,
                                null, 2));
                        } else {
                            //console.log("GetItem succeeded:", JSON.stringify(data, null, 2));
                        }
                        var newData = {
                            Items: data.Responses['decode-lastStateV2']
                        }
                        callback(err, newData);
                    });

                    */
                }

            }

            else {
                params = {
                    TableName: 'decode-lastStateV2',
                    FilterExpression: "	DdgCompanyDV = :DdgCompanyDV",
                    ExpressionAttributeValues: {
                        ":DdgCompanyDV": actualCompany.id
                    },
                };
            }



        }


        if (doQuery) {

            var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
            docClient.scan(params, function (err, data) {

                if (err) {
                    console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

                } else {
                //    //console.log("QueryItem succeeded getDevices :", data);

                    if (data.LastEvaluatedKey) {

                        params.ExclusiveStartKey = data.LastEvaluatedKey;

                        docClient.scan(params, function (err, newData) {
                            if (err) {
                                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));
                            } else {
                                ////console.log("QueryItem succeeded getDevices new Dataaaa!!:", newData);
                                newData.Items.map((_item) => {
                                    data.Items.push(_item);
                                })
                            }
                            callback(err, data);
                        });
                    }
                    else {
                        callback(err, data);
                    }
                }
            });

        }



    }

    getRegional(id, callback, dataSaved = [], LastEvaluatedKey) {


        this.updateCredentials();
        var params: any = {
            TableName: 'regional',
            FilterExpression: "id = :id",
            ExpressionAttributeValues: {
                ":id": id
            },
        }


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            ////console.log("data!", data);
            ////console.log("error", err);


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getRegional(id, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                ////console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });
    }

    getCity(id, callback, dataSaved = [], LastEvaluatedKey) {
        
        this.updateCredentials();
        var params : any = {
            TableName: 'city',
            FilterExpression: "id = :id",
            ExpressionAttributeValues: {
                ":id": id
            },
        }


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            ////console.log("data!", data);
            ////console.log("error", err);


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getCity(id, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                ////console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });
    }


    getRegionals(callback, dataSaved = [], LastEvaluatedKey) {
        this.updateCredentials();
        var actualUser = localStorage.getItem("actualUser");

        actualUser = JSON.parse(actualUser);


        ////console.log("actualUserrrr!!! regional", actualUser);

        var actualCompany: any = localStorage.getItem("savedEnterprise");

        actualCompany = JSON.parse(actualCompany);



        if (actualCompany.regionals && actualCompany.regionals.allComplete == true) {


            var params : any= {
                TableName: 'regional',
                FilterExpression: "id_company = :id_company",
                ExpressionAttributeValues: {
                    ":id_company": actualCompany.id
                },
            }

            
            if (LastEvaluatedKey) {
                params.ExclusiveStartKey = LastEvaluatedKey
            }
            var mythis = this;

            var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });



            docClient.scan(params, function (err, data) {

                ////console.log("data!", data);
                ////console.log("error", err);
    
    
                dataSaved = [...dataSaved, ...data.Items]
    
                if (data.LastEvaluatedKey) {
    
    
                    mythis.getRegionals( callback, dataSaved, data.LastEvaluatedKey)
                }
                else {
    
                    callback(err, { Items: dataSaved });
                }
    
                if (err) {
                    console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));
    
                } else {
                    ////console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
                }
    
            });

        }
        else if (actualCompany.regionals && actualCompany.regionals.allComplete == false) {


            var keys = []

            actualCompany.regionals.regionals.map((item) => {

                if (item.completed) {


                    keys.push({ id: item.id })
                }

            });

            var _params: any = {
                "RequestItems": {
                    "regional": {
                        "Keys": keys
                    }
                }

            };
            var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
            docClient.batchGet(_params, function (err, data) {
                if (err) {
                    console.error("Unable to read item. Error JSON:", JSON.stringify(err,
                        null, 2));
                } else {
                    ////console.log("GetItem succeeded:", JSON.stringify(data, null, 2));
                }
                var newData = {
                    Items: data.Responses.regional
                }
                callback(err, newData);
            });

        }
        else {

            var params : any= {
                TableName: 'regional',
                FilterExpression: "id_company = :id_company",
                ExpressionAttributeValues: {
                    ":id_company": actualCompany.id
                },
            }
           
            if (LastEvaluatedKey) {
                params.ExclusiveStartKey = LastEvaluatedKey
            }
            var mythis = this;

            var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });


            docClient.scan(params, function (err, data) {

                ////console.log("data!", data);
                ////console.log("error", err);
    
    
                dataSaved = [...dataSaved, ...data.Items]
    
                if (data.LastEvaluatedKey) {
    
    
                    mythis.getRegionals( callback, dataSaved, data.LastEvaluatedKey)
                }
                else {
    
                    callback(err, { Items: dataSaved });
                }
    
                if (err) {
                    console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));
    
                } else {
                    //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
                }
    
            });
        }




    }

    getAllClients(callback, dataSaved= [], LastEvaluatedKey) {
    

        this.updateCredentials();
        var params: any = {
            TableName: 'company',
        };

        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            //console.log("data!", data);
            //console.log("error", err);


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getAllClients( callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

    }

    getAllDevices(callback, dataSaved= [], LastEvaluatedKey) {
        /*
        this.updateCredentials();
        var params = {
            TableName: 'devicesGPIOv2',
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getCities :", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });
        */





        this.updateCredentials();
        var params: any = {
            TableName: 'devicesGPIOv2',
        };

        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            //console.log("data!", data);
            //console.log("error", err);


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getAllDevices( callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

    }


    async  deleteCognitoUser(email) {
        AWS.config.update({
            accessKeyId: "AKIAZBLQDRDKQN5TLZVS",
            secretAccessKey: "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",
            // "region": "us-east-1" 
        });
        const cognito = new AWS.CognitoIdentityServiceProvider();

        return await cognito.adminDeleteUser({
            UserPoolId: environment.userPoolId,
            Username: email,
        }).promise();
    }
    getUser(email, callback, dataSaved = [], LastEvaluatedKey) {

        //aws_access_key_id = AKIAZBLQDRDKQN5TLZVS
        //aws_secret_access_key = 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

        /*
        AWS.config.update({
            accessKeyId: "AKIAZBLQDRDKQN5TLZVS",
            secretAccessKey: "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",
            // "region": "us-east-1" 
        });

        var params = {
            TableName: 'user',
            FilterExpression: "id = :email",
            ExpressionAttributeValues: {
                ":email": email

            }
        };

        var mythis = this;
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getUser :", JSON.stringify(data, null, 2));
                mythis.updateCredentials();
            }
            callback(err, data);
        });

  */      



        AWS.config.update({
            accessKeyId: "AKIAZBLQDRDKQN5TLZVS",
            secretAccessKey: "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",
            // "region": "us-east-1" 
        });


        this.updateCredentials();
        var params: any = {
            TableName: 'user',
            KeyConditionExpression: "id = :email",
            ExpressionAttributeValues: {
                ":email": email

            }
        };


        //console.log("params", params);

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.query(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });

        /*

        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            //console.log("data!", data);
            //console.log("error", err);


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getUser(email, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

        */
    }

    getAllControlPoints(callback, dataSaved = [], LastEvaluatedKey) {

        this.updateCredentials();
        var params: any = {
            TableName: 'devicesGPIOv2',
        };


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getAllControlPoints(callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });
    }

    getAllUsers(callback, dataSaved = [], LastEvaluatedKey) {

        this.updateCredentials();
        var params: any = {
            TableName: 'user',
        };


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getAllUsers(callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });
    }

/*
    getAllUsers(callback) {
        this.updateCredentials();
        var params = {
            TableName: 'user',
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });
    }

    */
    getAllCities(callback, dataSaved = [], LastEvaluatedKey) {

        this.updateCredentials();
        var params: any = {
            TableName: 'city',
        };


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getAllCities(callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });
    }

    getCities(regional, callback) {

        this.updateCredentials();
        var keys = []



        var actualUser = localStorage.getItem("actualUser");

        actualUser = JSON.parse(actualUser);


        //console.log("actualUserrrr!!! regional", actualUser);

        var actualCompany: any = localStorage.getItem("savedEnterprise");

        actualCompany = JSON.parse(actualCompany);



        if (actualCompany.regionals && actualCompany.regionals.regionals) {
            var actualRegional;

            actualCompany.regionals.regionals.map((regionalItem, idx) => {
                if (regionalItem.id == regional.id) {
                    actualRegional = idx;
                }
            })

            //console.log("actualRegional", actualRegional)

            //console.log("adsadadddddd", actualCompany.regionals.regionals[actualRegional])
            if (actualCompany.regionals.regionals[actualRegional].cities && actualCompany.regionals.regionals[actualRegional].cities.allComplete == true) {
                regional.id_cities.map((item) => {

                    keys.push({ id: item })

                })
            }
            else if (actualCompany.regionals.regionals[actualRegional].cities && actualCompany.regionals.regionals[actualRegional].cities.allComplete == false) {

                //console.log("entro al false!")

                actualCompany.regionals.regionals[actualRegional].cities.cities.map((cityItem) => {
                    if (cityItem.completed == true) {
                        keys.push({ id: cityItem.id })
                    }
                })

            }

            else {
                regional.id_cities.map((item) => {

                    keys.push({ id: item })

                })
            }
        }
        else {
            regional.id_cities.map((item) => {

                keys.push({ id: item })

            })
        }



        var table = "city";

        var params = {
            "RequestItems": {
                "city": {
                    "Keys": keys
                }
            }

        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.batchGet(params, function (err, data) {
            if (err) {
                console.error("Unable to read item. Error JSON:", JSON.stringify(err,
                    null, 2));
            } else {
                //console.log("GetItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });
    }



    getPoints(city, callback, dataSaved = [], LastEvaluatedKey) {
        this.updateCredentials();

 
        var params: any = {
            TableName: 'point',
            FilterExpression: "cityId= :cityId",
            ExpressionAttributeValues: {
                ":cityId": city.id,

            },
        };

        if (city.all == true) {

            params = {
                TableName: 'point'
            };

        }


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getPoints(city,callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });


        
    }
    getIdTr(id_tr, callback,  dataSaved = [], LastEvaluatedKey){



        this.updateCredentials();
        var params : any= {
            TableName: 'transportadora',
            FilterExpression: "id_tr= :id_tr",
            ExpressionAttributeValues: {
                ":id_tr": id_tr.trim(),

            },
        };


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getIdTr(id_tr, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

    }

    getDevice(mac, callback) {
        this.updateCredentials();
        var params = {
            TableName: 'devicesGPIOv2',

            KeyConditionExpression: "DgIdTr = :DgIdTr",
            ExpressionAttributeValues: {
                ":DgIdTr": mac,

            }


        };

        //console.log("params", params);

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.query(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });
    }
    getDevices(city, regional, callback, dataSaved = [], LastEvaluatedKey) {
        this.updateCredentials();

        //console.log("getDevices!");

        //console.log("city", city);

        var actualCompany: any = localStorage.getItem("savedEnterprise");

        actualCompany = JSON.parse(actualCompany);

        console.log("companyyyy!", actualCompany)

        var newStr = ""

        var firstStr = true;


        var attrNames = {

        }


        if (actualCompany.regionals && actualCompany.regionals.regionals) {

            console.log("11")

            var actualRegional;

            actualCompany.regionals.regionals.map((regionalItem, idx) => {
                if (regionalItem.id == regional.id) {
                    actualRegional = idx;
                }
            })



            var actualCity;

            actualCompany.regionals.regionals[actualRegional].cities.cities.map((cityItem, idx) => {
                if (cityItem.id == city.id) {
                    actualCity = idx;
                }
            })

            console.log("actualRegiona", actualRegional)
            console.log("actualCity", actualCity)

            if (actualCompany.regionals.regionals[actualRegional].cities && actualCompany.regionals.regionals[actualRegional].cities.cities[actualCity] &&

                actualCompany.regionals.regionals[actualRegional].cities.cities[actualCity].devices.allComplete == true
            ) {

                // newStr+= "DgMac="+ 

            }

            else if (

                actualCompany.regionals.regionals[actualRegional].cities && actualCompany.regionals.regionals[actualRegional].cities.cities[actualCity] &&

                actualCompany.regionals.regionals[actualRegional].cities.cities[actualCity].devices.allComplete == false

            ) {
                actualCompany.regionals.regionals[actualRegional].cities.cities[actualCity].devices.devices.map((deviceItem, idx) => {
                    //"DgCityId = :DgCityId AND DgCompany = :DgCompany  AND DgMac=00-50-c2-59-c8-83 OR DgMac=00-50-c2-59-ca-56"


                    if (deviceItem.completed == true) {
                        var attrName = ":mac" + idx;
                        if (firstStr == true) {

                            //   newStr+=" AND DgMac=" + deviceItem.DgMac
                            newStr += " AND DgMac= " + attrName
                            firstStr = false;
                        }
                        else {
                            newStr += " OR DgMac=" + attrName
                        }

                        attrNames[attrName] = deviceItem.DgMac;
                    }

                })
            }

            else {

            }

        }

        var params: any = {
        }

        console.log("newStr", newStr)


        if (city.all) {

            console.log("22")

            attrNames[":DgCompany"] = actualCompany.id
            params = {
                TableName: 'devicesGPIOv2',
                FilterExpression: "DgCompany = :DgCompany",
                ExpressionAttributeValues: attrNames

            };
        }
        else {
            console.log("33")
            attrNames[":DgCompany"] = actualCompany.id
            attrNames[":DgCityId"] = city.id

            params = {
                TableName: 'devicesGPIOv2',

                FilterExpression: "DgCityId = :DgCityId AND DgCompany = :DgCompany " + newStr,
                ExpressionAttributeValues: attrNames


            };

        }

        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }

        console.log("params get devices glory", params);

        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {




            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {

                dataSaved = [...dataSaved, ...data.Items]

                if (data.LastEvaluatedKey) {


                    mythis.getDevices(city, regional, callback, dataSaved, data.LastEvaluatedKey)
                }
                else {

                    callback(err, { Items: dataSaved });
                }

                /*
                    //console.log("QueryItem succeeded getDevices :", data);
    
                    if (data.LastEvaluatedKey) {
    
                        params.ExclusiveStartKey = data.LastEvaluatedKey;
    
    
    
    
                        docClient.scan(params, function (err, newData) {
    
                            if (err) {
                                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));
    
                            } else {
    
    
                                //console.log("QueryItem succeeded getDevices new Dataaaa!!:", newData);
    
                                newData.Items.map((_item) => {
                                    data.Items.push(_item);
                                })
    
                            }
                            callback(err, data);
                        });
    
                    }
                    */

            }
            //  callback(err, data);
        });

    }


    getSingleForm(FrName, callback) {
        this.updateCredentials();
        //console.log("FrName");
        //console.log(FrName);
        var params = {
            TableName: 'IotFormularios',
            FilterExpression: "FrName= :FrName",
            ExpressionAttributeValues: {
                ":FrName": FrName.trim(),

            },
        };


        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });

    }

    getEnterpriseForms(company, callback) {
        this.updateCredentials();
        var params = {
            TableName: 'IotTipoFormulario',
            FilterExpression: "TfCompany= :company",
            ExpressionAttributeValues: {
                ":company": company,
                //  ":s": tstamp
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
        };


        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });
    }


    getDeviceFormsByTimestamp(mac, tstamp, callback) {

        this.updateCredentials();
        //console.log("Timestamppppp");
        //console.log(tstamp);

        var params = {
            TableName: "IotFormularios",
            // IndexName: "DdgMacDV-DdgCompanyDV-index",
            KeyConditionExpression: "FrDeviceMac= :mac",
            //    KeyConditionExpression : "FrDeviceMac= :mac AND  FrTstamp= :s",
            //    FilterExpression: " FrTstamp =  :tstamp",
            //  ExpressionAttributeNames: {
            //     "#FrDeviceMac": "FrDeviceMac"
            //  },
            ExpressionAttributeValues: {
                ":mac": mac,
                //  ":s": tstamp
                //  ":company": 'alexander.ortiz@azlogica.com',
            },

        };

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.query(params, function (err, data) {

            var newData = null;
            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
                data.Items.map((item) => {
                    if (item.FrTstamp == tstamp) {
                        newData = item;

                        var params = {
                            TableName: "IotTipoFormulario",
                            KeyConditionExpression: "TfName= :name",
                            ExpressionAttributeValues: {
                                ":name": item.FrName,
                            },
                        };
                        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
                        docClient.query(params, function (err, data) {
                            //console.log("errroooor");
                            //console.log(err);
                            //console.log(data);
                            if (!err) {
                                if (data.Items && data.Items[0]) {
                                    newData.TipoFormulario = data.Items[0];
                                }
                            }
                            callback(err, newData);
                        });

                    }
                })

            }

        });

    }

    getActualUser(user, callback,  dataSaved = [], LastEvaluatedKey) {


        this.updateCredentials();
        var params : any= {
            TableName: 'user',
            // KeyConditionExpression: "(#IpUser = :mac)",
            KeyConditionExpression: " id =  :mac",

            ExpressionAttributeValues: {
                ":mac": user,
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            // ScanIndexForward: false,

        };

        //console.log("params", params);

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.query(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });

        /*


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getActualUser(user, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

        */

    }
    getDeviceForms(mac, callback) {
        this.updateCredentials();
        var params = {
            TableName: "IotFormularios",
            // IndexName: "DdgMacDV-DdgCompanyDV-index",
            KeyConditionExpression: "(#FrDeviceMac = :mac)",
            //      FilterExpression: " DdgCompanyDV =  :company",
            ExpressionAttributeNames: {
                "#FrDeviceMac": "FrDeviceMac"
            },
            ExpressionAttributeValues: {
                ":mac": mac
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            ScanIndexForward: false
        };


        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.query(params, function (err, data) {
            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });

    }



    getDeviceHistoricTime(mac, callback) {
        this.updateCredentials();
        //console.log(mac);
        var params = {
            TableName: "decode-dataGPIOv2",
            // IndexName: "DdgMacDV-DdgCompanyDV-index",
            KeyConditionExpression: "(#DdgIdTrDV  = :mac)",
            //      FilterExpression: " DdgCompanyDV =  :company",
          //  FilterExpression: "DdgMotivo  <> :DdgMotivo AND DdgMotivo  <> :DdgMotivo2 ",
            ExpressionAttributeNames: {
                "#DdgIdTrDV": "DdgIdTrDV"
            },
            ExpressionAttributeValues: {
                ":mac": mac,
              //  ":DdgMotivo": 444,
               // ":DdgMotivo2": "444"
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            ScanIndexForward: false,
            Limit: 1

        };

        //console.log("paramsssss! ", params)
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.query(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));

            }
            callback(err, data);
        });
    }

    getTransportadora(id, callback, dataSaved = [], LastEvaluatedKey) {

  

        this.updateCredentials();
        var params : any= {
            TableName: 'transportadora',
            // KeyConditionExpression: "(#IpUser = :mac)",
            FilterExpression: " id =  :mac",

            ExpressionAttributeValues: {
                ":mac": id,
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            // ScanIndexForward: false,

        };


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getTransportadora(id, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

    }
    getDeviceHistoric(mac, callback) {
        this.updateCredentials();
        //console.log(mac);
        /*
         var params = {
            //  TableName: "pruebas",
            TableName: "decode-dataGPIOv2",
            // IndexName: "DdgMacDV-DdgCompanyDV-index",
            KeyConditionExpression: "(#DdgMacDV = :mac)",
            //      FilterExpression: " DdgCompanyDV =  :company",
            FilterExpression: 'DdgMotivo  <> :DdgMotivo',
            ExpressionAttributeNames: {
                "#DdgMacDV": "DdgMacDV"
            },
            ExpressionAttributeValues: {
                ":mac": mac,
                ":DdgMotivo": 444
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            ScanIndexForward: false,
            //  Limit: 100

        };
        */
        var params = {
            //  TableName: "pruebas",
            TableName: "decode-dataGPIOv2",
            // IndexName: "DdgMacDV-DdgCompanyDV-index",
            KeyConditionExpression: "(#DdgIdTrDV = :mac)",
            //      FilterExpression: " DdgCompanyDV =  :company",
            FilterExpression: "DdgMotivo  <> :DdgMotivo AND DdgMotivo  <> :DdgMotivo2 ",
            ExpressionAttributeNames: {
                "#DdgIdTrDV": "DdgIdTrDV"
            },
            ExpressionAttributeValues: {
                ":mac": mac,
                ":DdgMotivo": 444,
                ":DdgMotivo2": "444"
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            ScanIndexForward: false,
            //  Limit: 100

        };

        console.log("params decodeDataGPIO actual", params);

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.query(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));

            }
            callback(err, data);
        });

    }

    getRangedHistoricFromFilterBatch(regional, city, initDate, endDate, callback, items) {


        this.updateCredentials();
        var myQueries = [];


        items.map((item) => {


            var params = {
                TableName: "decode-dataGPIOv2",
                IndexName: "DdgMacDV-DdgTstamp-index",
                KeyConditionExpression: "(#DdgMacDV = :mac) AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
                FilterExpression: "DdgMotivo  <> :DdgMotivo AND DdgMotivo  <> :DdgMotivo2 ",
                ExpressionAttributeNames: {
                    "#DdgMacDV": "DdgMacDV",
                    "#DdgTstamp": "DdgTstamp"
                },
                ExpressionAttributeValues: {
                    ":mac": item.DdgMacDV,
                    ":initDate": initDate,
                    ":endDate": endDate,
                    ":DdgMotivo": 444,
                    ":DdgMotivo2": "444",
                    //  ":company": 'alexander.ortiz@azlogica.com',
                },
                ScanIndexForward: false,
                // Limit: 30

            };

            var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
            //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
            // return docClient.then
            myQueries.push(docClient.query(params).promise())

        })

        Promise.all(myQueries).then((results) => {
            //console.log("resultsssss!!!!!", results);
            var newData = [];
            results.map((data) => {
                newData = [...newData, ...data.Items];
            });

            newData.sort(function (x, y) {
                return x.DdgTstamp - y.DdgTstamp;
            })


            newData.reverse();



            callback(null, { Items: newData }, true)
        })

    }


    getEstadoCaja(){

        var data = {
            "query": "query { balance(id: \"57000001-5-6-48\",fecha:\"2022-01-19 14:10:00\") }" 
        }
        this.http.post<any>('http://3.232.123.143:8080', data).subscribe(data => {
            //console.log("datafromEstadoCaja!", data);

       

        })

    }


    getRangedHistoricFromFilter(regional, city, initDate, endDate, callback, LastEvaluatedKey = null, dataSaved = []) {

        this.updateCredentials();
        var actualCompany: any = localStorage.getItem("savedEnterprise");
        actualCompany = JSON.parse(actualCompany);



        var query = `
        query getHistoric($DdgCityIdDV: String, $DdgRegionalIdDV: String, $InitDate: String, $EndDate: String, $DdgMotivo: Int, $DdgCompanyDV: String) {
            getHistoric(DdgCityIdDV :$DdgCityIdDV, DdgRegionalIdDV :$DdgRegionalIdDV, InitDate :$InitDate , EndDate :$EndDate, DdgMotivo :$DdgMotivo , DdgCompanyDV: $DdgCompanyDV) {
                DdgData{
                    valor
                    variable
                  }
                  DdgLatDV
                  DdgLongDV
                  DdgMacDV
                  DdgTstamp
                  DdgLongDV
                  DdgMotivo
                  DdgNameDV
                  DdgIdTrDV
                  DdgAliasDV
                  DdgCityIdDV
                  DdgCompanyDV
                  DdgAddressDV
                  DdgRegionalIdDV
                  DdgDatetimeServer
            }
        }
    `;
        var data: any = {
            query: query,
            variables: {
                InitDate: initDate,
                EndDate: endDate,
                DdgMotivo: 444
            }
        };

        //console.log("variables1", data);


        if (actualCompany) {
            data.variables.DdgCompanyDV = actualCompany.id;
        }

        if (regional) {
            data.variables.DdgRegionalIdDV = regional.id;
        }

        if (city) {
            data.variables.DdgCityIdDV = city.id;
        }

//  this.http.post<any>('https://api3.thingsmanager.co:13303/graphql', data).subscribe(data => {
        this.http.post<any>('https://api3.thingsmanager.co/graphql', data).subscribe(data => {
            //console.log("data!", data);

            callback(null, { Items: data.data.getHistoric }, true);

        })

        /*
        superagent.post('https://api3.azlogica.com/graphql')
            .send(data)
            // .set('Accept', 'application/json')
            .then((err, res) => {
                // //console.log("dataaaa:", res);
                if (err) {
                    //  return //console.log("err·: ", err.res.text);
                    if (err.res && err.res.text) {
                        // //console.log("err·: ", err.res.text);

                        var newData = JSON.parse(err.res.text);

                        if (newData) {
                            newData = newData.data.reportes;
                        }
                        resolve(newData);
                    }

                }


            })
            .catch((err) => {

            })
*/


        /*
        //console.log(city);

        //console.log("getDevices!");
        var actualCompany: any = localStorage.getItem("savedEnterprise");
        actualCompany = JSON.parse(actualCompany);

        var params: any = {
        }

        if (regional && city) {
            params = {
                TableName: 'decode-dataGPIOv2',
                IndexName: "DdgCompanyDV-DdgTstamp-index",
                KeyConditionExpression: "#DdgCompanyDV = :DdgCompanyDV AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
                FilterExpression: " DdgRegionalIdDV = :DdgRegionalIdDV AND DdgCityIdDV = :DdgCityIdDV  AND DdgMotivo  <> :DdgMotivo",
                ExpressionAttributeValues: {
                    ":DdgCompanyDV": actualCompany.id,
                    ":DdgRegionalIdDV": regional.id,
                    ":DdgCityIdDV": city.id,



                },
            };
        }
        else if (!city && regional) {
            params = {
                TableName: 'decode-dataGPIOv2',
                IndexName: "DdgCompanyDV-DdgTstamp-index",
                KeyConditionExpression: "#DdgCompanyDV = :DdgCompanyDV AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
                FilterExpression: "DdgRegionalIdDV = :DdgRegionalIdDV  AND DdgMotivo  <> :DdgMotivo",
                ExpressionAttributeValues: {
                    ":DdgCompanyDV": actualCompany.id,
                    ":DdgRegionalIdDV": regional.id

                },
            };
        }
        else if (!regional && !city) {

            if (actualCompany.regionals) {

                if (actualCompany.regionals && actualCompany.regionals.allComplete == true) {

                    params = {
                        TableName: 'decode-dataGPIOv2',
                        IndexName: "DdgCompanyDV-DdgTstamp-index",
                        KeyConditionExpression: "#DdgCompanyDV = :DdgCompanyDV AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
                        FilterExpression: "	DdgMotivo  <> :DdgMotivo",
                        ExpressionAttributeValues: {
                            ":DdgCompanyDV": actualCompany.id
                        },
                    };
                }
                else {

                    var keys = []
                    var filter = " AND (";

                    params = {
                        TableName: 'decode-dataGPIOv2',
                        IndexName: "DdgCompanyDV-DdgTstamp-index",
                        KeyConditionExpression: "#DdgCompanyDV = :DdgCompanyDV AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
                        FilterExpression: "	DdgCompanyDV = :DdgCompanyDV AND (DdgTstamp BETWEEN  :initDate AND :endDate ) " + filter,
                        ExpressionAttributeValues: {
                            ":DdgCompanyDV": actualCompany.id
                        },
                    };

                    actualCompany.regionals.regionals.map((item, indx) => {

                        var firstEnter = true;
                        if (item.completed) {

                            var _idname = ":DdgRegionalIdDV" + indx;

                            params.ExpressionAttributeValues[_idname] = item.id

                            if (firstEnter) {
                                filter += 'DdgRegionalIdDV =' + _idname
                                firstEnter = false;
                            }
                            else {
                                filter += ' OR DdgRegionalIdDV =' + _idname;
                            }

                            if (item.completed) {
                                keys.push({ DdgCompanyDV: actualCompany.id, DdgRegionalIdDV: item.id })
                            }
                        }
                    });

                    params.FilterExpression = "DdgMotivo  <> :DdgMotivo " + filter + " )",
                        //console.log("haha prams", params);

                }

            }

            else {
                params = {
                    TableName: 'decode-dataGPIOv2',
                    IndexName: "DdgCompanyDV-DdgTstamp-index",

                    KeyConditionExpression: "#DdgCompanyDV = :DdgCompanyDV AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
                    FilterExpression: " DdgMotivo  <> :DdgMotivo",

                    ExpressionAttributeValues: {
                        ":DdgCompanyDV": actualCompany.id
                    },
                };
            }
        }

        params.ScanIndexForward = false;

        params.ExpressionAttributeValues[":initDate"] = initDate;
        params.ExpressionAttributeValues[":endDate"] = endDate;
        params.ExpressionAttributeValues[":DdgMotivo"] = 444;
        params.ExpressionAttributeNames = {
            "#DdgCompanyDV": "DdgCompanyDV",
            "#DdgTstamp": "DdgTstamp"
        }

        //console.log("myparams", params);

        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }


        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.query(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {

                //console.log("data", data);
                if (data.LastEvaluatedKey) {


                    dataSaved = [...dataSaved, ...data.Items]


                    //console.log("dataSaveeeeed!", dataSaved);
                    mythis.getRangedHistoricFromFilter(regional, city, initDate, endDate, callback, data.LastEvaluatedKey, dataSaved)

                }

                else {

                    dataSaved = [...dataSaved, ...data.Items]
                    dataSaved.sort(function (x, y) {
                        return x.DdgTstamp - y.DdgTstamp;
                    })
                    dataSaved.reverse();

                    callback(err, { Items: dataSaved }, true);
                }
            }
        });

        */



    }

    getDeviceRangedHistoric(mac, initDate, endDate, callback) {
        this.updateCredentials();
        //console.log(mac);

        /*
          var params = {
            TableName: "decode-dataGPIOv2",
            IndexName: "DdgMacDV-DdgTstamp-index",
            KeyConditionExpression: "(#DdgMacDV = :mac) AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
            FilterExpression: "DdgMotivo  <> :DdgMotivo",
            ExpressionAttributeNames: {
                "#DdgMacDV": "DdgMacDV",
                "#DdgTstamp": "DdgTstamp"
            },
            ExpressionAttributeValues: {
                ":mac": mac,
                ":initDate": initDate,
                ":endDate": endDate,
                ":DdgMotivo": 444
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            ScanIndexForward: false,
            // Limit: 30

        };

        */
        var params = {
            TableName: "decode-dataGPIOv2",
        //   IndexName: "DdgMacDV-DdgTstamp-index",
            KeyConditionExpression: "(#DdgIdTrDV  = :mac) AND (#DdgTstamp BETWEEN  :initDate AND :endDate )",
            FilterExpression: "DdgMotivo  <> :DdgMotivo AND DdgMotivo  <> :DdgMotivo2 ",
            ExpressionAttributeNames: {
                "#DdgIdTrDV": "DdgIdTrDV",
                "#DdgTstamp": "DdgTstamp"
            },
            ExpressionAttributeValues: {
                ":mac": mac,
                ":initDate": initDate,
                ":endDate": endDate,
                ":DdgMotivo": 444,
                ":DdgMotivo2": "444"
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            ScanIndexForward: false,
            // Limit: 30

        };

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.query(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));

            }
            callback(err, data);
        });

    }

    updateUserDisabled(val, user, callback) {
        this.updateCredentials();
        var params = {
            TableName: "user",
            // IndexName: "DgCompany-index",
            Key: {
                "id": user.id
            },
            UpdateExpression: "SET disabled = :val",
            ExpressionAttributeValues: {
                ":val": val
            }
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                //	$scope(err, null);
            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
                // callback(err, data);
                //	callback(null, data);
            }
            callback(err, data);
        });

    }
    updateAttributeAlias(mac, attrname, attrnewalias, callback) {
        this.updateCredentials();
        var params = {
            TableName: "devicesGPIOv2",
            // IndexName: "DgCompany-index",
            Key: {
                "DgMac": mac
            },
            UpdateExpression: "SET DgAttributes." + attrname + ".alias = :newalias",
            ExpressionAttributeValues: {
                ":newalias": attrnewalias
            }
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                //	$scope(err, null);
            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
                // callback(err, data);
                //	callback(null, data);
            }
            callback(err, data);
        });

    }
    updateAttributeRealtimeChart(mac, attrname, attrnewalias, callback) {
        this.updateCredentials();
        var params = {
            TableName: "devicesGPIOv2",
            // IndexName: "DgCompany-index",
            Key: {
                "DgMac": mac
            },
            UpdateExpression: "SET DgAttributes." + attrname + ".renderRealtime = :newalias",
            ExpressionAttributeValues: {
                ":newalias": attrnewalias
            }
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                //	$scope(err, null);
            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
                // callback(err, data);
                //	callback(null, data);
            }
            callback(err, data);
        });

    }

    getAllRegionals(callback, dataSaved = [], LastEvaluatedKey) {


        this.updateCredentials();
        var params: any = {
            TableName: 'regional',
        };


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getAllRegionals( callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

    }


    getEnterprises(mapArray, callback,  dataSaved = [], LastEvaluatedKey) { 


        this.updateCredentials();
        var params: any = {
            TableName: 'company',
        };



        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getEnterprises(mapArray, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {


            dataSaved.map((data) => {
                mapArray.push(data);
            });
                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

    }
    /*
    getEnterprises(mapArray, callback) {
        //console.log(mapArray);
        var params = {
            TableName: 'IotCompany',
        };

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.scan(params, (err, data) => {

            //console.log("err", err);
            //console.log("data", data);

            data.Items.map((data) => {
                mapArray.push(data);
            });

            callback("OK");
        });


    }

    */

    registerEnterprise(name, callback) {

        this.updateCredentials();
        //console.log("registerenterprise");

        this.updateCredentials();
        var params = {
            TableName: 'company',
            Item: {
                "id": uuidv4(),
                "name": name,
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {

            //console.log("err", err);
            //console.log("data", data);
            callback(err, data);
        });
    }



    editUserEnterprises(enterprises, callback) {

        this.updateCredentials();

        var actualUser: any = localStorage.getItem("actualUser");

        actualUser = JSON.parse(actualUser);

        var params = {
            TableName: "user",
            // IndexName: "DgCompany-index",
            Key: {
                "id": actualUser.id
            },
            UpdateExpression: "SET  companies = :companies",
            ExpressionAttributeValues: {

                ":companies": enterprises
            }
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.update(params, function (err, data) {
            if (err) {

                //	$scope(err, null);
            } else {

                // callback(err, data);
                //	callback(null, data);
            }

            //console.log("err!", err);
            //console.log("response!", data);
            callback(err, data);
        });
    }
    editUser(email, enterprises, permissions, callback) {

        this.updateCredentials();
        var params = {
            TableName: "user",
            // IndexName: "DgCompany-index",
            Key: {
                "id": email
            },
            UpdateExpression: "SET userPermissions = :userPermissions, companies = :companies",
            ExpressionAttributeValues: {
                ":userPermissions": permissions,
                ":companies": enterprises
            }
        };

        //console.log("paramsEditUser", params);
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.update(params, function (err, data) {
            if (err) {

                //	$scope(err, null);
            } else {

                // callback(err, data);
                //	callback(null, data);
            }

            //console.log("err!", err);
            //console.log("response!", data);
            callback(err, data);
        });
    }

    registerUser(email, enterprises, permissions, callback) {


        this.updateCredentials();
        //console.log("register user...");
        var params = {
            TableName: 'user',
            Item: {
                "id": email,
                "permissions": {
                    "realtime": {
                        "view": true,
                        "update": true
                    },
                    "equipos_en_linea": {
                        "view": true,
                        "update": true
                    },
                    "historico": {
                        "view": true,
                        "update": true
                    },
                    "alarmas": {
                        "view": true,
                        "update": true
                    },
                    "reportes": {
                        "view": true,
                        "update": true
                    }
                },
                "companies": enterprises,
                "userPermissions": permissions

            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {

            //console.log("err", err);
            //console.log("dataa!", data);
            callback(err, data);
        });


    }

    /*
    registerUser(email, callback) {

        var params = {
            TableName: 'IotProfile',
            Item: {
                "IpUser": email,
                "IpPermissions": {
                    "realtime": {
                        "view": true,
                        "update": true
                    },
                    "equipos_en_linea": {
                        "view": true,
                        "update": true
                    },
                    "historico": {
                        "view": true,
                        "update": true
                    },
                    "alarmas": {
                        "view": true,
                        "update": true
                    },
                    "reportes": {
                        "view": true,
                        "update": true
                    }
                }
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, data);
        });


    }

    */

    getUserEnterprise(email, callback,  dataSaved = [], LastEvaluatedKey) {


    

        this.updateCredentials();
        var params : any= {
            TableName: 'company',
            // KeyConditionExpression: "(#IpUser = :mac)",
            FilterExpression: " id =  :mac",

            ExpressionAttributeValues: {
                ":mac": email,
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            // ScanIndexForward: false,
        };


        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getUserEnterprise(email, callback, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

    }



    getUserPermissions(email, callback, user = null,  dataSaved = [], LastEvaluatedKey) {

        

        this.updateCredentials();
      
        var params: any = {
            TableName: 'user',
            // KeyConditionExpression: "(#IpUser = :mac)",
            KeyConditionExpression: " id =  :mac",

            ExpressionAttributeValues: {
                ":mac": email,
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            // ScanIndexForward: false,

        };


        //console.log("params", params);

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.query(params, function (err, data) {

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded:", JSON.stringify(data, null, 2));
            }
            callback(err, data);
        });

        /*

        if (LastEvaluatedKey) {
            params.ExclusiveStartKey = LastEvaluatedKey
        }
        var mythis = this;

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        docClient.scan(params, function (err, data) {


            dataSaved = [...dataSaved, ...data.Items]

            if (data.LastEvaluatedKey) {


                mythis.getUserPermissions(email, callback, user, dataSaved, data.LastEvaluatedKey)
            }
            else {

                callback(err, { Items: dataSaved });
            }

            if (err) {
                console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

            } else {
                //console.log("QueryItem succeeded getAllusers :", JSON.stringify(data, null, 2));
            }

        });

        */




    }

    /*

    getUserPermissions(email, callback, user = null) {


        var params = {
            TableName: 'IotProfile',
            // KeyConditionExpression: "(#IpUser = :mac)",
            FilterExpression: " IpUser =  :mac",

            ExpressionAttributeValues: {
                ":mac": email,
                //  ":company": 'alexander.ortiz@azlogica.com',
            },
            // ScanIndexForward: false,

        };

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.scan(params, (err, data) => {

            //console.log("err", err);
            //console.log("data", data);

            callback(err, data, user);

        });



    }

    */


    editTransportadora(item, callback) {
        this.updateCredentials();
        var params = {
            TableName: "transportadora",
            // IndexName: "DgCompany-index",
            Key: {
                "id": item.id
            },
            UpdateExpression: "SET id_tr = :id_tr, #name = :name",
            ExpressionAttributeNames: {
                "#name": "name"
            },
            ExpressionAttributeValues: {
                ":id_tr": item.id_tr,
                ":name": item.name
            }
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.update(params, function (err, data) {
            if (err) {

                //	$scope(err, null);
            } else {

                // callback(err, data);
                //	callback(null, data);
            }
            callback(err, data);
        });
    }

    addClient(name, callback) {
        this.updateCredentials();
        var _id = uuidv4();
        var params = {
            TableName: 'company',
            Item: {
                "id": _id,
                "name": name,
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, { ...data, newId: _id });
        });


    }

    addCity(name, callback) {
        var _id = uuidv4();
        this.updateCredentials();
        var params = {
            TableName: 'city',
            Item: {
                "id": _id,
                "name": name,
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, { ...data, newId: _id });
        });
    }

    addRegional(enterprise_id, name, arrList, callback) {
        var _id = uuidv4();
        this.updateCredentials();

        var params = {
            TableName: 'regional',
            Item: {
                "id": _id,
                "name": name,
                "id_company": enterprise_id,
                "id_cities": arrList
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, { ...data, newId: _id });
        });

    }
    addTransportadora(item, callback) {

        this.updateCredentials();
        var params = {
            TableName: 'transportadora',
            Item: item
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, data);
        });

    }

    deletePoint(item, callback) {

        this.updateCredentials();
        var params = {
            TableName: 'devicesGPIOv2',
            Key: {
                "DgIdTr": item.DgIdTr
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.delete(params, (err, data) => {

            console.log("deletePointErr", err);
            console.log("deletePointData,", data);
            

            this.updateCredentials();
            var params = {
                TableName: 'transportadora',
                Key: {
                    "id": item.DgIdTr
                }
            }
    
            var docClient = new AWS.DynamoDB.DocumentClient();
            docClient.delete(params, (err, data) => {
                callback(err, data);
            });

        });
    }



    deleteUser(item, callback) {

        this.updateCredentials();
        var params = {
            TableName: 'user',
            Key: {
                "id": item.id
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.delete(params, (err, data) => {
            callback(err, data);
        });
    }


    updateDevice(item, callback) {

        var _date = new Date().toString()
        this.updateCredentials();
        var params = {
            TableName: "devicesGPIOv2",
            Key: {
                "DgIdTr": item.DgIdTr
            },
            
            UpdateExpression: "SET    DgLastUpdate = :DgLastUpdate , #DgAttributes.#cantacumbilletes.#advertencia.#val = :cvalue,   #DgAttributes.#cantacumbilletes.#advertencia.#lim = :climit , DgAddress = :DgAddress,  DgAlias = :DgAlias,  DgAnclaPointId = :DgAnclaPointId,  DgCityId = :DgCityId,  DgCompany = :DgCompany,  DgName = :DgName,  DgRegionalId = :DgRegionalId, DgMac = :DgMac, DgDeviceType = :DgDeviceType",
            ExpressionAttributeValues: {
                ":DgLastUpdate": _date,
                ":DgAddress": item.DgAddress,
                ":DgAlias": item.DgAlias,
                ":DgAnclaPointId": item.DgAnclaPointId,
                ":DgCityId": item.DgCityId,
                ":DgCompany": item.DgCompany,
                ":DgName": item.DgName,
                ":DgRegionalId": item.DgRegionalId,
                ":cvalue": item.cvalue,
                ":climit": item.climit,
                ":DgMac": item.DgMac,
                ":DgDeviceType": item.DgDeviceType,
              

            },
            ExpressionAttributeNames: {
                "#DgAttributes": "DgAttributes",
                "#cantacumbilletes": "cantacumbilletes",
                "#advertencia": "advertencia",
                "#val": "value",
                "#lim": "limit",
                //  "#val": "DgAttributes.cantacumbilletes.advertencia.value",
                // "#lim": "DgAttributes.cantacumbilletes.advertencia.limit"
            }
        };

        //console.log("params", params)
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        docClient.update(params, function (err, data) {
            if (err) {

                //	$scope(err, null);
            } else {

                // callback(err, data);
                //	callback(null, data);
            }
            callback(err, data);
        });
    }


    addDevice(item, callback) {

        this.updateCredentials();
        var params = {
            TableName: 'devicesGPIOv2',
            Item: item
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, data);
        });

    }



    setEnterprise(enterprise, company, callback) {

        this.updateCredentials();
        var params = {
            TableName: 'IotCompany',
            Item: {
                "IcCompany": enterprise,
                "IcEmail": company,
                "IcUsers": []
            }
        }

        var docClient = new AWS.DynamoDB.DocumentClient();
        docClient.put(params, (err, data) => {
            callback(err, data);
        });


    }
    insertAlarmMessage(mac, timestamp, message, callback) {


        //NO SE USA
        this.updateCredentials();
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })
        var params = {
            TableName: "decode-dataGPIOv2",
            IndexName: "DdgMacDV-DdgTstamp-index",
            // IndexName: "DgCompany-index",
            Key: {
                "DdgMacDV": mac,
                "DdgTstamp": timestamp
            },
            UpdateExpression: "SET DdgAlarComentarios = list_append(if_not_exists(DdgAlarComentarios, :empty_list), :my_value)",
            ExpressionAttributeValues: {
                ":empty_list": [],
                ":my_value": [message]
            }
        };

        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                //	$scope(err, null);
            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
                callback(err, data);
                //	callback(null, data);
            }
        });

    }
    updateAlarEstado(mac, timestamp, value, callback) {

        //NO SE USA
        this.updateCredentials();
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })
        var params = {
            TableName: "decode-dataGPIOv2",
            IndexName: "DdgMacDV-DdgTstamp-index",
            // IndexName: "DgCompany-index",
            Key: {
                "DdgMacDV": mac,
                "DdgTstamp": timestamp
            },
            UpdateExpression: "SET DdgAlarEstado =  :my_value",
            ExpressionAttributeValues: {
                ":my_value": value
            }
        };

        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                //	$scope(err, null);
            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
                callback(err, data);
                //	callback(null, data);
            }
        });


    }

    setUserEnterprise(enterprise, email, callback) {

        this.updateCredentials();

        //console.log("enterprise");
        //console.log(enterprise);

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })
        var params = {
            TableName: 'company',
            Key: {
                "name": enterprise
            },
            UpdateExpression: 'SET users = list_append(if_not_exists(users, :empty_list), :my_value)',
            ExpressionAttributeValues: {
                ":my_value": [email],
                ":empty_list": []
            },
            ReturnValues: "UPDATED_NEW"

        };

        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                //	$scope(err, null);
            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
                callback(err, data);
                //	callback(null, data);
            }
        });

    }

    /*
        setUserEnterprise(enterprise, email, callback) {

        //console.log("enterprise");
        //console.log(enterprise);

        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false })
        var params = {
            TableName: 'IotCompany',
            Key: {
                "IcCompany": enterprise
            },
            UpdateExpression: 'SET IcUsers = list_append(if_not_exists(IcUsers, :empty_list), :my_value)',
            ExpressionAttributeValues: {
                ":my_value": [email],
                ":empty_list": []
            },
            ReturnValues: "UPDATED_NEW"

        };

        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
                //	$scope(err, null);
            } else {
                //console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
                callback(err, data);
                //	callback(null, data);
            }
        });

    }
    */


}

/*
   var params = {
       TableName: 'city',
       FilterExpression: "id= :cityId",
       ExpressionAttributeValues: {
           ":cityId": regional.id_city,
       },
   };


   var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
   docClient.scan(params, function (err, data) {

       if (err) {
           console.error("Unable to obtain item. Error JSON:", JSON.stringify(err, null, 2));

       } else {
           //console.log("QueryItem succeeded getCities :", JSON.stringify(data, null, 2));
       }
       callback(err, data);
   });

   */