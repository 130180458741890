import { Component, OnInit, Input,  Output } from '@angular/core';
import * as CanvasJS from './../../../lib/canvasjs.min';


var today = new Date();
var yy = today.getFullYear(); var mm = today.getMonth()+1;

var monthlyDate = yy + "-" + mm + "-01" + " " + "00:00:00";
var tsMonthyDate = new Date(monthlyDate).getTime();

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


@Component({
    selector: 'report_by_name-container',
    templateUrl: 'report_by_name.html',
    styleUrls: ['./report_by_name.css']
})



export class ReportByNameContainer implements OnInit {

    @Input() device: any;
    @Input() historic: any;

  

    
    pdfSrc= "https://s3.amazonaws.com/azcognito-iot/claudia+Patricia+Henao+oviedo_2018_05_18+LECTOR.pdf";
    pdfSrc2= "https://s3.amazonaws.com/azcognito-iot/claudia+Patricia+Henao+oviedo_2018_05_18+diaria.pdf";

    days = [  'dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sab' ];
    months = ["ene", "feb", "mar", "abr", "may", "jun",
    "jul", "ago", "sep", "oct", "nov", "dic"
    ];

    actualMonth = this.months[mm-1];

    _monthlyData = [];
    _monthlyDataPoints = [];

    _dailyData = {};
    _dailyDataPoints= {};

    historicFiltered = [];
    actualAttribute = { "unidad": "" };

    constructor(){


    }

    monthlyData(){

        this._monthlyData = [];
        

        /*var averageDays = [];
        this.historic.map( (item)=>{
            
            var day = new Date(item.DdgTstamp).getDate();
            if(!averageDays[day]){
                averageDays[day] = {
                    total : 0,
                    count : 0
                };
            }
            else{
                averageDays[day].total+= parseFloat(item.DdgData[0].valor);
                averageDays[day].count+= 1;
            }
        });
        console.log("averageDays");
        console.log(averageDays);

        for(let i in averageDays){
            var average = averageDays[i].total /  averageDays[i].count;

            this._monthlyDataPoints.push({
                y: average,
                x: i
            });
            
            
        } */

       this.historic.map( (item)=>{
           
            if(item.DdgTstamp >= tsMonthyDate){

                var d = new Date(item.DdgTstamp);
                var day = new Date(item.DdgTstamp).getDate();
            
                var dayName = days[d.getDay()];
               
                this._monthlyDataPoints.push({
                    y: parseFloat(item.DdgData[0].valor),
                   
                   x:  new Date(item.DdgTstamp)
                 //  x: dayName,
                });
                this._monthlyData.push(item);
            }
        }) 
        
        console.log("_monthlyDataPoints");
        console.log(this._monthlyDataPoints);

        var data = [];
        var dataSeries: any = { type: "line" };
        var dataPoints = [];
       
        dataSeries.dataPoints = dataPoints;
        data.push(dataSeries);   

       // this._dailyDataPoints = data;

		let chart = new CanvasJS.Chart(`chartContainer${this.device.DgMac}`, {
            animationEnabled: true,
            exportEnabled: true,
            zoomEnabled: true,
            title: {
            //	text: "Estadísticas por Semana"
            },
            axisY: {
              //  title: "mg/dL",
               // interlacedColor: "azure",
                //tickColor: "azure",
                titleFontSize: 18,
                titleFontColor: "rgb(0,75,141)",
                fontSize: 20,
                suffix: "mg/dL",
                maximum: 400,
               //0 a 70 Deficiencia de Glucosa Baja 
               //>200 Glucosa alta
               stripLines: [
                {
                    value: 70,
                    //startValue:1,
                   // endValue:70,  
                   labelFontSize: 12    ,                 
                   // color: "#e53935" ,
                   color: 'blue',
                //    label: "Glucosa Baja (70) mg/dL",
                //    labelFontColor: '#e53935',
                //    labelBackgroundColor: "transparent" 
                },
                {
                    value: 200,
                   // startValue:200,
                  //  endValue:400, 
                  labelFontSize: 12    ,   
                  color: 'red',
                 // color: "#e53935" ,
                 //   label: "Glucosa alta (200) mg/dL",
                //     labelFontColor: '#e53935',
                 //   labelBackgroundColor: "transparent" 
                },
            ]

              //  valueFormatString: "DDD,DD",
               // labelFontSize: 20
                //interval: 100000000
              
            },
            axisX: {
                title: "Día",
               // interlacedColor: "azure",
               // tickColor: "azure",
                titleFontColor: "rgb(0,75,141)",
                titleFontSize: 18,
                fontSize: 20,
                valueFormatString: "DDD, DD",
            },
            data: [{
                    type: "line",
                    dataPoints: this._monthlyDataPoints
                }]
            });
                
        chart.render();

    }

    dailyData(){

        this._dailyData = [];

        this.historic.map( (item)=>{
           
             if(item.DdgTstamp >= tsMonthyDate){
               
                var dayNumber = new Date(item.DdgTstamp).getDay();
                var day = new Date(item.DdgTstamp).getDate();
                
                var month = new Date(item.DdgTstamp).getMonth();
                var hour = new Date(item.DdgTstamp).getHours();
                var mins = new Date(item.DgTstamp).getMinutes();

                if(!this._dailyData[day]){
                    this._dailyData[day]= {
                        info: {
                            day: dayNumber,
                            month,
                            title: new Date(item.DdgTstamp).getDate() + " de " + this.months[month]
                        },
                        data: []
                    };
                }
                this.historicFiltered.push({
                    info: {
                        day: dayNumber,
                        month,
                        title: new Date(item.DdgTstamp).getDate() + " de " + this.months[month]
                    },
                    item
                });


                if(!this._dailyDataPoints[day]){
                    this._dailyDataPoints[day] = [];
                }

                 this._dailyDataPoints[day].push({
                     y: parseFloat(item.DdgData[0].valor),
                   //  x: hour
                    x:  new Date(item.DdgTstamp)
                 });
                 this._dailyData[day].data.push(item);
            }
         });

         setTimeout(() => {

         for(let item in this._dailyData){

            //PROM
            var prom: any = 0;
            var total = 0;
            var count = 0;

            this._dailyData[item].data.map( (item) =>{
                total+= parseFloat(item.DdgData[0].valor)
                count++;
            });

            prom = total / count;
            this._dailyData[item].info.prom = parseInt(prom);
            this._dailyData[item].info.attribute = this.device.DgAttributes['Glu'];

            let chart = new CanvasJS.Chart(`chartContainer${this.device.DgMac}-day${item}`, {
                animationEnabled: true,
                exportEnabled: true,
                title: {
                //	text: "Estadísticas día: " + item
                },
                axisY: {
                    //title: "mg/dL",
                    //interlacedColor: "azure",
                    //tickColor: "azure",
                    titleFontColor: "rgb(0,75,141)",
                    fontSize: 20,
                    titleFontSize: 18,
                    suffix: "mg/dL",
                    stripLines: [
                        {
                            value: 70,
                            //startValue:1,
                           // endValue:70,  
                           labelFontSize: 12    ,    
                           color: "blue",             
                          //  color: "#e53935" ,
                          //  label: "Glucosa Baja (70) mg/dL",
                      //      labelFontColor: '#e53935',
                        //    labelBackgroundColor: "transparent" 
                        },
                        {
                            value: 200,
                           // startValue:200,
                          //  endValue:400, 
                          labelFontSize: 12    ,    
                          color: "red",               
                       //   color: "#e53935" ,
                        //    label: "Glucosa alta (200) mg/dL",
                          //   labelFontColor: '#e53935',
                         //   labelBackgroundColor: "transparent" 
                        },
                    ]
                 
                 //   valueFormatString: "#M,,.",
                   // labelFontSize: 20
                    //interval: 100000000
                  
                },
                axisX: {
                    title: "Hora",
                  //  interlacedColor: "azure",
                  //  tickColor: "azure",
                    titleFontColor: "rgb(0,75,141)",
                    fontSize: 20,
                    titleFontSize: 18,
                    valueFormatString: "HH:mm",
                },
                data: [{
                      //  yValueFormatString: "#,### Units",
                        xValueFormatString: "HH:mm",
                        type: "spline",
                        dataPoints: this._dailyDataPoints[item]
                    }]
                });
          
                chart.render();
           
                    
            
         }
        }, 1000);

    }
    
    ngOnInit() {
        console.log("container");
        this.actualAttribute = this.device.DgAttributes['Glu'];
       
        setTimeout(()=>{

                this.monthlyData();
                this.dailyData();

    }, 1000)
    }

}


/*


import { Component, ViewEncapsulation, OnInit , Input, Output} from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';

import { TEMPERATURES } from './temperatures';

@Component({
    selector: 'report_by_name-container',
   // encapsulation: ViewEncapsulation.None,
    templateUrl: './report_by_name.html',
    styleUrls: ['./report_by_name.css']
})
export class ReportByNameContainer implements OnInit {

    @Input() device: any;
    @Input() historic: any;


    title = 'Multi-Series Line Chart';

    data: any;

    svg: any;
    margin = {top: 20, right: 80, bottom: 30, left: 50};
    g: any;
    width: number;
    height: number;
    x;
    y;
    z;
    line;

    constructor() {

    }

    ngOnInit() {

        this.data = TEMPERATURES.map((v) => v.values.map((v) => v.date ))[0];
        //.reduce((a, b) => a.concat(b), []);

        this.initChart();
        this.drawAxis();
        this.drawPath();
    }

    private initChart(): void {
        this.svg = d3.select('svg');

        this.width = this.svg.attr('width') - this.margin.left - this.margin.right;
        this.height = this.svg.attr('height') - this.margin.top - this.margin.bottom;

        this.g = this.svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

        this.x = d3Scale.scaleTime().range([0, this.width]);
        this.y = d3Scale.scaleLinear().range([this.height, 0]);
        this.z = d3Scale.scaleOrdinal(d3ScaleChromatic.schemeCategory10);

        this.line = d3Shape.line()
            .curve(d3Shape.curveBasis)
            .x( (d: any) => this.x(d.date) )
            .y( (d: any) => this.y(d.temperature) );

        this.x.domain(d3Array.extent(this.data, (d: Date) => d ));

        this.y.domain([
            d3Array.min(TEMPERATURES, function(c) { return d3Array.min(c.values, function(d) { return d.temperature; }); }),
            d3Array.max(TEMPERATURES, function(c) { return d3Array.max(c.values, function(d) { return d.temperature; }); })
        ]);

        this.z.domain(TEMPERATURES.map(function(c) { return c.id; }));
    }

    private drawAxis(): void {
        this.g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + this.height + ')')
            .call(d3Axis.axisBottom(this.x));

        this.g.append('g')
            .attr('class', 'axis axis--y')
            .call(d3Axis.axisLeft(this.y))
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 6)
            .attr('dy', '0.71em')
            .attr('fill', '#000')
            .text('Temperature, ºF');
    }

    private drawPath(): void {
        let city = this.g.selectAll('.city')
            .data(TEMPERATURES)
            .enter().append('g')
            .attr('class', 'city');

        city.append('path')
            .attr('class', 'line')
            .attr('d', (d) => this.line(d.values) )
            .style('stroke', (d) => this.z(d.id) );

        city.append('text')
            .datum(function(d) { return {id: d.id, value: d.values[d.values.length - 1]}; })
            .attr('transform', (d) => 'translate(' + this.x(d.value.date) + ',' + this.y(d.value.temperature) + ')' )
            .attr('x', 3)
            .attr('dy', '0.35em')
            .style('font', '10px sans-serif')
            .text(function(d) { return d.id; });
    }

}
*/