import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { CognitoCallback, LoggedInCallback, UserLoginService, UserParametersService, Callback, UserRegistrationService } from "../../service/cognito.service";
import { DynamoDBIOTService } from "../../service/ddbiot.service";


import { BucketBeaconsService } from "../../service/bucket.beaconsimages.service";



import { Observable } from "rxjs";
import * as fromRoot from '../../common/index';
import * as layout from '../../common/layout/layout.actions';
import * as games from '../../common/games/games.actions';
import * as devices from '../../common/devices/devices.actions';

import { Store } from "@ngrx/store";
import timeago from 'timeago.js';

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { MatSort, MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material';

import { utils } from '../../lib/utils';

import { CompleterService, CompleterData } from 'ng2-completer';
import { HistoricExport } from '../../service/historicExport.service';
import { JSONP_ERR_NO_CALLBACK } from "@angular/common/http/src/jsonp";

declare var $: any;

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './forms.html',
    styleUrls: ['./forms.css', './searchbar.css']
})
export class FormsComponent implements LoggedInCallback, OnInit {

    public searchStr: string;
    public captain: string;
    public dataService: CompleterData;

    inFormsModal= false;

    formsDevice: any[] = [];
    formsEnterprise: any[] = [];
    enterprise ; 
    decodeDataDevice: any[] = [];
    newData : any[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    public actualDevice: any = {
        DdgMacDV: "",
        DgMac: ""
    };

    public inModal$: Observable<boolean>;
    public devices$: Observable<any>;

    public isDevice = false;

    public initDate: any;
    public endDate: any;

    public infoRangedText = "";

    public enterpriseForms;

    // displayedColumns = ['date', 'event', 'ubication'];
    displayedColumns = ['date', 'event'];
    //exampleDatabase = new ExampleDatabase();
    dataSource: ExampleDataSource | null;


    selected: boolean = false;
    typeArchive: boolean = false;
    showNoData: boolean  = false;

    constructor( private UserParametersService: UserParametersService, public ddb: DynamoDBIOTService, private historicExport: HistoricExport, public dialog: MatDialog, private completerService: CompleterService, private _utils: utils, private store: Store<fromRoot.AppState>, private el: ElementRef, public router: Router, public userService: UserLoginService) {

        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.inModal$ = store.select(fromRoot.getLayoutModalIsOpen);
        this.userService.isAuthenticated(this);

        this.store.dispatch(new layout.CloseModalAction(true));

        this.UserParametersService.getParameters(this.setParameters.bind(this));



        var test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];
        };
        timeago.register('test_local', test_local_dict);

    }

    setParameters(data) {
        console.log(data);
        if (data[0].Value) {
            this.enterprise = data[0].Value;
        }
        this.ddb.getEnterpriseForms(this.enterprise, this.onEnterpriseForms.bind(this));
    }

    ngOnInit(): void {
        this.prepareSearchBar();
        //this.dataSource = new ExampleDataSource(this, this.paginator);

        this.dataSource = new ExampleDataSource(this, this.paginator);

        var s;
        this.devices$.forEach((data) => { s = data });
        var z = [];
        for (let key in s) {
            z.push(s[key]);
        }

        this.devices$.subscribe((data) => {
            var z = [];
            for (let key in data) {
                z.push(s[key]);
            }
            this.dataService = this.completerService.local(z, 'DgAlias', 'DgAlias');

        });
        this.dataService = this.completerService.local(z, 'DgAlias', 'DgAlias');

       

    }
    assignForm(item){
        this.inFormsModal = false;
        var unique = false;
        this.formsDevice.map( (val)=>{
            if(val.FrName == item.TfName){
                console.log("ya existe formulario")
                if(item.TfUnique){
                    unique = true;
                    alert("Este formulario ya está asignado a este dispositivo");
                }
              
            }
        })

        if(!unique) {
            this.ddb.assignForm(this.actualDevice, item, this.enterprise, this.onAssignedForm.bind(this));
        }
    }

    onAssignedForm(err,data){
        if(!err){
            alert("Formulario asignado con éxito");
            this.ddb.getDeviceForms(this.actualDevice.DgMac,this.onFormsData.bind(this));
        }
        else {
            alert("Error asignando formulario");
        }
    }

    onEnterpriseForms(err, data){
        console.log("onEnterpriseForms");
        console.log(err);
        console.log(data);
        this.formsEnterprise = data.Items;
    }

    onFormClick(item){
        this.router.navigate(['/securehome/forms', item.FrDeviceMac, item.FrTstamp]);
    }
    onSelectedM(item){
        if(item){
            this.isDevice = false;
            var mac = item.originalObject.DgMac;

             //get actual device
             this.actualDevice = {
                DgMac: ""
            }
            var comp = this;
            this.devices$.forEach((data) => {
                if (data != undefined) {
                    if (data[mac]) {
                        comp.actualDevice = data[mac]
                    }
                }
            })
            this.infoRangedText = "Formularios";
            this.ddb.getDeviceForms(mac,this.onFormsData.bind(this));

        }
    }

    onFormsData(err, data){
        console.log(err);
        console.log(data);
        this.formsDevice = [];
        this.newData = [];
        if (!err) {
            this.newData = data.Items;
         
            if(this.newData.length == 0){
                this.showNoData = true; 
            }else{
                this.showNoData = false;
            }
            data.Items.map((item) => {
                this.formsDevice.push(item);
            })
        }
        this.isDevice = true;

        
    }

    onSelected(item) {

        console.log(item);
        if (item) {
            this.isDevice = false;
            var mac = item.originalObject.DgMac;

            //get actual device
            this.actualDevice = {
                DgMac: ""
            }
            var comp = this;
            this.devices$.forEach((data) => {
                if (data != undefined) {
                    if (data[mac]) {
                        comp.actualDevice = data[mac]
                    }
                }
            })
            this.infoRangedText = "Datos de todos los tiempos";
            // get historic data from DynamoDB
            this.ddb.getDeviceHistoric(mac, this.onHistoricData.bind(this));
        }

    }
    setRangedHistoric() {

        // get historic data from DynamoDB
        console.log(this.initDate);
        console.log(this.endDate);
        if (this.initDate && this.endDate) {
            this.isDevice = false;
            var _initDate = new Date(this.initDate).getTime();
            var _endDate = new Date(this.endDate).getTime();
            console.log(_initDate);
            console.log("In historic)");
            this.infoRangedText = "Datos desde  " + this.initDate + "  " + "  hasta  " + this.endDate;

            if (_initDate > _endDate) {
                alert("Digite fechas válidas");
            }
            else {
                this.ddb.getDeviceRangedHistoric(this.actualDevice.DgMac, _initDate, _endDate, this.onHistoricData.bind(this));
            }
        }

    }
    onPressAsign(){
        this.inFormsModal = true;
    }
    onHistoricData(err, data) {
        console.log(err);
        console.log(data);
        this.decodeDataDevice = [];
        this.newData = [];
        if (!err) {
            this.newData = data.Items;
         
            if(this.newData.length == 0){
                this.showNoData = true; 
            }else{
                this.showNoData = false;
            }
            data.Items.map((item) => {
                this.decodeDataDevice.push(item);
            })
            this.displayedColumns = ['date', 'event'];
            for (let key in this.actualDevice.DgAttributes) {
                console.log(key);
                this.displayedColumns.push(key);
            }

        }
        this.isDevice = true;


    }


    export(typeArchive) {
        console.log("YOU`RE ON FIRST EXPORT METHOD");
        console.log("HOW ARRIVE EXPORT TYPEARCHIVE: ", typeArchive);
        if(typeArchive != undefined){
            
            let data = [];
            let obj = {};
            var date: any;
            var motive: any;
            var attributes: any;
            var device: any = this.actualDevice.DgAlias;
            var header = ["Dispositivo","Fecha","Evento"];
            var attrs = {};
            for (let j in this.newData) {
                
                date = this.newData[j].DdgDatetimeServer;
                motive = this.returnStateInfoText(this.newData[j].DdgMotivo);
                attributes = this.newData[j].DdgData;
              
                attributes.map((item, i) => {
    
                    let vari = this.actualDevice.DgAttributes[item.variable].alias;
    
                    let val = this.returnAttrValue(attributes, item.variable);
    
                    attrs[vari] = val;
                    
                });
                
                obj = { Dispositivo: device, Fecha: date, Evento: motive, ...attrs }
    
                data.push(obj);
            }
            for(let i in attrs){
                header.push(i);
            }
            if (typeArchive) {
                console.log("DATAAAAAA EXCEL: ", data);
                this.historicExport.exportHistoricExcel(data, "Historico_" + device);
            } else {
                console.log("DATAAAAAA CSV: ", data);
                this.historicExport.exportHistoricCsv(data, "Historico_" + device, header.toString());
    
            }
        }

    }

    prepareSearchBar() {
        $(document).ready(function () {
            var $searchTrigger = $('[data-ic-class="search-trigger"]'),
                $searchInput = $('[data-ic-class="search-input"]'),
                $searchClear = $('[data-ic-class="search-clear"]');
            $searchTrigger.click(function () {
                var $this = $('[data-ic-class="search-trigger"]');
                $this.addClass('active');
                $searchInput.focus();
            });
            $searchInput.blur(function () {

                if ($searchInput.val().length > 0) {
                    return false;
                } else {
                    $searchTrigger.removeClass('active');
                }
            });
            $searchClear.click(function () {
                $searchInput.val('');
            });
            $searchInput.focus(function () {
                $searchTrigger.addClass('active');
            });
        });
    }
    data() {
        return this.decodeDataDevice;
    }
    returnSensorsCount(device) {
        //  console.log(device);
        var j = 0;
        for (let i in device.value.DgAttributes) {
            j++;
            //  console.log(i);
        }
        return j;
    }

    returnStateInfoText(val) {
        return this._utils.getStateReason(val).text;
    }
    setActualDevice(trama) {


        this.actualDevice = {
            DgMac: ""
        }
        var comp = this;
        this.devices$.forEach((data) => {

            console.log(data);
            console.log(trama.DdgMacDV);
            if (data != undefined) {
                console.log("passed")
                if (data[trama.DdgMacDV]) {
                    console.log("passed2");
                    comp.actualDevice = data[trama.DdgMacDV]
                }
            }

        })
        this.store.dispatch(new layout.OpenModalAction(true));
    }

    returnDeviceImage(mac) {

        return "https://s3.amazonaws.com/azcognito-iot/" + mac;
    }

    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, "test_local"); // returns '11 hours ago'
        // console.log(time);
        return time;
    }
    returnAttrValue(DdgData, position) {

        var device = this.actualDevice;

        var _item: any = 'N/A';
        DdgData.map((item) => {
            if (item.variable == position) {

                _item = item.valor;
            }
        });

        if (_item != 'N/A') {

            var newVal: any = _item;

            /*this.devices$.forEach((data) => {
                device = data[this.deviceMac];
            }) */
            if (device.DgAttributes[position].representacion == 'Hex') {
                _item = parseInt(_item, 16);
                var factorConversion = device.DgAttributes[position].factorConversion;
                factorConversion = factorConversion.replace("{valor}", _item);
                newVal = eval(factorConversion);
            }
            var _length = newVal.toString().length;
            if (_length >= 5 && device.DgAttributes[position].unidad != 'V' ) {

                newVal = newVal.toString().slice(0, 5);
            }

            _item = newVal;
    
           if (device.DgAttributes[position].unidad == 'dec' || device.DgAttributes[position].unidad == 'b') {

            }
            else {
                _item = _item + " " + device.DgAttributes[position].unidad;
            }

            if (device.DgAttributes[position].render == 'switch') {
                _item = _item == 1 ? 'On' : 'Off';
            } 
        }

        return _item


    }
    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            console.log("scanning Devices");
            //GET
        }
    }



}


export interface UserData {
    id: string;
    name: string;
    progress: string;
    color: string;
}


export class ExampleDataSource extends DataSource<any> {
    constructor(private _exampleDatabase: FormsComponent, private _paginator: MatPaginator) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<UserData[]> {
        const displayDataChanges = [
            this._exampleDatabase.devices$,
            this._paginator.page,
        ];

        return Observable.merge(...displayDataChanges).map(() => {
            const data = this._exampleDatabase.data();
            console.log(data);

            // Grab the page's slice of data.
            const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
            return data.splice(startIndex, this._paginator.pageSize);
        });
    }

    disconnect() { }
}



