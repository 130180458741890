import { Component } from '@angular/core';

import { AwsUtil } from "./service/aws.service";
import { UserLoginService, CognitoUtil, LoggedInCallback } from "./service/cognito.service";

import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { combineLatest } from 'rxjs/observable/combineLatest'
import { DynamoDBIOTService } from './service/ddbiot.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app works!';



  constructor(

    public translate: TranslateService,
    private http: HttpClient,
    public ddb: DynamoDBIOTService,
    public router: Router,
    public awsUtil: AwsUtil, public userService: UserLoginService, public cognito: CognitoUtil
  ) {

    //translate

    //  const lang = this._utils.getCurrentLang();
    const getGlobal = this.http.get('assets/i18n/_i18n.json');
    console.log("getGloba", getGlobal);

    var s = translate.getBrowserCultureLang();

    var newLang = s.slice(0, 2);

    const getDefault = this.http.get('assets/i18n/' + newLang + '.json');
    console.log("getDefault", getDefault);

    const combined = combineLatest(getGlobal, getDefault);

    const subscribe = combined.subscribe(

      (res) => {
        console.log("mryres", res);
        translate.setTranslation('_i18n', Object.assign({}, res[0], res[1]));
        translate.addLangs(['_i18n', ...Object.keys(res[0])]);
        translate.setDefaultLang('_i18n');
        // !lang ? this._utils.setLang('es') : this._utils.setLang(lang);
        console.log("new method");




        console.log("newwwwwww:", newLang);
      }
    );


    // Observable.combineLatest(getGlobal, getDefault).subscribe(
    //   (res) => {
    //     // this language will be used as a fallback when a translation isn't found in current language
    //     translate.setTranslation('_i18n', Object.assign({}, res[0], res[1]));
    //     translate.addLangs(['_i18n', ...Object.keys(res[0])]);
    //     translate.setDefaultLang('_i18n');
    //     // the lang to use, if the lang isn't available, it will use the current loader to get them
    //     !lang
    //       ? this._utils.setLang('es')
    //       : this._utils.setLang(lang);
    //     // console.log("CURRENT LANG", lang);
    //   }
    // );

  }


  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.userService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log("AppComponent: the user is authenticated: " + isLoggedIn);
    let mythis = this;

    if (isLoggedIn) {

      var actualUser: any = localStorage.getItem('actualUser')

      actualUser = JSON.parse(actualUser);

      console.log("actualUSer!", actualUser.id);
      this.ddb.getUser(actualUser.id, (err, data) => {
        if (!err) {

          console.log("myUserrr!!!!!!!", data);

          if (data.Items[0]) {

            var disabled = data.Items[0].disabled;
            if (disabled) {
              this.userService.logout();

              this.router.navigate(['/home/login']);

              setTimeout(function () {
                location.reload();
              }, 1000);
            }
            else {

            }
          }


        }
      }, [], null)
    }
    this.cognito.getIdToken({
      callback() {

      },
      callbackWithParam(token: any) {
        // Include the passed-in callback here as well so that it's executed downstream
        console.log("AppComponent: calling initAwsService in callback")
        mythis.awsUtil.initAwsService(null, isLoggedIn, token);
      }
    });
  }
}
