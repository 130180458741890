import { Component, OnInit, Input, Output } from '@angular/core';
import { Observable } from "rxjs";

import { Store } from "@ngrx/store";

import * as fromRoot from '../../../common/index';
import { DynamoDBService } from "../../../service/ddb.service"
@Component({
    selector: 'barchart-realtime',
    templateUrl: 'barchart.html',
    styleUrls: ['./barchart.css']

})


export class BarChartComponent implements OnInit {


    @Input() mac: any;
    @Input() device: any;

    charData: any = [];

    chartOptions = {
        responsive: true
    };

    chartData = [
        { data: [], label: 'Temperatura' },
    ];
    public lineChartColors: Array<any> = [
    ];

    chartLabels = [];

    onChartClick(event) {
        console.log(event);
    }
    constructor(public _db: DynamoDBService) {

        this.historicData = this.historicData.bind(this);

    }

    ngOnInit() {
        this._db.getRangedData(this.mac, this.historicData);
    }
    historicData(err, data) {
        if (!err) {

            var total = [];
            var s = this.classifyByDayOfWeek(data.Items);
            console.log(s);
            var mythis = this
            s.forEach((item, index) => {
                console.log(item);
                console.log(index);
                item.forEach((item2) => {
                    for (let i = 0; i < item2.DdgData.length; i++) {
                        if (item2.DdgData[i].variable == 'T' || item2.DdgData[i].variable == 'T1') {
                            if (item2.DdgData[i].valor != " ") {
                                var val = item2.DdgData[i].valor;
                                var newVal = item2.DdgData[i].valor;
                                if (mythis.device.DgAttributes[item2.DdgData[i].variable].representacion == 'Hex') {
                                    val = parseInt(val, 16);
                                    var factorConversion = mythis.device.DgAttributes[item2.DdgData[i].variable].factorConversion;
                                    factorConversion = factorConversion.replace("{valor}", val);
                                    newVal = eval(factorConversion);
                                    console.log(newVal);
                                }
                                if (!total[index]) {
                                    total[index] = [];
                                    total[index].total = 0;
                                    total[index].num = 0;
                                    total[index].date = ""
                                }
                                total[index].total = total[index].total + parseFloat(newVal);
                                total[index].num = total[index].num + 1
                                total[index].date = item2.DdgDatetimeServer.slice(0, 10).trim();

                            }
                        }
                    }
                });

            });

            total.forEach((data, index) => {
                console.log(data);
                var newAverage = data.total / data.num;
                console.log(newAverage);

                mythis.chartData[0] = Object.assign({}, mythis.chartData[0], {
                    data: [...mythis.chartData[0].data, newAverage]
                });
                mythis.chartLabels = [...mythis.chartLabels, data.date];
                this.lineChartColors.push({ // dark grey
                    backgroundColor: '#4DB6AC',
                    borderColor: '#00796B',
                    pointBackgroundColor: 'rgba(77,83,96,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(77,83,96,1)'
                });


                //  mythis.chartData[0].data.push(newAverage);
                //  mythis.chartLabels.push(data.date);

            })
            console.log(total);
            console.log(this.chartData);
            console.log(this.chartLabels);
            // c

        }
    }

    classifyByDayOfWeek(customArr) {
        var byDayOfWeek = [];
        for (var i = 0; i < customArr.length; i++) {

            var __Day = customArr[i]['DdgDatetimeServer']
            __Day = __Day.slice(8, 10).trim();
            __Day = parseInt(__Day);

            var day = new Date(customArr[i]['DdgTstamp']).getDay();

            if (!byDayOfWeek[__Day]) {
                byDayOfWeek[__Day] = [];
            }
            byDayOfWeek[__Day].push(customArr[i]);
        };
        return byDayOfWeek;
    }

}