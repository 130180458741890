import { Component, OnInit, Input,  Output } from '@angular/core';

@Component({
    selector: 'countcard-item',
    templateUrl: 'countcard.html',
    styleUrls: ['./countcard.css']
 
})

export class CountCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;

    constructor(){}
    ngOnInit(){}
}