import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import * as io from 'socket.io-client';



@Injectable()
export class SocketIOService {
  private url = 'https://thingsmanager.co';  
  private socket;
  constructor(){

  }
  sendMessage(message){
    this.socket.emit('add-message', message);    
  }
  
  getMessages() {
      console.log("getMessagesInitializacion");
    let observable = new Observable(observer => {
      //reconnect: true,
      this.socket = io(this.url, {secure: true,  rejectUnauthorized : false});
      this.socket.on('message', (data) => {
       //   console.log("new messageeee", data);
        observer.next(data);

      });
      return () => {
        this.socket.disconnect();
      };  
    })     
    return observable;
  }  
}