import { createSelector } from 'reselect';
import * as devices from './devices.actions';

import { utils } from '../../lib/utils';

import { SharedService } from "../../service/global.service";

export interface State {
  loaded: boolean;
  loading: boolean;
  entities: object;
  logs: Array<any>;
  count: number;
  page: number;
};

const initialState: State = {
  loaded: false,
  loading: false,
  entities: {},
  logs: [],
  count: 0,
  page: 1
};


import { TranslateService } from '@ngx-translate/core';

import { MzToastService } from "ng2-materialize/dist";


export function reducer(state = initialState, action: devices.DevicesActions): State {


  switch (action.type) {

    case devices.DeviceActionTypes.LOAD: {
      const page = action.payload;

    //  console.log("loading");
      return Object.assign({}, state, {
        loading: true,
        /*
         If there is no page selected, use the page from the initial state
         */
        page: page == null ? state.page : page
      });
    }
    case devices.DeviceActionTypes.SET_REALTIME_DEVICE: {
   //   console.log("new Socket Message");
   //   console.log(action.payload);
      action.payload = JSON.parse(action.payload);
      if (action.payload) {
       // console.log("1");
        if (action.payload.DdgMacDV) {
        //  console.log("2");
          if (state.entities[action.payload.DdgMacDV]) {
        //    console.log("3");
            if (state.entities[action.payload.DdgMacDV].realtime) {
            }
            else {
              state.entities[action.payload.DdgMacDV].realtime = [];
            }

            if (state.entities[action.payload.DdgMacDV].realtime.map) {
              state.entities[action.payload.DdgMacDV].realtime.map = [];
            }
            else {

            }

            state.entities[action.payload.DdgMacDV].realtime.map.push({
              latitude: action.payload.DdgLatDV,
              longitude: action.payload.DdgLongDV
            })

            action.payload.DdgData.map((value) => {

            //  console.log("holaaaa");

              value.date = action.payload.DdgDatetimeServer;
              value.date = value.date.slice(10, value.date.length);


              if (state.entities[action.payload.DdgMacDV].realtime[value.variable]) {
              }
              else {
                state.entities[action.payload.DdgMacDV].realtime[value.variable] = [];
              }

              state.entities[action.payload.DdgMacDV].realtime[value.variable].push(value);

              //REALTIME (PROTOTYPE)
            /*  setTimeout(() => {

                var date = new Date();
                var _date = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                value.date = _date;
                state.entities[action.payload.DdgMacDV].realtime[value.variable].push(value);
              }, 7000);
              setTimeout(() => {
                var date = new Date();
                var _date = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                value.date = _date;
                state.entities[action.payload.DdgMacDV].realtime[value.variable].push(value);
              }, 14000);
              setTimeout(() => {
                var date = new Date();
                var _date = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                value.date = _date;
                state.entities[action.payload.DdgMacDV].realtime[value.variable].push(value);
              }, 21000); */


            })

          }

        }
      }

// console.log("prepared realtime");
   //   console.log(state.entities);

      return state;
    }

    case devices.DeviceActionTypes.SET_DEVICE: {



      

      if (action.payload.payload ) {
        let child = action.payload.payload.val();
       // console.log(child);

        child.DdgLatDV = parseFloat(child.DdgLatDV);
        child.DdgLongDV = parseFloat(child.DdgLongDV);

        //  var logs = state.logs;

       
        if (state.entities[child.DdgMacDV]) {

          state.logs.unshift(child);
          state.entities[child.DdgMacDV].log.unshift(child);

     
         

          setTimeout(() => {
            
        
      
          //console.log("newTrama");
          //console.log(newTrama)

          var htmlLog = '';
          if(child.DdgMotivo == 1){

           /* htmlLog = '<div>' +
              '<div style="    display: flex;  font-size: 15px;padding: 3px;font-weight: 600;color: rgba(0,0,0,.87);"> <div> ' + _title + ' </div> <div> <i style="padding-left: 17px;" class="material-icons">info</i></div> </div>' +
              '<div style="display:flex;">   ' +
              ' <div> <img style="margin-top:15px;width:70px; height:70px;" src="https://s3.amazonaws.com/azcognito-iot/' + child.DdgMacDV + '"> </div>' +
              ' <div style="padding-left: 24px;">  <div style="color:#424242; font-weight: bold ;  font-size: 14px; padding:10px;">' + child.DdgNameDV + '</div>' +

              //  ' <div style="padding: 10px;color: #616161;font-size: 12px;padding-top: 0px;"> ' + comp.returnStateInfoText(child.val().DdgMotivo) + '  </div>' +
              ' <div style="padding: 10px;color: #616161;font-size: 12px;padding-top: 0px;"> ' + child.DdgDatetimeServer + '  </div></div>' +

              '</div>' +
              '</div>'; */
          }
          else {

            var s: MzToastService = new MzToastService;

            var tranlate: any = TranslateService;

            

            var _utils : any= new utils(tranlate);
            var _title = _utils.getTitle();
            var newTrama = _utils.getStateReason(child.DdgMotivo).text;


            htmlLog = '<div style="padding: 12px;">' +
              '<div style="    display: flex;  font-size: 15px;padding: 3px;font-weight: 600;color: #d32f2f;"> <div> Alarma </div> <div> <i style="padding-left: 17px;" class="material-icons">warning</i></div> </div>' +
              '<div style="display:flex;">   ' +
              ' <div> <img style="margin-top:15px;width:70px; height:70px;" src="https://s3.amazonaws.com/azcognito-iot/' + child.DdgMacDV + '"> </div>' +
                ' <div style="   padding-left: 5px;">  <div style="color: #d32f2f; font-weight: bold ;  font-size: 14px; padding:10px;">' + child.DdgNameDV + '</div>' +
              ' <div style="padding-left: 24px;">  <div style="color: #d32f2f; font-weight: bold ;  font-size: 14px; padding:10px;">' + newTrama + '</div>' +

              //  ' <div style="padding: 10px;color: #616161;font-size: 12px;padding-top: 0px;"> ' + comp.returnStateInfoText(child.val().DdgMotivo) + '  </div>' +
              ' <div style="padding: 10px;color: #616161;font-size: 12px;padding-top: 0px;"> ' + child.DdgDatetimeServer + '  </div></div>' +

              '</div>' +
              '</div>';

              s.show(htmlLog, 4000, 'white');
          }
          //console.log(s);
        

        }, 4000);
          //   console.log(state.entities);
          ////  state.entities[action.payload.DdgMacDV] = device;
          //  var device = state.entities[action.payload.DdgMacDV];
          //  device.log.unshift(action.payload);
          //var _devices_ = state.entities;
          // _devices_[action.payload.DdgMacDV] = device;

        }

        /*return Object.assign({}, state, {
          loaded: true,
          loading: false,
          logs: logs,
          entities: _devices_
        }); */
     
      }
      return state;
    }

    case devices.DeviceActionTypes.UPDATE_LOGS_COMMENTS: {

     // console.log(action);

      state.logs.map( (log)=>{

        if(log.DdgMacDV == action.payload.mac && log.DdgTstamp == action.payload.timestamp){
          if(!log.DdgAlarComentarios){
            log.DdgAlarComentarios = [];
          }
          log.DdgAlarComentarios.push(action.payload.message)
        }

      });

      return state;
    }
    case devices.DeviceActionTypes.UPDATE_ATTR_REALTIME_CHART: {
    //  console.log(state.entities);
      var _devic = state.entities;
   //   console.log(action);
      state.entities[action.payload.DgMac].DgAttributes[action.payload.AttrName].renderRealtime = action.payload.NewRealtimeChart;
   //   console.log(state.entities);

      return state;
    }

    case devices.DeviceActionTypes.UPDATE_ATTR_ALIAS: {
   //   console.log(state.entities);
      var _devic = state.entities;
      //  console.log(_devic[action.payload.DgMac]);
      //  _devic[action.payload.DgMac]

    //  console.log(action);
      state.entities[action.payload.DgMac].DgAttributes[action.payload.AttrName].alias = action.payload.NewAlias;
    //  console.log(state.entities);
      /* return Object.assign({}, state, {
        loaded: true,
        loading: false,
        entities: state.entities
      }); */

      return state;
    }
    case devices.DeviceActionTypes.UPDATE_ALAR_ESTADOS : {

    //  console.log(action.payload);
      state.logs.map( (item)=>{
        
        if(action.payload.DdgTstamp == item.DdgTstamp){
       //   console.log("found");
          if(action.payload.DdgAlarEstado){
            item.DdgAlarEstado = false;
          }
          else {
            item.DdgAlarEstado = true;
          }
        }
      });

      return state;
    }

    case devices.DeviceActionTypes.LOAD_SUCCESS: {

      //console.log("gggg", action);

      var _devices = {};
      var _logs = [];
      if (action.payload.devices) {
        _devices = action.payload.devices;
        _logs = action.payload.logs;
      }

      _logs.sort(function (a, b) {

        //console.warn("a", a.DdgTstamp);
        var c = (a.DdgTstamp);
        var d = (b.DdgTstamp);
        return d - c;
      });


      //REALTIME INITIALIZACION
      /* for (let mac in _devices) {
         if (_devices[mac].log[0]) {
 
           var log = _devices[mac].log[0];
           _devices[mac].realtime = [];
 
           //MAP 
           _devices[mac].realtime.map= [];
           _devices[mac].realtime.map.push({
             latitude: log.DdgLatDV,
             longitude: log.DdgLongDV
           })
 
 
           log.DdgData.map((value) => {
 
             console.log("holaaaa");
 
             value.date = log.DdgDatetimeServer;
             value.date = value.date.slice(10, value.date.length);
 
             if (_devices[mac].realtime[value.variable]) {
 
             }
             else {
               _devices[mac].realtime[value.variable] = [];
 
             }
 
             _devices[mac].realtime[value.variable].push(value);
 
           })
 
         }
 
       } */




      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        entities: _devices,
        logs: _logs
      });
    }

    case devices.DeviceActionTypes.LOAD_FAILURE: {

      console.log("fail");
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        entities: [],
        count: 0
      });
    }
    default:
      return state;


  }

}
/*
 Selectors for the state that will be later
 used in the games-list component
 */
export const getEntities = (state: State) => state.entities;
export const getLogs = (state: State) => state.logs;
export const getPage = (state: State) => state.page;
export const getCount = (state: State) => state.count;
export const getLoadingState = (state: State) => state.loading;

