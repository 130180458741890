import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CognitoCallback, UserLoginService, LoggedInCallback } from "../../../service/cognito.service";
import { DynamoDBService } from "../../../service/ddb.service";
import { DynamoDBIOTService } from "app/service/ddbiot.service";

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './login.html',
    styleUrls: ['./login.css']
})
export class LoginComponent implements CognitoCallback, LoggedInCallback, OnInit {
    email: string;
    password: string;
    errorMessage: string;


    year: any;
    constructor(
        public router: Router,
        public ddb: DynamoDBService,
        public _ddb: DynamoDBIOTService,
        public userService: UserLoginService) {
        console.log("LoginComponent constructor");
    }

    ngOnInit() {
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.userService.isAuthenticated(this);

        var _date = new Date();

        this.year = _date.getFullYear();
    }

    onLogin() {
        if (this.email == null || this.password == null) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.errorMessage = null;

        this._ddb.getUser(this.email, (err, data) => {
            if (!err) {

                if (data.Items[0]) {

                    var disabled = data.Items[0].disabled;
                    if (disabled) {
                        alert("Este usuario se encuentra inactivo, por favor contacte con un administrador.")


                    
                    }
                    else {
                        this.userService.authenticate(this.email, this.password, this);
                    }
                }
            }
        }, [],null)

     
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { //error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
            if (this.errorMessage === 'User is not confirmed.') {
                console.log("redirecting");
                this.router.navigate(['/home/confirmRegistration', this.email]);
            }
        } else { //success
            //      this.ddb.writeLogEntry("login");


            this.router.navigate(['/securehome']);
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn)
            this.router.navigate(['/securehome']);
    }
}