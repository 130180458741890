import { Component, OnInit, Input, Output } from '@angular/core';

declare var $: any;

@Component({
    selector: 'analogcard-item',
    templateUrl: 'analogcard.html',
    styleUrls: ['./analogcard.css']

})

export class AnalogCardComponent implements OnInit {

    @Input() value: any;
    @Input() valueString: any;
    @Input() name: any;
    @Input() attributes: any;

    donutChartClass = {

    }
    donutChartJSClass = {

    }

    percentage = 0;

    constructor() { }

  

    ngOnInit() {

        var min = this.attributes.minRango;
        var max = this.attributes.maxRango;

        var value = this.value;

        var newValue =  (this.name).toString().replace(/ /g, '');


        this.donutChartClass = {
            ['donut-chart'+ newValue] : true
        };
        this.donutChartJSClass  ={
            ['donut-chart-js'+ newValue] : true
        };
       
        
        setTimeout(function() {

            
    
        var donutChart = $('.donut-chart-js'+ newValue);

        if (donutChart.length > 0) {
         
            $.each(donutChart, function (index, item) {
                var donutChartPercentage = $(item).attr('data-percentage');
                var donutChartRadio = $(item).find('.donut-chart').attr("r");
                var donutChartValue = ((max - Number(value)) * (6.28 * Number(donutChartRadio))) / max;

                $(item).find('.donut-chart-value').html(donutChartPercentage + "%");
                $(item).find('circle.donut-chart'+ newValue).css('stroke-dashoffset', donutChartValue);
            });
        }

        }, 4000);



    }


}

