import { Component, OnInit, Input,  Output } from '@angular/core';

import { GraphQLService } from '../../service/graphql.service';
@Component({
    selector: 'digitalcard-item',
    templateUrl: 'digitalcard.html',
    styleUrls: ['./digitalcard.css']
 
})

export class DigitalCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;
    @Input() checked: any;
    @Input() encender: any;
    @Input() comando: any;
    @Input() color: any;
    @Input() variable: any;
    @Input() inverted: any;
    @Input() decodeData: any;

    public isChanged = false;

    textOffOn = "off       /      on";

    constructor( public graphqlService: GraphQLService ){}
    ngOnInit(){

      
    }
    returnValue(val){
        return "";
        if(this)
        if(val == 1 || val == "1"){
            return "Encendido";
        }
        else {
            return "Apagado";
        }
    }
    onChange(ev){
        console.log("switchhhhh!!!!!!: ", ev);

        var check = 1;

        if(this.comando == 'encenderComando'){

            if(ev == false){
                check = 0;
            }
            else {
                check = 1;
            }

            this.graphqlService.encenderComando(check, this.decodeData).subscribe( (khee)=>{
                console.log("kheee?", khee)
            })
        }
        else if(this.comando == 'encenderEscrituraComando'){
            
           /* var valor1 = 0;
            var valor2 = 0;

            if(this.variable == 'o1'){
                valor2 = 1;

                  
                if(ev == false){
                    check = 1;
                }
                else {
                    check = 0;
                }
            }
            else if(this.variable == 'o2'){
                valor2 = 2;

                    
                if(ev == false){
                    check = 0;
                }
                else {
                    check = 1;
                }
            } */

          

            this.graphqlService.encenderEscrituraComando(ev, this.variable,this.inverted, this.decodeData).subscribe( (khee)=>{
                console.log("kheee?", khee)
            })
        }


        
    }
    
}