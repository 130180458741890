import { Action } from '@ngrx/store';
import { type } from "../util";


export const DeviceActionTypes = {
  LOAD: type('[Devices] load devices'),
  SET_DEVICE: type('[Devices] set device'),
  UPDATE_ATTR_ALIAS: type('[Devices] Update Attr Alias'),
  UPDATE_ALAR_ESTADOS: type('[DEVICES] Update Alar Estados'),
  UPDATE_LOGS_COMMENTS: type('[Devices] Update Logs Comment'),
  UPDATE_ATTR_REALTIME_CHART: type('[Devices] Update Attr Realtime Chart'),
  SET_REALTIME_DEVICE: type('[Devices] set REALTIME device'),
  SET_DEVICE_SUCCESS: type('[Devices] successfully SET device'),
  LOAD_SUCCESS: type('[Devices] successfully loaded devices'),
  LOAD_FAILURE: type('[Devices] failed to load devices'),

};

export class LoadDevicesAction implements Action {
  type = DeviceActionTypes.LOAD;
  constructor(public payload: any = null) { }
}

export class SetDeviceAction implements Action {
  type = DeviceActionTypes.SET_DEVICE
  constructor(public payload: any = null) { }
}

export class SetRealtimeDeviceAction implements Action {
  type = DeviceActionTypes.SET_REALTIME_DEVICE
  constructor(public payload: any = null) { }
}

export class UpdateAlarEstadoAction implements Action{
  type = DeviceActionTypes.UPDATE_ALAR_ESTADOS
  constructor(public payload:any = null){}
}
export class UpdateAttrAliasAction implements Action {
  type = DeviceActionTypes.UPDATE_ATTR_ALIAS
  constructor(public payload: any = null) { }
}
export class UpdateLogsComments implements Action{
  type = DeviceActionTypes.UPDATE_LOGS_COMMENTS
  constructor(public payload: any = null){}
}
export class UpdateAttrRealtimeChartAction implements Action {
  type = DeviceActionTypes.UPDATE_ATTR_REALTIME_CHART
  constructor(public payload: any = null) { }
}


export class LoadDevicesFailedAction implements Action {
  type = DeviceActionTypes.LOAD_FAILURE;
  constructor(public payload: any = null) { }
}
export class LoadDevicesSuccessAction implements Action {
  type = DeviceActionTypes.LOAD_SUCCESS;
  constructor(public payload: any = null) { }
}

export type DevicesActions = LoadDevicesAction | SetDeviceAction | UpdateAttrAliasAction |
UpdateLogsComments | UpdateAlarEstadoAction |
  UpdateAttrRealtimeChartAction | LoadDevicesFailedAction | LoadDevicesSuccessAction | SetRealtimeDeviceAction

