import { Component, OnInit, Input,  Output } from '@angular/core';

@Component({
    selector: 'infocard-item',
    templateUrl: 'infocard.html',
    styleUrls: ['./infocard.css']
 
})

export class InfoCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;
    @Input() icon: any;

    constructor(){}
    ngOnInit(){

    }
}