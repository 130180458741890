import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { LoggedInCallback, UserLoginService } from "../../service/cognito.service";
import { Router } from "@angular/router";
import { DynamoDBIBeaconsService } from "../../service/ddbibeacons.service";
import { BucketBeaconsService } from "../../service/bucket.beaconsimages.service";


import { Observable } from "rxjs";
import * as fromRoot from '../../common/index';
import * as layout from '../../common/layout/layout.actions';
import * as games from '../../common/games/games.actions';
import * as devices from '../../common/devices/devices.actions';

import { Store } from "@ngrx/store";
import timeago from 'timeago.js';

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { MatSort } from '@angular/material';

import { utils } from '../../lib/utils';

import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './equipmentinline.html',
    styleUrls: ['./equipmentinline.css', './searchbar.css']
})
export class EquipmentInLineComponent implements LoggedInCallback, OnInit {

    @ViewChild('filter') filter: ElementRef;
    @ViewChild(MatSort) sort: MatSort;

    public actualDevice: any = {
        DdgMacDV: "",
        DgMac: ""
    };

    public inModal$: Observable<boolean>;
    public devices$: Observable<any>;


    public inImageModal =  false;
    public selectedImage = null;



     actualLang = "en";

    displayedColumns = ['userId', 'userName', 'progress', 'color', 'ubication'];
    //exampleDatabase = new ExampleDatabase();
    dataSource: ExampleDataSource | null;

    selected: boolean = false;

    constructor( public translate: TranslateService, private _utils: utils, private store: Store<fromRoot.AppState>, public bucket: BucketBeaconsService, private el: ElementRef, public router: Router, public ddb: DynamoDBIBeaconsService, public userService: UserLoginService) {

        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.inModal$ = store.select(fromRoot.getLayoutModalIsOpen);
        this.userService.isAuthenticated(this);

        this.store.dispatch(new layout.CloseModalAction(true));

        var test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];
        };

        timeago.register('es', test_local_dict);

        var pt_test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Alguns momentos atrás'],
                ['Hace %s segundos', 'Em %s segundos'],
                ['Hace 1 minuto', 'Em 1 minuto'],
                ['Hace %s minutos', 'Em %s minutos'],
                ['Hace 1 hora', 'Em 1 hora'],
                ['Hace %s horas', 'Em %s horas'],
                ['Hace 1 día', 'Em 1 dia'],
                ['Hace %s días', 'Em %s dias'],
                ['Hace 1 semana', 'Em 1 semana'],
                ['Hace %s semanas', 'Em %s semanas'],
                ['Hace 1 mes', 'Em 1 mês'],
                ['Hace %s meses', 'Em %s meses'],
                ['Hace 1 año', 'Em 1 ano'],
                ['Hace %s años', 'Em %s anos']
            ][index];
        };

        timeago.register('pt', pt_test_local_dict);

        var en_test_local_dict = function (number, index, total_sec) {
            return [
                ['just now', 'right now'],
                ['%s seconds ago', 'in %s seconds'],
                ['1 minute ago', 'in 1 minute'],
                ['%s minutes ago', 'in %s minutes'],
                ['1 hour ago', 'in 1 hour'],
                ['%s hours ago', 'in %s hours'],
                ['1 day ago', 'in 1 day'],
                ['%s days ago', 'in %s days'],
                ['1 week ago', 'in 1 week'],
                ['%s weeks ago', 'in %s weeks'],
                ['1 month ago', 'in 1 month'],
                ['%s months ago', 'in %s months'],
                ['1 year ago', 'in 1 year'],
                ['%s years ago', 'in %s years']
            ][index]; 
        };

        timeago.register('en', en_test_local_dict);

        var s = translate.getBrowserCultureLang();

        var newLang = s.slice(0,2);

        this.actualLang = newLang;

  
    }

    ngOnInit(): void {
        this.prepareSearchBar();
        this.dataSource = new ExampleDataSource(this, this.sort);
        Observable.fromEvent(this.filter.nativeElement, 'keyup')
            .debounceTime(150)
            .distinctUntilChanged()
            .subscribe(() => {
                if (!this.dataSource) { return; }
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }
    prepareSearchBar() {
        $(document).ready(function () {

            var $searchTrigger = $('[data-ic-class="search-trigger"]'),
                $searchInput = $('[data-ic-class="search-input"]'),
                $searchClear = $('[data-ic-class="search-clear"]');

            $searchTrigger.click(function () {

                var $this = $('[data-ic-class="search-trigger"]');
                $this.addClass('active');
                $searchInput.focus();

            });

            $searchInput.blur(function () {

                if ($searchInput.val().length > 0) {

                    return false;

                } else {

                    $searchTrigger.removeClass('active');

                }

            });

            $searchClear.click(function () {
                $searchInput.val('');
            });

            $searchInput.focus(function () {
                $searchTrigger.addClass('active');
            });

        });

    }
    data() {
        var s;
        this.devices$.forEach((data) => { s = data });
        console.log("sssss", s);
        return s;
    }
    returnSensorsCount(device) {
        //  console.log(device);
        var j = 0;
        for (let i in device.value.DgAttributes) {
            j++;
            //  console.log(i);
        }
        return j;
    }

    returnStateInfoText(val) {
        return this._utils.getStateReason(val).text;
    }
    setActualDevice(trama, item) {

        this.selectedImage = null;
        this.inImageModal = false;
        
        console.log("La tramaaa",trama);
        console.log("Item", item);
        console.log("Qué está haciendo esto", item.DgDeviceType);

        if(item.DgDeviceType){
            if(item.DgDeviceType == 'camara'){
                trama = {
                    DdgMacDV: item.DgMac
                };
            }
        }

        if(trama){
            this.actualDevice = {
                DgMac: ""
            }
            var comp = this;
            this.devices$.forEach((data) => {
                console.log("On forEach");
                if (data != undefined) {
                    console.log("Data exists");
                    if (data[trama.DdgMacDV]) {
                        console.log("Data[trama.DdgMacDV]");
                        comp.actualDevice = data[trama.DdgMacDV]
                    }
                }

            });
            //actualDevice.DgMac
            console.log("Lo que se va",this.actualDevice);
            this.store.dispatch(new layout.OpenModalAction(true));
        }else{
            this.translate.get('no_data').subscribe((res: string) => {
                console.log(res);
                alert(res);
            });  
        }
    }

    returnDeviceImage(mac) {
        return 'https://api3.azlogica.com/getImage?api_key=AKIAIML5VHZTZZJYO2YA&bucket=azcognito-iot&key='+ mac;
    }

    

    returnFixedUbication(address){
        var _length = address.length;
        var s = address;
        if(_length > 60){
            s = address.slice(0,63);
        }
        return s;
        
    }

    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, this.actualLang ); // returns '11 hours ago'
        // console.log(time);
        return time;
    }
    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            console.log("scanning Devices");
            //GET
        }
    }

    closeModal() {
        this.inImageModal = false;
    }

    openImage(imageMac) {

        this.inImageModal = true;

        this.selectedImage = "https://s3.amazonaws.com/azcognito-iot/" + imageMac;

 
    }



}

/** Constants used to fill up our data base. */
const COLORS = ['maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple',
    'fuchsia', 'lime', 'teal', 'aqua', 'blue', 'navy', 'black', 'gray'];
const NAMES = ['Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack',
    'Charlotte', 'Theodore', 'Isla', 'Oliver', 'Isabella', 'Jasper',
    'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'];

export interface UserData {
    id: string;
    name: string;
    progress: string;
    color: string;
}

export class ExampleDataSource extends DataSource<any> {
    _filterChange = new BehaviorSubject('');
    get filter(): string { return this._filterChange.value; }
    set filter(filter: string) { this._filterChange.next(filter); }

    constructor(private _exampleDatabase: EquipmentInLineComponent, private _sort: MatSort) {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._exampleDatabase.devices$,
            this._filterChange,
            this._sort.sortChange
           // this._sort.mdSortChange
           
        ];



        return Observable.merge(...displayDataChanges).map((_s: any) => {
            console.log(_s);
            var s = this._exampleDatabase.data();
            var z = [];
            for (let key in s) {
                z.push(s[key]);
            }
            var ss = null;

            if (_s.active) {

                ss = this.getSortedData();

            }
            else {

                ss = z.slice().filter((item) => {
                    // console.log("iteeeeemmm", item)
                    let searchStr = (item.DgName).toLowerCase();
                    //  console.log(searchStr.indexOf(this.filter.toLowerCase()));
                    return searchStr.indexOf(this.filter.toLowerCase()) != -1;
                });
            }
            //console.log(ss);
            console.log(this.filter);
            return ss;
        });
    }

    disconnect() { }

    /** Returns a sorted copy of the database data. */
    getSortedData(): UserData[] {

        var s = this._exampleDatabase.data();
        var z = [];
        for (let key in s) {
            z.push(s[key]);
        }
        const data = z.slice();
        if (!this._sort.active || this._sort.direction == '') { return data; }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch (this._sort.active) {
                case 'userId': [propertyA, propertyB] = [a.DgMac, b.DgMac]; break;
                case 'userName': [propertyA, propertyB] = [a.DgName, b.DgName]; break;
                case 'progress': [propertyA, propertyB] = [a.log[0].DdgTstamp, b.log[0].DdgTstamp]; break;
                case 'color': [propertyA, propertyB] = [a.log[0].DdgMotivo, b.log[0].DdgMotivo]; break;
            }

            let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
        });
    }
}