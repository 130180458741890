import { Component, OnInit } from "@angular/core";
import { LoggedInCallback, UserLoginService, UserParametersService, Callback } from "../../service/cognito.service";
import { Router } from "@angular/router";
import { SharedService } from "../../service/global.service";

import { Store } from "@ngrx/store";
import * as fromRoot from '../../common/index';

import { Observable } from "rxjs";

import { utils } from '../../lib/utils';
import * as layout from '../../common/layout/layout.actions';

import { DynamoDBIOTService } from "../../service/ddbiot.service";

import * as devices from '../../common/devices/devices.actions';

import timeago from 'timeago.js';

declare var AWS: any;
declare var $: any;


@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './news.html',
    styleUrls: ['./news.css', './searchbar.css']
})

export class NewsComponent implements OnInit, LoggedInCallback {

    public games$: Observable<any>;
    public logs$: Observable<any>;
    public devices$: Observable<any>;
    public inModal$: Observable<boolean>;

    public logsLoading$: Observable<boolean>;

    public actualDomain;

    public inputs = [];
    public dispatchParams: any;

    public username: any;
    public actualDevice: any = {
        DdgMacDV: "",
        DgMac: ""
    };

    public query:any;

    public actualAlarmsView: string = "unattended";
    test = false;

    constructor(public ddb: DynamoDBIOTService, private UserParametersService: UserParametersService, public sharedService: SharedService, private _utils: utils, private store: Store<fromRoot.AppState>, public router: Router, public userService: UserLoginService, public userParams: UserParametersService) {
        this.userService.isAuthenticated(this);
        console.log("In MyProfileComponent");

        this.games$ = store.select(fromRoot.getGamesEntities);
        this.logs$ = store.select(fromRoot.getLogsEntities);
        this.devices$ = store.select(fromRoot.getDevicesEntities);

        this.logsLoading$ = store.select(fromRoot.getLogsLoadingState);
        this.inModal$ = store.select(fromRoot.getLayoutModalIsOpen);

        this.store.dispatch(new layout.CloseModalAction(true));

        var test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];
        };
        timeago.register('test_local', test_local_dict);

    }
    setAlarEstado(log) {

        if (log.DdgAlarEstado) {
            this.ddb.updateAlarEstado(log.DdgMacDV, log.DdgTstamp, false, this.onAlarmUpdated.bind(this));
        }
        else {
            this.ddb.updateAlarEstado(log.DdgMacDV, log.DdgTstamp, true, this.onAlarmUpdated.bind(this));
        }

        setTimeout(()=> { 
            this.store.dispatch(new devices.UpdateAlarEstadoAction(log));
        }, 1000);
    }
    onAlarmUpdated(err, data) {
        console.log(err);
        if(!err){
            console.log(data);
        }
    }
    sendMessage(timestamp, mac) {
        if (this.inputs[timestamp]) {

            var mess = {
                date: new Date().getTime(),
                description: this.inputs[timestamp],
                autor: this.username,
                image: 'https://s3.amazonaws.com/azcognito-iot/' + this.sharedService.UsId
            }

            this.ddb.insertAlarmMessage(mac, timestamp, mess, this.onAddedMessage.bind(this));

            this.dispatchParams = {
                mac: mac,
                timestamp: timestamp,
                message: mess
            }
            this.inputs[timestamp] = "";
        }
    }
    changeAlarmsView(val){
        this.actualAlarmsView = val;
    }
    onAddedMessage(err, data) {
        if (err) {
            console.log("Error :(");
        }
        else {
            console.log(":=)");
            console.log(data);
            this.store.dispatch(new devices.UpdateLogsComments(this.dispatchParams));
        }
    }

    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, "test_local"); // returns '11 hours ago'
        // console.log(time);
        return time;
    }
    setActualDevice(trama) {

        this.actualDevice = {
            DgMac: ""
        }
        var comp = this;
        this.devices$.forEach((data) => {

            console.log(data);
            console.log(trama.DdgMacDV);
            if (data != undefined) {


                //    if(trama.DdgMacDv){
                console.log("passed")
                if (data[trama.DdgMacDV]) {
                    console.log("passed2");
                    comp.actualDevice = data[trama.DdgMacDV]
                }
            }
            //   }

        })
        // this.store.dispatch(new layout.OpenModalAction(true));

        // setTimeout( ()=>{
        //      comp.store.dispatch(new layout.OpenModalAction(true));
        //  }, 3500)

        this.test = !this.test;
    }
    ngOnInit() {
        this.actualDomain = this.sharedService.domains[this.sharedService.myDomain];

    }
    returnDeviceImage(mac) {

        return "https://s3.amazonaws.com/azcognito-iot/" + mac;
    }
    returnStateInfoText(val) {
        return this._utils.getStateReason(val).text;
    }
    returnStateInfoImage(val) {
        return this._utils.getStateReason(val).image;

    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            this.UserParametersService.getParameters(this.setParameters.bind(this));
            //  this.userParams.getParameters(new GetParametersCallback(this));
        }
    }

    setParameters(data) {
        this.username = data[3].Value;
        //this.firebaseData();
    }


}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchLog'
})
export class SearchLogPipe implements PipeTransform {
    public transform(value, keys: string, term: string) {
        console.log(keys);
        if (!term) return value;
        return (value || []).filter((item) => keys.split(',').some(key => {
            console.log(item);
            return item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]);

        }));

    }
}