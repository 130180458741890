import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchUserPipe'
})
export class searchUserPipe implements PipeTransform {

  transform(items: any[], field:string, value: string): any[] {

    if(!items) return [];
    if(!value) return items;


    console.log("field", field);

 

    return items.filter( str => {

      if(str[field]){

 


          return str[field].toLowerCase().includes(value.toLowerCase());
        }
        });
   }
}