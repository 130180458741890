import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

import { SharedService } from "./global.service";


/**
 * Created by Vladimir Budilov
 */


declare var AWS: any;
declare var AWSCognito: any;

@Injectable()
export class DynamoDBBeaconsService {

    constructor(private SharedService: SharedService) {
        console.log("DynamoDBBeaconsService: constructor");
    }

    getAWS() {
        return AWS;
    }

    getFollowingLogEntries(mapArray, points: any[]) {
        console.log("DynamoDBService: reading from DDBeacons with creds - " + AWS.config.credentials);
        //console.log(this.SharedService.Beacons.length);
        if (this.SharedService.FollowingBeacons) {
            //console.log("Con Beacons Cantidad: "+Object.keys(this.SharedService.Beacons).length);
            //console.log(this.SharedService.Beacons[0].IbUMm);
            this.SharedService.FollowingBeacons.forEach(function (key) {
                //console.log(key.IbUMm);
                //console.log(key);
                var params = {
                    TableName: environment.ddbDecodeDataTableName,
                    ProjectionExpression: "DdUMmIB,DdAliasIB,DdAddressDV,DdLatDV,DdLongDV,DdDatetimeServer,DdStatusIB,DdCompanyIB,DdTstamp,DdAliasDV,DdNameIB,DdIbUrlIB",
                    KeyConditionExpression: "DdUMmIB = :DdUMmIB",
                    FilterExpression: "DdCompanyIB = :DdCompanyIB",
               
                    ExpressionAttributeValues: {
                        ":DdUMmIB": key.IbUMm,
                        ":DdCompanyIB" : key.IbCompany
          
                    },
                    ScanIndexForward: false,
                    Limit: 1
                };

                //0 ausencia
                //1 presencia
                //console.log("ID Beacon, para Consulta:"+item.IbUMm);

                var docClient = new AWS.DynamoDB.DocumentClient();
                docClient.query(params, onQuery);
                //console.log("Beacon a Consultar--->"+key.IbUMm);


            });
        }
        else {
            console.log("Sin Beacons");
        }




        function onQuery(err, data) {
            if (err) {
                console.error("DynamoDBeaconsService: Unable to query the table. Error JSON:", JSON.stringify(err, null, 2));
            } else {



                // print all the movies
                console.log("datas");
                console.log(data);
                //console.log("DynamoDBeaconsService: Query succeeded. Decode-Data");
                var j = 0;
                var logitem = data.Items[0];
                //  data.Items.forEach(function (logitem) {

                //	console.log(logitem);

                j++;


                if (logitem.DdStatusIB == 0) {
                    mapArray.push({ name: logitem.DdNameIB, alias: logitem.DdAliasIB, type: logitem.DdAliasDV, date: logitem.DdDatetimeServer, date2: logitem.DdTstamp, localization: logitem.DdAddressDV, lat: logitem.DdLatDV, lng: logitem.DdLongDV, status: "Ausencia", isAusent: true, isPresent: false, icon: '../../../assets/img/pin_iconRedSmall.png', url: logitem.DdIbUrlIB , user: logitem.DdCompanyIB});


                }
                else if (logitem.DdStatusIB == 1) {

                    mapArray.push({ name: logitem.DdNameIB, alias: logitem.DdAliasIB, type: logitem.DdAliasDV, date: logitem.DdDatetimeServer, date2: logitem.DdTstamp, localization: logitem.DdAddressDV, lat: logitem.DdLatDV, lng: logitem.DdLongDV, status: "Presencia", isAusent: false, isPresent: true, icon: '../../../assets/img/pin_iconGreenSmall.png', url: logitem.DdIbUrlIB , user: logitem.DdCompanyIB });

                }

                points.push({
                    "Latitude": logitem.DdLatDV,
                    "Longitude": logitem.DdLongDV
                }
                );

                //    console.log(points);



                //  });


                //    console.log("Registros de la Consulta:"+ j);
            }
        }
    }

    getLogEntries(mapArray, points: any[]) {
        console.log("DynamoDBService: reading from DDBeacons with creds - " + AWS.config.credentials);

        var shared = this.SharedService;
        //console.log(this.SharedService.Beacons.length);
        if (this.SharedService.Beacons) {
            //console.log("Con Beacons Cantidad: "+Object.keys(this.SharedService.Beacons).length);
            //console.log(this.SharedService.Beacons[0].IbUMm);
            this.SharedService.Beacons.forEach(function (key) {
                //console.log(key.IbUMm);
                //console.log(key);
                var params = {
                    TableName: environment.ddbDecodeDataTableName,
                    ProjectionExpression: "DdUMmIB,DdAliasIB,DdAddressDV,DdLatDV,DdLongDV,DdDatetimeServer,DdStatusIB,DdCompanyIB,DdTstamp,DdAliasDV,DdNameIB,DdIbUrlIB",
                    KeyConditionExpression: "DdUMmIB = :DdUMmIB " ,
                    FilterExpression: "DdCompanyIB = :DdCompanyIB",
                    ExpressionAttributeValues: {
                        ":DdUMmIB": key.IbUMm,
                        ":DdCompanyIB" : shared.UsId
                    },
                    ScanIndexForward: false,
                    Limit: 1
                };

                //0 ausencia
                //1 presencia
                //console.log("ID Beacon, para Consulta:"+item.IbUMm);

                var docClient = new AWS.DynamoDB.DocumentClient();
                docClient.query(params, onQuery);
                //console.log("Beacon a Consultar--->"+key.IbUMm);


            });
        }
        else {
            console.log("Sin Beacons");
        }




        function onQuery(err, data) {
            if (err) {
                console.error("DynamoDBeaconsService: Unable to query the table. Error JSON:", JSON.stringify(err, null, 2));
            } else {



                // print all the movies
                console.log("datas");
                console.log(data);
                //console.log("DynamoDBeaconsService: Query succeeded. Decode-Data");
                var j = 0;
                var logitem = data.Items[0];
                //  data.Items.forEach(function (logitem) {

                //	console.log(logitem);

                j++;


                if (logitem.DdStatusIB == 0) {
                    mapArray.push({ name: logitem.DdNameIB, alias: logitem.DdAliasIB, type: logitem.DdAliasDV, date: logitem.DdDatetimeServer, date2: logitem.DdTstamp, localization: logitem.DdAddressDV, lat: logitem.DdLatDV, lng: logitem.DdLongDV, status: "Ausencia", isAusent: true, isPresent: false, icon: '../../../assets/img/pin_iconRedSmall.png', url: logitem.DdIbUrlIB });


                }
                else if (logitem.DdStatusIB == 1) {

                    mapArray.push({ name: logitem.DdNameIB, alias: logitem.DdAliasIB, type: logitem.DdAliasDV, date: logitem.DdDatetimeServer, date2: logitem.DdTstamp, localization: logitem.DdAddressDV, lat: logitem.DdLatDV, lng: logitem.DdLongDV, status: "Presencia", isAusent: false, isPresent: true, icon: '../../../assets/img/pin_iconGreenSmall.png', url: logitem.DdIbUrlIB });

                }

                points.push({
                    "Latitude": logitem.DdLatDV,
                    "Longitude": logitem.DdLongDV
                }
                );

                //    console.log(points);



                //  });


                //    console.log("Registros de la Consulta:"+ j);
            }
        }
    }
    updateLogEntry(uuid, url) {

        var docClient = new AWS.DynamoDB.DocumentClient()

        var params = {
            TableName: environment.ddbDecodeDataTableName,
            ProjectionExpression: "DdUMmIB,DdAliasIB,DdAddressDV,DdLatDV,DdLongDV,DdDatetimeServer,DdStatusIB,DdCompanyIB,DdTstamp,DdAliasDV,DdNameIB,DdIbUrlIB",
            KeyConditionExpression: "DdUMmIB = :DdUMmIB",
            ExpressionAttributeValues: {
                ":DdUMmIB": uuid
            },
            ScanIndexForward: false,
            Limit: 1
        };

        docClient.query(params, onQuery);

        function onQuery(err, data) {
            if (err) {
                console.error("DynamoDBeaconsService: Unable to query the table IN UPDATE LOG ENTRY. Error JSON:", JSON.stringify(err, null, 2));
            } else {
                var logitem = data.Items[0];

                var timestamp = logitem.DdTstamp;

                console.log("timestamp!: " + logitem.DdTstamp);

                DynamoDBBeaconsService.onSearchedLogEntry(uuid, url, timestamp)


            }

        }
    }
    static onSearchedLogEntry(uuid, url, timestamp) {

        var docClient = new AWS.DynamoDB.DocumentClient()

        var params = {
            TableName: environment.ddbDecodeDataTableName,
            Key: {
                "DdUMmIB": uuid,
                "DdTstamp": timestamp
            },
            UpdateExpression: "set DdIbUrlIB = :url",
            ExpressionAttributeValues: {
                ":url": url
            },
            ReturnValues: "UPDATED_NEW"
        };

        console.log("Updating the item...");
        docClient.update(params, function (err, data) {
            if (err) {
                console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
            } else {
                console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
            }
        });



    }



}
