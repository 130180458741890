import { Component, Attribute } from '@angular/core';
import { VJSConfig } from './vjs.config.service';

declare var visualize: any;

@Component({
  selector: 'vjs-component',
  template: '<div id="{{resourceId}}"></div>',
})

export class VisualizeJS {

  resourceId:any; 

  constructor( @Attribute('id') id: string, public vjsConfig: VJSConfig) {

    var resourceIndex: number = this.getObjectIndex(vjsConfig.resourceDetails, id);
    var resourceId: string = vjsConfig.resourceDetails[resourceIndex].id;

    this.drawResource(vjsConfig, resourceIndex);

  };

  public drawResource(vjsConfig, resourceIndex) {

    var server = 'https://bi.azlogica.com:8443/jasperserver-pro';
    var server2 = 'http://server3.azlogica.com:8080/jasperserver';
    var data = 'j_username=user_webservice%7C1&j_password=okmijn098';
    var data2 = 'j_username=iotazlogica&j_password=lenovo2025T';

    visualize({
      auth: {
        loginFn: function (properties, request) {
          return request({
            method: "POST",
            url: server2 + "/rest/login",
            data: data2,
            headers: ''
          });
        }
      }
    }, function (v) {
      console.log("Workssssss!");
      console.log(v);
      switch (vjsConfig.resourceDetails[resourceIndex].type) {

        case "report": {
          v("#" + vjsConfig.resourceDetails[resourceIndex].id).report({
            resource: vjsConfig.resourceDetails[resourceIndex].uri,
            params: vjsConfig.resourceDetails[resourceIndex].params,
            success: function () { console.log("success") },
            error: function (err) { console.log( err) }
          });
          break;
        }

       /* case "dashboard": {
          v("#" + vjsConfig.resourceDetails[resourceIndex].id).dashboard({
            resource: vjsConfig.resourceDetails[resourceIndex].uri,
            params: vjsConfig.resourceDetails[resourceIndex].params,
            success: function () { console.log("success") },
            error: function (err) { alert("Dashboard draw failed: " + err) }
          });
          break;
        } */

        default: {
          alert("Visualize.js resource type not found.");
        }
      }

      },
      function (err) {
        console.log(err);
      }
    );

    /* visualize({
       server: 'http://bi.azlogica.com/jasperserver-pro',
       auth: vjsConfig.userAuth
     }, function (v) {

       console.log(v);

       switch (vjsConfig.resourceDetails[resourceIndex].type) {

         case "report": {
           v("#" + vjsConfig.resourceDetails[resourceIndex].id).report({
             resource: vjsConfig.resourceDetails[resourceIndex].uri,
             params: vjsConfig.resourceDetails[resourceIndex].params,
             success: function () { console.log("success") },
             error: function (err) { alert("Report draw failed: " + err) }
           });
           break;
         }

         case "dashboard": {
           v("#" + vjsConfig.resourceDetails[resourceIndex].id).dashboard({
             resource: vjsConfig.resourceDetails[resourceIndex].uri,
             params: vjsConfig.resourceDetails[resourceIndex].params,
             success: function () { console.log("success") },
             error: function (err) { alert("Dashboard draw failed: " + err) }
           });
           break;
         }

         default: {
           alert("Visualize.js resource type not found.");
         }

       };
     },
       function (err) {
         console.log(err);
         // alert("Visualize.js could not authenticate user/password.");
       }); */
  };

  private getObjectIndex(object: any, id: any): number {
    return object.map(function (x) { return x.id; }).indexOf(id);
  };

}
