import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { LoggedInCallback, UserLoginService } from "../../service/cognito.service";
import { Router } from "@angular/router";
import { DynamoDBIBeaconsService } from "../../service/ddbibeacons.service";
import { BucketBeaconsService } from "../../service/bucket.beaconsimages.service";

import { SharedService } from "../../service/global.service";

import { Observable } from "rxjs";
import * as fromRoot from '../../common/index';
import * as layout from '../../common/layout/layout.actions';
import * as games from '../../common/games/games.actions';
import * as devices from '../../common/devices/devices.actions';

import { Store } from "@ngrx/store";
import timeago from 'timeago.js';


declare var AWS: any;


@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './realtime.html',
    styleUrls: ['./realtime.css']
})
export class RealtimeComponent implements LoggedInCallback, OnInit {

    public actualDevice: any = {
        DdgMacDV: "",
        DgMac: ""
    };

    public actualDomain;

    latitude = 0;
    longitude = 0;

    public devices$: Observable<any>;
    public query: any;

    constructor(public sharedService: SharedService, public router: Router, private store: Store<fromRoot.AppState>) {

        this.devices$ = store.select(fromRoot.getDevicesEntities);

    }

    returnDeviceImage(mac) {
        return "https://s3.amazonaws.com/azcognito-iot/" + mac;
    }
    returnMapImage(log) {
        return 'url(https://maps.googleapis.com/maps/api/staticmap?center=' + log.DdgLatDV + ',' + log.DdgLongDV + '&zoom=16&size=1200x600&markers=color:blue%7C' + log.DdgLatDV + ',' + log.DdgLongDV + '&key=AIzaSyCNTADvFF94DewVXLpsdiIWDzU2TnThZCc)';
    }
    returnDeviceStatus(device) {
        var s = "";
        if (device.realtime) {
            s = 'On';
        }
        else {
            s = 'Off';
        }
        return s;
    }

    ngOnInit(): void {

        this.actualDomain = this.sharedService.domains[this.sharedService.myDomain];


       /* var bucket = new AWS.S3(
            {
                region: 'us-east-1',
                apiVersion: '2006-03-01',
                credentials: new AWS.Credentials({
                    accessKeyId: 'AKIAJIN3RX6HFWZ6FU2A', secretAccessKey: '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'
                }),
                //Bucket: 'azlogica-ft-media'
            }
        );

        bucket.listObjects({
            Bucket: 'azlogica-ft-media',
          
            //  ACL: 'public-read'
        }, function (err, data) {
            if (err) {
                console.log('There was an error uploading your photo: ', err.message);
                console.log(err);
            }
            console.log(data);


        }); */


    }

    setActualDevice(trama) {

        if(trama){

            this.actualDevice = {
                DgMac: ""
            }
            var comp = this;
            this.devices$.forEach((data) => {

                console.log(data);
                console.log(trama.DdgMacDV);
                if (data != undefined) {
                    console.log("passed")
                    if (data[trama.DdgMacDV]) {
                        console.log("passed2");
                        comp.actualDevice = data[trama.DdgMacDV]
                    }
                }

            })
            this.store.dispatch(new layout.OpenModalAction(true));
        }
        else{
            alert("No hay datos recientes de este dispositivo.");
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {

            //GET
        }
    }
    returnActualValue(arr , params) {
        return arr[arr.length - 1].valor +  " " + params;
    }
    returnMapStatus(arr, type) {

        var s = arr[arr.length - 1];
        var ret = 0;
        if (type == 'latitude') {
            ret = parseFloat(s.latitude);
        }
        else if (type == 'longitude') {
            ret = parseFloat(s.longitude);
        }
        return ret;
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchDevice'
})
export class SearchDevicePipe implements PipeTransform {
    public transform(value, keys: string, term: string) {

        if (!term) return value;
        return (value || []).filter((item) => keys.split(',').some(key => item.value.hasOwnProperty(key) && new RegExp(term, 'gi').test(item.value[key])));

    }
}