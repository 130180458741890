import {Component, ElementRef, ViewChild} from "@angular/core";
import {LoggedInCallback, UserLoginService} from "../../service/cognito.service";
import {Router} from "@angular/router";
import {DynamoDBIBeaconsService} from "../../service/ddbibeacons.service";
import { BucketBeaconsService } from "../../service/bucket.beaconsimages.service";

declare var $: any

import { Observable } from "rxjs";
import * as fromRoot from '../../common/index';
import * as layout from '../../common/layout/layout.actions';
import * as games from '../../common/games/games.actions';
import * as devices from '../../common/devices/devices.actions';

import { Store } from "@ngrx/store";


import timeago from 'timeago.js';


@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './devices.html',
     styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements LoggedInCallback {

   public  actualDevice : any ={
        DdgMacDV: "",
        DgMac:""
   };
  public inModal$: Observable<boolean>;

   public devices$: Observable<any>;
   static actualItem= {
     umm: "",
      alias: "",
      name: "",
      idb: "",
      group: "",
      url: ""
    };
  test = false;
    actualPosItem ;
    file: File;

    static blobfile;
    static filesrc;
    @ViewChild('form') form;
    @ViewChild('modal') modal;

    constructor( private store: Store<fromRoot.AppState>, public bucket: BucketBeaconsService, private el: ElementRef, public router: Router, public ddb: DynamoDBIBeaconsService, public userService: UserLoginService) {
        
        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.inModal$ = store.select(fromRoot.getLayoutModalIsOpen);
        this.userService.isAuthenticated(this);

        DevicesComponent.blobfile = null;
        console.log("in DevicesComponent");
        $(".modals").html("");


        var test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];
        };
        timeago.register('test_local', test_local_dict);
        //this.modal.nativeElement.reset();
    //     $(".ui.modal")
        //    .modal({
      //       blurring: true
      //     });
    }
    returnSensorsCount(device){
    //  console.log(device);
      var j= 0;
      for(let i in device.value.DgAttributes){
        j++;
      //  console.log(i);
      }
      return j;
    }
        setActualDevice(trama){

        this.actualDevice = {
            DgMac: ""
        }
       var comp  = this;
        this.devices$.forEach( (data)=>{

            console.log(data);
            console.log(trama.DdgMacDV);
            if(data !=undefined){
                console.log("passed")
                if(data[trama.DdgMacDV]){
                    console.log("passed2");
                    comp.actualDevice = data[trama.DdgMacDV] 
                }
            }

        })
        this.store.dispatch(new layout.OpenModalAction(true));
        this.test = !this.test;
    }

    returnDeviceImage(mac) {

        return "https://s3.amazonaws.com/azcognito-iot/" + mac;
    }

    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, "test_local"); // returns '11 hours ago'
        // console.log(time);
        return time;
    }
    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            console.log("scanning Devices");
                 //GET
        }
    }
    public onClickBeaconCard(event, item,i){

      console.log("actual pos item: " + i );
        this.actualPosItem = i;
        console.log("onClickBeaconCard!");
        DevicesComponent.blobfile = null;
        DevicesComponent.actualItem = item;
        this.form.nativeElement.reset();
        $("#actualItemImg").attr('src',item.url);
        $('.ui.modalitem')
        .transition('horizontal flip')
          .modal('show')
        ;
    }

    getActualItem(type){
      if(type == "url"){
        return DevicesComponent.actualItem.url;
      }
      else if(type == "name"){
        return DevicesComponent.actualItem.name;
      }
    }

    onChange(event: EventTarget) {

        let eventObj: MSInputMethodContext = <MSInputMethodContext> event;
        let target: HTMLInputElement = <HTMLInputElement> eventObj.target;
        let files: FileList = target.files;
        var reader = new FileReader();
        reader.onload = function(){
            $("#actualItemImg").attr('src',reader.result);
            DevicesComponent.filesrc = reader.result;

        };
        var reader2 = new FileReader();
        reader2.onloadend = function() {
          console.log("Successful file write: " + this.result);
          var blob = new Blob([new Uint8Array(<ArrayBuffer>this.result)], { type: "image/png" });
          console.log(blob);
          DevicesComponent.blobfile = blob;
        };

          if(files[0].type == "image/jpeg" || files[0].type == "image/png"){
              reader.readAsDataURL(files[0]);
              reader2.readAsArrayBuffer(files[0]);
          }


      }
      public updateBeacon(){

        if(DevicesComponent.blobfile !=null){
            this.bucket.setBeaconImage(DevicesComponent.blobfile, DevicesComponent.actualItem.name, DevicesComponent.actualItem.umm, true);

          //  this.logdata[this.actualPosItem].url = DevicesComponent.filesrc;

        }

      }

}
