import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
    selector: 'weathercard-item',
    templateUrl: 'weathercard.html',
    styleUrls: ['./weathercard.css']

})

export class WeatherCardComponent implements OnInit {

    @Input() degrees: any;
    @Input() valueString: any;
    @Input() name: any;

    percentage: any = 80;

    constructor() { }
    ngOnInit() {
       // console.log(this.degrees);
        this.percentage = (this.degrees / 100) * 100;

        if (this.percentage < 0) {
            this.percentage = 0;

        }
        if(this.percentage >100){
            this.percentage = 100;
        }
      //  console.log(this.percentage);

    }

    returnWeatherStatus() {


        let _degrees = this.degrees;



        if (_degrees > 20) {
            return 'sunny';
        }
        else if (_degrees >= 0 && _degrees <= 19) {
            return 'rain';
        }
        else if (_degrees < 0) {
            return 'night'
        }
    }
}