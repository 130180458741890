import { Component, OnInit, Input,  Output } from '@angular/core';

@Component({
    selector: 'rfidcard-item',
    templateUrl: 'rfidcard.html',
    styleUrls: ['./rfidcard.css']
 
})

export class RFIDCardComponent implements OnInit {

    @Input() value: any;
    @Input() name: any;
    constructor(){}
    ngOnInit(){}
}