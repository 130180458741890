import { Component, OnInit, Input, Output } from '@angular/core';


declare var $: any;

@Component({
    selector: 'barcard-item',
    templateUrl: 'barcard.html',
    styleUrls: ['./barcard.css']

})

export class BarCardComponent implements OnInit {

    @Input() value: any;
    @Input() valueString: any;
    @Input() name: any;
    @Input() attributes: any;
    constructor() { }
    ngOnInit() {

        setTimeout( ()=>{
            moveProgressBar(_value);
        }, 4000)

        var max = this.attributes.maxRango;
        var comp = this;
    //    console.log(max);

        var _value = this.value;

        moveProgressBar(_value);

        function moveProgressBar(_value) {
         //   console.log("moveProgressBar");
            var getPercent =  (_value ) / max;

         //   console.log(getPercent);
            var getProgressWrapWidth = $('.progress-wrap').width();
            var progressTotal = getPercent * getProgressWrapWidth;
            var animationLength = 2500;

         //   console.log(progressTotal);

            // on page load, animate percentage bar to data percentage length
            // .stop() used to prevent animation queueing
            $('.progress-bar').stop().animate({
                left: progressTotal
            }, animationLength);
        }


    }

}