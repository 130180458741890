import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule ,JsonpModule} from '@angular/http';

import { Ng2CompleterModule } from "ng2-completer";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/*
  "@ngui/auto-complete": "^3.0.0",
    "@ngui/datetime-picker": "^0.16.2",*/
import {HomeComponent, AboutComponent, HomeLandingComponent} from "./public/home.component";
import { LoginComponent } from "./public/auth/login/login.component";
import { RegisterComponent } from "./public/auth/register/registration.component";
import { ForgotPasswordStep1Component, ForgotPassword2Component } from "./public/auth/forgot/forgotPassword.component";
import { LogoutComponent, RegistrationConfirmationComponent } from "./public/auth/confirm/confirmRegistration.component";
import { ResendCodeComponent } from "./public/auth/resend/resendCode.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';

import {SecureHomeComponent} from "./secure/landing/securehome.component";
import {HomeDashboardComponent} from "./secure/home/home.component";
import {NewsComponent, SearchLogPipe} from "./secure/news/news.component";
import {DevicesComponent} from "./secure/devices/devices.component"
import {AdminComponent} from "./secure/admin/admin.component";
import {MapComponent} from "./secure/map/map.component";
import {EquipmentInLineComponent} from './secure/equipmentinline/equipmentinline.component';
import {EquipmentComponent} from './secure/equipment/equipment.component';
import {RealtimeComponent ,SearchDevicePipe} from './secure/realtime/realtime.component';
import {HistoricComponent} from './secure/historic/historic.component';
import {ReportsComponent} from './secure/reports/reports.component';
import {FormsComponent} from './secure/forms/forms.component';
import {FormComponent} from './secure/form/form.component';
import {TerminalComponent} from './secure/terminal/terminal.component';

import {DeviceComponent, KeysPipe, WindowRef} from "./components/device/device.component"
import {TagCardComponent} from "./components/tagcard/tagcard.component"
import {WeatherCardComponent} from "./components/weathercard/weathercard.component"
import {DigitalCardComponent} from "./components/digitalcard/digitalcard.component"
import {ProfileCardComponent} from "./components/profilecard/profilecard.component"
import {AnalogCardComponent} from "./components/analogcard/analogcard.component"
import {CountCardComponent} from "./components/countcard/countcard.component"
import {CompassCardComponent} from "./components/compasscard/compasscard.component"
import {TacometroCardComponent} from "./components/tacometrocard/tacometrocard.component"
import {BarCardComponent} from "./components/barcard/barcard.component"
import {InfoCardComponent} from "./components/infocard/infocard.component"
import {MapCardComponent} from "./components/mapcard/mapcard.component"
import {RFIDCardComponent} from "./components/rfidcard/rfidcard.component"
import {TextCardComponent} from "./components/textcard/textcard.component"

import {TrafficLightCardComponent} from "./components/trafficlightcard/trafficlightcard.component"
import { TextBoxComponent } from './components/text-box/text-box.component'

import { BaseChartComponent} from "./components/realtime/basechart/basechart.component";
import {BarChartComponent } from "./components/realtime/barchart/barchart.component";
import { SwitchComponent } from './components/realtime/switch/switch.component';
import { AttributesChartComponent } from './components/realtime/attributeschart/attributeschart.component'

//CONTAINERS
import {ReportByNameContainer} from "./containers/reports/report_by_name/report_by_name.container";

import { UserRegistrationService, UserLoginService, UserParametersService, CognitoUtil } from "./service/cognito.service";
import { AwsUtil } from "./service/aws.service";
import { DynamoDBService } from "./service/ddb.service";
import { DynamoDBBeaconsService } from "./service/ddbbeacons.service";
import { DynamoDBIBeaconsService } from "./service/ddbibeacons.service";
import {DynamoDBIOTService } from "./service/ddbiot.service";

import { BucketBeaconsService } from "./service/bucket.beaconsimages.service";
import { SharedService } from "./service/global.service";

import { HistoricExport } from './service/historicExport.service';

import { utils } from './lib/utils'

import {routing} from "./app.routes";


import { MaterializeModule } from 'ng2-materialize';
import { MdlModule } from '@angular-mdl/core';
//import { UiSwitchModule }  from '../../node_modules/angular2-ui-switch/src'
import { UiSwitchModule } from 'ngx-toggle-switch';
import { AppComponent } from './app.component';

import {MatTableModule, MatInputModule, MatSortModule, MatPaginatorModule, MatSelectModule,
        MatAutocompleteModule, MatFormFieldModule, MatDialogModule , MatCheckboxModule} from '@angular/material';
        
import { ShowDialogComponent } from './secure/historic/historic.component';
import { PopupCaldera } from './components/device/device.component';       

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';


// import {AutocompleteLibModule} from 'angular-ng-autocomplete';

//store
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';


import {metaReducer, reducers} from "./common/index";
import {GameEffects} from "./common/games/games.effects";
import {DeviceEffects} from "./common/devices/devices.effects";
import {GamesService} from "./common/games/games.service";
import {DevicesService} from "./common/devices/devices.service"
import {FirebaseService} from "./common/devices/firebase.service";
import {SocketIOService} from "./common/devices/socket.io.service"


import { AgmCoreModule } from '@agm/core';

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule, AngularFireDatabase} from 'angularfire2/database';
import { FirebaseListObservable } from 'angularfire2/database-deprecated';

import { environment } from '../environments/environment';

import { TranslationModule } from './translation.module';


import { ChartsModule } from 'ng2-charts';

import {VJSConfig} from './visualize/vjs.config.service'

import {VisualizeJS} from './visualize/vjs.main.component';

 import { NguiDatetimePickerModule } from '@ngui/datetime-picker';

 import { FilterPipe} from './pipes/filter.pipe';
 import { searchUserPipe } from './pipes/searchUserPipe';

 import { GraphQLModule } from './apollo.config';

 import { GraphQLService } from './service/graphql.service'

 import {HttpClientModule, HttpClient} from '@angular/common/http';
 import {TranslateHttpLoader} from '@ngx-translate/http-loader';

 import { AreaChart } from './d3/bar-chart/bar-chart';
 import { JwtComponent } from './secure/jwttokens/jwt.component';
 import { MyProfileComponent } from './secure/profile/myprofile.component';
 import { UseractivityComponent } from './secure/useractivity/useractivity.component';


 //Autocomplete 
import { NguiAutoCompleteModule } from '@ngui/auto-complete';

@NgModule({
  declarations: [
    AppComponent,


    HomeComponent,
    AboutComponent,
    HomeLandingComponent,

    HomeDashboardComponent,
    SecureHomeComponent,
    NewsComponent,
    DevicesComponent,
    AdminComponent,
    MapComponent,
    EquipmentInLineComponent,
    EquipmentComponent,
    RealtimeComponent,
    HistoricComponent,
    ReportsComponent,
    FormsComponent,
    FormComponent,
    TerminalComponent,

    LoginComponent,
    RegisterComponent,
    ForgotPasswordStep1Component,
    ForgotPassword2Component,
    LogoutComponent,
    RegistrationConfirmationComponent,
    ResendCodeComponent,

    TextBoxComponent,

    DeviceComponent,
    KeysPipe,
    FilterPipe,
    searchUserPipe,

    SearchDevicePipe,
    SearchLogPipe,
    
    TagCardComponent,
    WeatherCardComponent,
    DigitalCardComponent,
    ProfileCardComponent,
    AnalogCardComponent,
    CountCardComponent,
    CompassCardComponent,
    TacometroCardComponent,
    BarCardComponent,
    InfoCardComponent,
    MapCardComponent,
    RFIDCardComponent,
    TextCardComponent,
    TrafficLightCardComponent,

    BaseChartComponent,
    BarChartComponent,
    SwitchComponent,
    AttributesChartComponent,

    ReportByNameContainer,

    VisualizeJS,

    ShowDialogComponent,
    PopupCaldera,

    AreaChart,
    JwtComponent,
    MyProfileComponent,
    UseractivityComponent

  ],
  entryComponents: [
    ShowDialogComponent,
    PopupCaldera
  ],
  imports: [
    // AutocompleteLibModule,
    NguiAutoCompleteModule,
    PdfViewerModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    AngularFireModule.initializeApp(environment.firebase, 'iot-gpio'),
    AngularFireDatabaseModule,

    routing,

    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MdlModule,
    BrowserAnimationsModule,
    MaterializeModule.forRoot(),
    UiSwitchModule,

    //store
    JsonpModule,
    StoreModule.forRoot(reducers),
    //ngrx effects
    EffectsModule.forRoot(
      [GameEffects,
       DeviceEffects
      ]
    ),

     AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCNTADvFF94DewVXLpsdiIWDzU2TnThZCc'
    }),

    ChartsModule,

    Ng2CompleterModule,
    NguiDatetimePickerModule,

    GraphQLModule,

    TranslationModule


  ],
  providers: [
    WindowRef,
    AwsUtil,
    DynamoDBService,
    DynamoDBBeaconsService,
    DynamoDBIBeaconsService,
    DynamoDBIOTService,
    BucketBeaconsService,
    SharedService,
    UserRegistrationService,
    UserLoginService,
    UserParametersService,
    CognitoUtil,

    GamesService,
    DevicesService,
    FirebaseService,
    SocketIOService,
    HistoricExport,

    GraphQLService,

    VJSConfig,

    utils

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
