import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';

declare let AWS: any;
declare let AWSCognito: any;

@Component({
    selector: 'awscognito-angular2-app',
    template: '<p>Welcome!"</p>'
})
export class AboutComponent {

}

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './home.html',
    styleUrls: ['./home.css']
})
export class HomeLandingComponent implements OnInit {

    actualLang = "es";


    constructor(public router: Router, public translate: TranslateService ) {
        console.log("HomeLandingComponent constructor");
        var s = translate.getBrowserCultureLang();

        var newLang = s.slice(0,2);

        this.actualLang = newLang;


    }
    ngOnInit() {

        this.router.navigate(['/home/login']);
    }
}

import { SharedService } from "../service/global.service";


@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './home.html'
})
export class HomeComponent implements OnInit {

    public imgDomain = "";
    public actualDomain ;

    actualLang = "es";


    constructor(public sharedService: SharedService,  public translate: TranslateService, ) {
        console.log("HomeComponent constructor");


        var s = translate.getBrowserCultureLang();

        var newLang = s.slice(0,2);

        this.actualLang = newLang;
    }

    ngOnInit() {

        this.actualDomain = this.sharedService.domains[this.sharedService.myDomain];
        console.log(this.actualDomain);
        console.log("asdasdadd domain");
        if (this.sharedService.myDomain == 'evolucion') {
            this.imgDomain = 'assets/img/az-icon.png';
        }
        else {
            this.imgDomain = 'assets/img/tigoune-icon.jpg';
        }

    }
}


