import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SharedService } from "./global.service";
import {DynamoDBIBeaconsService} from "./ddbibeacons.service";

/**
 * Created by Felipe Castillo
 */


declare var AWS: any;
declare var AWSCognito: any;

@Injectable()
export class BucketBeaconsService {

	myfile: any;
	file: any;
	static ddbb;

	constructor(private SharedService: SharedService, public ddb: DynamoDBIBeaconsService) {
		console.log("DynamoDBIBeaconsService: constructor");
		BucketBeaconsService.ddbb = this.ddb;
	}
	getAWS() {
		return AWS;
	}

	getBeaconsImages()  {


		console.log( AWS.config.credentials);

		var bucket = new AWS.S3();



		bucket.getObject({ Bucket: environment.bucketBeaconsImages, Key: 'a@abc.com/profilePhotoaa' }, function(err, file) {

			//code?? to display this image file in the img tag
			//$scope.src=file????....obviously it wont work

			if (err) {
				console.log("error");
				console.log(err);
			}

			console.log("in bucket");
			console.log(file);

		});


	}
	setBeaconImage(blob, key, uuid, ibeacons){

			var bucket = new AWS.S3();

		              bucket.putObject({
		                      Bucket: 'azcognito-iot',
		                      Key: uuid,
		                      Body: blob,
		                      ContentType:"image/jpeg"
		                    //  ACL: 'public-read'
		                    }, function(err, data) {
		                      if (err) {
		                        console.log('There was an error uploading your photo: ', err.message);
		                        console.log(err);
		                      }
							  console.log(data);


		               });



	}





}
