import { Injectable, Inject } from '@angular/core';
import { Response, Http, Headers, RequestOptions, Jsonp } from "@angular/http";
import { Store } from "@ngrx/store";


import { SharedService } from "../../service/global.service";

import * as fromRoot from "../index"
declare var AWS: any;

@Injectable()
export class DevicesService {

    constructor(public SharedService: SharedService) {


        console.log(this.SharedService.UsParameters);
    }

    async query() {

        console.log(this.SharedService.UsParameters);

        var params = {
            TableName: "devicesGPIO",
            IndexName: "DgCompany-index",
            KeyConditionExpression: "#DgCompany = :beaValue ",
            ExpressionAttributeNames: {
                "#DgCompany": "DgCompany"
            },
            ExpressionAttributeValues: {
                ":beaValue": this.SharedService.UsParameters[0].Value
            }
        };
        var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
        //  var docClient = Promise.all( new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false }) );
        // return docClient.then
        return docClient.query(params).promise().then((data) => {

            return data;
        });

    }
    async queryDdg(data) {

        var nextPromises: any = [];

        var err = false;
        if (err) {
            console.error(err);
        }
        else {

            var devices = {};

            for (let j = 0; j < data.Items.length; j++) {

                console.log("hgola");
                var device = data.Items[j];
                //   data.Items.forEach(function (device, j) {

                devices[device.DgMac] = device;

                var params = {
                    TableName: "decode-dataGPIO",
                    // IndexName: "DdgMacDV-DdgCompanyDV-index",
                    KeyConditionExpression: "(#DdgMacDV = :mac)",
                    //      FilterExpression: " DdgCompanyDV =  :company",
                    ExpressionAttributeNames: {
                        "#DdgMacDV": "DdgMacDV"
                    },
                    ExpressionAttributeValues: {
                        ":mac": device.DgMac,
                        //  ":company": 'alexander.ortiz@azlogica.com',
                    },
                    ScanIndexForward: false,
                    Limit: 40

                };
                var params2 = {
                    TableName: "decode-dataGPIO",
                    // IndexName: "DdgMacDV-DdgCompanyDV-index",
                    KeyConditionExpression: "(#DdgMacDV = :mac)",
                    FilterExpression: " DdgMotivo >  :value",
                    ExpressionAttributeNames: {
                        "#DdgMacDV": "DdgMacDV"
                    },
                    ExpressionAttributeValues: {
                        ":mac": device.DgMac,
                        ":value": 1.00,
                    },
                    ScanIndexForward: false,
                 //   Limit: 20

                };
                var docClient = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
               // var docClient2 = new AWS.DynamoDB.DocumentClient({ dynamoDbCrc32: false });
                nextPromises.push(docClient.query(params).promise());
             //   nextPromises.push(docClient2.query(params2).promise());
            };
        }

        return Promise.all(nextPromises).then((allData: any) => {

            var devices = {};
            var logs = [];
            var alarms = [];

            console.log("alll data");
            console.log(allData);

            for (let j = 0; j < allData.length; j++) {

                var device = data.Items[j];
                devices[device.DgMac] = device;
                devices[device.DgMac].log = [];
                devices[device.DgMac].alarm = [];

                for (let i = 0; i < allData[j].Items.length; i++) {

                  //  if (allData[j].ScannedCount > 1) {
                        //LOGS
                        devices[device.DgMac].log.push(allData[j].Items[i]);
                        logs.push(allData[j].Items[i]);
                  //  }
                   // else {
                        //ALARMS
                   //     devices[device.DgMac].alarm.push(allData[j].Items[i]);
                  //      alarms.push(allData[j].Items[i]);
                  //  }

                }
                if (j == allData.length - 1) {
                    return {
                        devices: devices,
                        logs: logs,
                        alarms: alarms
                    };
                }
            }
        });

    }
}

