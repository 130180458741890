import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LoggedInCallback, UserLoginService, UserParametersService } from "../../service/cognito.service";
import { DynamoDBIBeaconsService } from "../../service/ddbibeacons.service";
import { SharedService } from "../../service/global.service";

import { BrowserModule } from "@angular/platform-browser";
import { BucketBeaconsService } from "../../service/bucket.beaconsimages.service";

import { Observable } from "rxjs";

import { DynamoDBIOTService } from "../../service/ddbiot.service";
/**
 * Import the root state in order to select parts of it.
 */
import * as fromRoot from '../../common/index';
/*
 * Import the layout actions to make dispatching from the component possible.
 */
import * as layout from '../../common/layout/layout.actions';
import { utils } from '../../lib/utils';
import * as games from '../../common/games/games.actions';
import * as devices from '../../common/devices/devices.actions';

import { Store } from "@ngrx/store";

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { FirebaseListObservable } from 'angularfire2/database-deprecated';
import { MzToastService } from "ng2-materialize/dist";
import {FormControl} from '@angular/forms';

import { CompleterService, CompleterData } from 'ng2-completer';
import timeago from 'timeago.js';


declare var $: any


export class Stuff3 {
    public umm: string;
    public alias: string;
    public name: string;
    public idb: string;
    public group: string;
    public url: string;
    public following: any;
    public followers: any;
    public icon: string;
    public iconName: string;

}
@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './secureHome.html',
    styleUrls: ['./secureHome.css']
})
export class SecureHomeComponent implements OnInit, LoggedInCallback {


       public searchStr: string;
    public games$: Observable<any>;
    public devices$: Observable<any>;
    public logsLoading$: Observable<boolean>;
    myControl = new FormControl();

    public userEmail: String;
    static usEmail: String;
    public fixedUserName: Array<String> = [];

    public logdatas: Array<Stuff3> = [];

    static bucketc;

    public bucketUrl = 'https://api3.azlogica.com/getImage?api_key=AKIAIML5VHZTZZJYO2YA&bucket=azcognito-iot&key=';


    public imgUrl: String;

    public followers = [];
    public following = [];
    public followersRequest = [];

    public enterprise = "";
    public username = "";

    public actualDomain;

    public moduleExceptions: any = {

        realtime: true,
        formularios: true,
        reportes: true
    };

    iotAccount: boolean = false;
    adminAccount: boolean = false;

    BuscarEmpresa = "Cambiar de empresa";
    userPermissionsBool: boolean = false;
    userPermissions = {};


    actualEnterprise = null;

    isModalActive = false;

    enterprises = [];

    companies= [];

    selectedEnterprise;


    keyword = 'name';
    data = [
       {
         id: 1,
         name: 'Usa'
       },
       {
         id: 2,
         name: 'England'
       }
    ];
 
    public dataService: CompleterData;

    @ViewChild('form') form;
    @ViewChild('modalprofile') modal;

    decodeDataItems: AngularFireList<any>;

    constructor( private completerService: CompleterService, private _utils: utils, private toastService: MzToastService, public db: AngularFireDatabase, private store: Store<fromRoot.AppState>, public bucket: BucketBeaconsService, public router: Router, public userService: UserLoginService, public ddb: DynamoDBIOTService, public sharedService: SharedService, private UserParametersService: UserParametersService) {
        this.userService.isAuthenticated(this);

        setTimeout(() => {
            this.getUserPermissions();
            this.getEnterprises();
            this.getActualEnterprise();
        }, 2000);
        this.UserParametersService.getParameters(this.setParameters.bind(this));
        SecureHomeComponent.bucketc = this.bucket;
        console.log("SecureHomeComponent: constructor");

        this.games$ = store.select(fromRoot.getGamesEntities);
        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.logsLoading$ = store.select(fromRoot.getLogsLoadingState);
        var comp = this;
        // setTimeout(function() {

        var test_local_dict = function (number, index, total_sec) {
            return [
                ['Justo ahora', 'Hace unos momentos'],
                ['Hace %s segundos', 'En %s segundos'],
                ['Hace 1 minuto', 'En 1 minuto'],
                ['Hace %s minutos', 'En %s minutos'],
                ['Hace 1 hora', 'En 1 hora'],
                ['Hace %s horas', 'En %s horas'],
                ['Hace 1 día', 'En 1 día'],
                ['Hace %s días', 'En %s días'],
                ['Hace 1 semana', 'En 1 semana'],
                ['Hace %s semanas', 'En %s semanas'],
                ['Hace 1 mes', 'En 1 mes'],
                ['Hace %s meses', 'En %s meses'],
                ['Hace 1 año', 'En 1 año'],
                ['Hace %s años', 'En %s años']
            ][index];
        };
        timeago.register('test_local', test_local_dict);

        if (this.sharedService.UsId == 'iot@azlogica.com' || this.sharedService.UsId == 'pipech161@gmail.com') {
            this.iotAccount = true;

        }


        //  }, 5000);
    }
    onSelected(evt){
        console.log("selected!", evt)

        this.selectedEnterprise = evt.originalObject;
    }

    openEnterpriseModal(){
        this.isModalActive = true;
    }

    disableModal(){
        this.isModalActive = false;
    }

    changeEnterprise(){

        console.log("changeEnterprise");
     
        this.disableModal();

        if(this.selectedEnterprise){

            console.log("changeEnterprise2");

            this.actualEnterprise = this.selectedEnterprise;

            console.log("actualEnterprise", this.actualEnterprise);

            localStorage.setItem("savedEnterprise", JSON.stringify(this.actualEnterprise));

            this.selectedEnterprise = null;

            location.reload();

        }
    }

    getSavedEnterprise(companies){

        

        var _savedEnterprise = localStorage.getItem("savedEnterprise");

        if(_savedEnterprise){
            
            this.actualEnterprise = JSON.parse(_savedEnterprise);
        }
        else {

            var _first = true;
            for ( let key in companies){

                if(_first){

                    this.actualEnterprise = companies[key]


                    localStorage.setItem("savedEnterprise", JSON.stringify(this.actualEnterprise));

                    _first = false;
                }
            }
            //this.actualEnterprise = companies[0]
        }

        console.log("ACTUAL ENTERPRISE!", this.actualEnterprise);

    }

 
    selectEvent(item) {
        // do something with selected item
      }
     
      onChangeSearch(val: string) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
      }
      
      onFocused(e){
        // do something when input is focused
      }
    getActualEnterprise(){

        this.ddb.getActualUser(this.sharedService.UsId, this.actualUserSuccess.bind(this), [], null);
    }

    actualUserSuccess(err,data){
        console.log("mi dataaa from actual user", data);

        if (err) {
            console.log("Err retriving user", err);
        }
        else {
            if (data.Items[0]) {

                localStorage.setItem("actualUser",JSON.stringify(data.Items[0]))

                this.companies = [];

                var comps = data.Items[0].companies;

                for (let key in comps){
                    this.companies.push( comps[key]);
                }

                this.dataService = this.completerService.local(this.companies, 'name', 'name');
            
                this.getSavedEnterprise(data.Items[0].companies);

            }
        }
    }


    getUserPermissions() {

        this.ddb.getUserPermissions(this.sharedService.UsId, this.userSuccess.bind(this), null, [] , null );

    }
    userSuccess(err, data) {
        console.log("mi dataaa", data);

        if (err) {
            console.log("Err retriving user", err);
        }
        else {
            if (data.Items[0]) {

                console.log("entreeee");

               
                this.userPermissions = data.Items[0].IpPermissions;
                this.userPermissionsBool = true;
            }
        }
    }

    getEnterprises() {
        this.enterprises = [];
        console.log("getEnterprises");
        this.ddb.getEnterprises(this.enterprises, this.enterprisesSuccess.bind(this), [], null);

    }
    enterprisesSuccess() {

        console.log("enterpriseSuccess");
        console.log(this.enterprises);
        this.enterprises.forEach((data) => {
            if (data.IcEmail == this.sharedService.UsId) {
                this.adminAccount = true;
            }
        }, this);

    }

    returnStateInfoText(val) {
        return this._utils.getStateReason(val).text;
    }

    returnDateTimeStamp(timestamp) {
        var time = timeago().format(timestamp, "test_local"); // returns '11 hours ago'
        // console.log(time);
        return time;
    }

    firebaseData() {

        var comp = this;
        this.decodeDataItems = this.db.list('decodedataGPIO', ref => ref.limitToLast(1));

       // this.decodeDataItems = this.db.list('decodedataGPIO');
        this.decodeDataItems.snapshotChanges(['child_added'])
        .subscribe(action => {
                console.log("actiiionnnn");
                console.log(action);
                action.forEach(_child => {
                    console.log(_child.type);
                    console.log(_child.key);
                    console.log(_child.payload.val());
                    console.log(_child.payload.val());
                    let child = _child.payload;

                    var htmlLog = '<div>' +
                        '<div style="    display: flex;  font-size: 15px;padding: 3px;font-weight: 600;color: rgba(0,0,0,.87);"> <div> Nueva Notificación! </div> <div> <i style="padding-left: 17px;" class="material-icons">info</i></div> </div>' +
                        '<div style="display:flex;">   ' +
                        ' <div> <img style="margin-top:15px;width:70px; height:70px;" src="https://s3.amazonaws.com/azcognito-iot/' + child.val().DdgMacDV + '"> </div>' +
                        ' <div style="padding-left: 24px;">  <div style="color:#424242; font-weight: bold ;  font-size: 14px; padding:10px;">' + child.val().DdgNameDV + '</div>' +

                        ' <div style="padding: 10px;color: #616161;font-size: 12px;padding-top: 0px;"> ' + comp.returnStateInfoText(child.val().DdgMotivo) + '  </div>' +
                        ' <div style="padding: 10px;color: #616161;font-size: 12px;padding-top: 0px;"> ' + child.val().DdgDatetimeServer + '  </div></div>' +

                        '</div>' +
                        '</div>';

                    if (this.sharedService.UsParameters[3]) {
                        if (this.sharedService.UsParameters[3].Value == child.val().DdgCompanyDV) {
                            comp.toastService.show(htmlLog, 4000, 'white');

                            comp.store.dispatch(new devices.SetDeviceAction(child.val()));
                        }
                    }
                }); 

            });
    }
    setParameters(data) {
        this.enterprise = data[2].Value;
        this.username = data[4].Value;
        this.sharedService.UsParameters = data;
        console.log("parammmmms------------");
        console.log(data);

        setTimeout(() => {
            
    
          //  this.ddb.getUserEnterprise(this.enterprise, this.userEnterpriseSuccess.bind(this));
          this.userEnterpriseSuccess(true,null);
 
            this.store.dispatch(new devices.LoadDevicesAction());
        }, 1000);

        
       // this.firebaseData();
    }


    userEnterpriseSuccess(err,data){
        console.log("userEnterpriseSuccess")
        console.log(data);
        if(!err){
            if(data.Items && data.Items[0] && data.Items[0].IcModulesExceptions){
                
                this.moduleExceptions = data.Items[0].IcModulesExceptions
            }
            else {
                this.moduleExceptions = {};
            }
        }
        else {
            this.moduleExceptions = {};
        }
    }

    ngOnInit() {

        this.actualDomain = this.sharedService.domains[this.sharedService.myDomain];

        var circle = $("#circle");
        var w = window.innerWidth;
        var h = window.innerHeight;
        circle.animate({
            "width": w + 100 + "px",
            "height": w + 100 + "px",
            "margin-left": -(w / 2) - 50 + "px",
            "margin-top": -(w / 2) - 50 + "px"
        }, 1000, function () {
            circle.css({
                "width": "100%",
                "border-radius": "0px",
                "margin": "0px",
                "top": "0px",
                "left": "0px"
            });
        })

        function ischr42android() {
            var ua = navigator.userAgent;
            var t = ua.split(' ');
            var s: any = (t.indexOf("Mobile") - 1).toString();
            s = parseInt(s);
            var tval: any = s;
            // var tval = parseInt(t.indexOf("Mobile") - 1); 
            var tf = t[tval].split('.');
            var tff: any = tf[0].split('/');
            if (tff[0] == "Chrome" && tff[1] <= 42) { return true } else { return false }
        }

        $('.card-action>a').addClass('green-text')
        $('#actovrdrpdwn li>a, .icon-navli>a').addClass('waves-effect waves-dark')

        $('.button-collapse').sideNav()
        if (navigator.userAgent.indexOf("Android") !== -1 && ischr42android() == true) { $("[data-activates='actovrdrpdwn']").hide(); }
        function focussearch() {
            $('#search-nav').fadeIn(250);
            $('#bl-search').focus();
        }

        $('#bl-search').focus(function () {
            $('.nav-form-back').css('color', 'black')
        })
        $('#bl-search').blur(function () {
            $('.nav-form-back').css('color', 'white')
        })

        function srcclose() { $('#search-nav').fadeOut(250); $('#bl-search').blur(); }


        console.log("Iniciando");
        //  this.ddb.getFollowing(this, this.following, false, false);
        // this.ddb.getFollowers(this, this.followers, false, false, false);
        //  this.ddb.getFollowersRequest(this, this.followersRequest, false, false);


        // this.ddb.getBeacons(this, this.logdatas, false, false);
        // this.ddb.getFollowingBeacons(this, this.followinglogdatas);

        this.fixedUserName = this.sharedService.UsId.split("@");

        this.userEmail = this.fixedUserName[0];

        SecureHomeComponent.usEmail = this.sharedService.UsId;

        this.imgUrl = this.bucketUrl + this.sharedService.UsId;

        //this.store.dispatch(new games.LoadGamesAction(1));
        //    this.store.dispatch(new devices.LoadDevicesAction());

    }

    openModal() {


        $('.ui.modalprofile')
            .transition('horizontal flip')
            .modal('show')
            ;
    }


    returnFollowing() {
        return this.following.length;
    }
    returnFollowers() {
        return this.followers.length;
    }
    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        }

        else {

        }
    }

    changeProfileImage() {



    }
    onChange(event: EventTarget) {

        let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
        let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
        let files: FileList = target.files;
        var reader = new FileReader();
        reader.onload = function () {
            $("#profileImage").attr('src', reader.result);
            $("#profileImage2").attr('src', reader.result);
        };
        var reader2 = new FileReader();
        reader2.onloadend = function () {
            console.log("Successful file write: " + this.result);
            var blob = new Blob([new Uint8Array(<ArrayBuffer>this.result)], { type: "image/png" });
            console.log(blob);
            SecureHomeComponent.bucketc.setBeaconImage(blob, SecureHomeComponent.usEmail, SecureHomeComponent.usEmail);
        };

        if (files[0].type == "image/jpeg" || files[0].type == "image/png") {
            reader.readAsDataURL(files[0]);
            reader2.readAsArrayBuffer(files[0]);
        }


    }
    returnUrl(item) {

        return 'https://s3.amazonaws.com/azlogica-iris/' + item;
    }
    logout() {
        this.userService.logout();

        this.router.navigate(['/home/login']);
        localStorage.clear();

        setTimeout(function () {
            location.reload();
        }, 1000);
    }
}


/*
   "an50": {
      "alias": "Mains",
      "factorConversion": "1*{valor}",
      "group": "Mains",
      "icon": "notification_important",
      "maxRango": 32000,
      "minRango": 0,
      "render": "texto",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "kWh"
    },

     "an51": {
      "alias": "Mains",
      "factorConversion": "1*{valor}",
      "group": "Mains",
      "icon": "notification_important",
      "maxRango": 32000,
      "minRango": 0,
      "render": "texto",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "kVArh"
    },

    "an17": {
      "alias": "Load kVAr",
      "factorConversion": "1*{valor}",
      "group": "Load",
      "icon": "battery_charging_full",
      "maxRango": 32000,
      "minRango": 0,
      "render": "texto",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "kVAr"
    },
    "an18": {
      "alias": "Load kVAr L1",
      "factorConversion": "1*{valor}",
      "group": "Load",
      "icon": "battery_charging_full",
      "maxRango": 32000,
      "minRango": 0,
      "render": "texto",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "kVAr"
    },
    "an19": {
      "alias": "Load kVAr L2",
      "factorConversion": "1*{valor}",
      "group": "Load",
      "icon": "battery_charging_full",
      "maxRango": 32000,
      "minRango": 0,
      "render": "texto",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "kVAr"
    },
    "an20": {
      "alias": "Load kVAr L3",
      "factorConversion": "1*{valor}",
      "group": "Load",
      "icon": "battery_charging_full",
      "maxRango": 32000,
      "minRango": 0,
      "render": "texto",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "kVAr"
    },

    "an44": {
      "alias": "D+",
      "factorConversion": "1*{valor}",
      "maxRango": 2000,
      "minRango": 0,
      "render": "dona",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "V"
    },
      "an47": {
      "alias": "Fuel Level",
      "factorConversion": "0.001*{valor}",
      "maxRango": 100,
      "minRango": 0,
      "render": "dona",
      "renderRealtime": "line",
      "representacion": "Decimal",
      "unidad": "%"
    },
      "D": {
      "alias": "Puerta",
      "factorConversion": "1*{valor}",
      "maxRango": 1,
      "minRango": 0,
      "render": "switch",
      "renderRealtime": "switch",
      "representacion": "Binaria",
      "unidad": " "
    },
    */