import { Component, OnInit, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from "rxjs";

import { Store } from "@ngrx/store";

import * as fromRoot from '../../../common/index';
import * as layout from '../../../common/layout/layout.actions';
import * as devices from '../../../common/devices/devices.actions';
import { DynamoDBIOTService } from "../../../service/ddbiot.service";



@Component({
    selector: 'basechart-realtime',
    templateUrl: 'basechart.html',
    styleUrls: ['./basechart.css']

})

export class BaseChartComponent implements OnInit, OnChanges {


    @Input() mac: any;
    @Input() realtimeData: any;
    @Input() deviceName: any;
    @Input() type: any;
    @Input() unity: any;
    @Input() attrname: any;
    @Input() maxValue: any;

    charData: any = [];

    firstInit : boolean = true;

    chartOptions = {
        responsive: true,
        maintainAspectRatio: false
        //  scales: {
        //  yAxes: [{
        // display: true,
        // ticks: {
        //  suggestedMin: 0,
        //  max : undefined
        // }
        //}]
        //  }
    };

    modalOptions: any;

    chartData = [
        { data: [], label: '' },
    ];

    randomColor = {
        backgroundColor: '#4DB6AC',
        borderColor: 'rgba(77,83,96,1)'
    };

    chartLabels = [];

    onChartClick(event) {

    }
    changedData() {


    }
    realLength;
    public devices$: Observable<any>;
    constructor(public db: DynamoDBIOTService, private store: Store<fromRoot.AppState>) {

        this.devices$ = store.select(fromRoot.getDevicesEntities);
        this.randomColor.backgroundColor = this.generateRandomColor();
    }
    changeChart(value) {

        var params = {
            "DgMac": this.mac,
            "AttrName": this.attrname,
            "NewRealtimeChart": value
        }

        this.store.dispatch(new devices.UpdateAttrRealtimeChartAction(params));
        this.db.updateAttributeRealtimeChart(this.mac, this.attrname, value, this.onChangedChart.bind(this));

    }

    onChangedChart() {
    }

    openServiceModal(){}

    generateRandomColor() {
        var colors = [
            '#4DB6AC',
            '#AED581',
            '#FFD54F',
            '#FF8A65',
            '#90A4AE',
            '#7986CB',
            '#9575CD'
        ];

        var rand = colors[Math.floor(Math.random() * colors.length)];
        return rand;

    }
    public lineChartColors: Array<any> = [
    ];
    ngOnChanges(changes: SimpleChanges) {


        // throw new Error("Method not implemented.");
    }

    newDataPoint(dataArr = [100, 100, 100], label) {

        this.chartData.forEach((dataset, index) => {
            this.chartData[index] = Object.assign({}, this.chartData[index], {
                data: [...this.chartData[index].data, dataArr[index]]
            });
        });

        this.chartLabels = [...this.chartLabels, label];

    }
    returnDataSet() {
        //  console.log(this.realtimeData.length);
        var le = this.realtimeData.length;
        if (le != this.realLength) {


            var arrd = [0];

            var dataLength = 20;
            if (this.type.toString == 'polarArea' || this.type == 'pie' || this.type == 'radar') {

                dataLength = 6;
            }

            if (this.realtimeData.length >= dataLength) {

              /*  if(this.firstInit){
                     this.chartData[0].data.splice(0, this.chartData[0].data.length -2);
                     this.chartLabels.splice(0, this.chartData[0].data.length -2);
                     this.firstInit = false;
                }
                else { */
                     this.chartData[0].data.splice(0, 1);
                     this.chartLabels.splice(0, 1);
              //  }
               


            }

            this.realtimeData.map((value, index) => {



                if (index == le - 1) {

                    arrd.push(value.valor);

                    this.chartData[0] = Object.assign({}, this.chartData[0], {
                        data: [...this.chartData[0].data, value.valor]
                    });

                    var _s = this.randomColor.backgroundColor;
                    if (this.type == "polarArea") {

                        _s = this.generateRandomColor();

                    }


                    this.chartLabels = [...this.chartLabels, value.date];
                    this.lineChartColors.push({ // dark grey
                        backgroundColor: _s,
                        borderColor: this.randomColor.borderColor,
                       /* pointBackgroundColor: 'rgba(77,83,96,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(77,83,96,1)'
                        */
                    });


                }

            })
            this.realLength = le;

        }



        return this.chartData;
    }
    ngOnInit() {

        this.firstInit = true;

        if (this.type == 'polarArea' || this.type == 'radar') {

        } else {
            //   this.chartOptions.scales.yAxes[0].ticks.max = this.maxValue;
        }

        this.chartData[0].label = this.deviceName;
        this.realLength;

        var le = this.realtimeData.length;
        var arrd = [0];
        var dataLength = 20;


        /*   this.realtimeData.map((value, index) => {
   
               arrd.push(value.valor);
   
               this.chartData[0] = Object.assign({}, this.chartData[0], {
                   data: [...this.chartData[0].data, value.valor]
               });
   
               var _s = this.randomColor.backgroundColor;
               if (this.type == "polarArea") {
   
                   _s = this.generateRandomColor();
   
               }
   
   
               this.chartLabels = [...this.chartLabels, value.date];
               this.lineChartColors.push({ // dark grey
                   backgroundColor: _s,
                   borderColor: this.randomColor.borderColor,
                   pointBackgroundColor: 'rgba(77,83,96,1)',
                   pointBorderColor: '#fff',
                   pointHoverBackgroundColor: '#fff',
                   pointHoverBorderColor: 'rgba(77,83,96,1)'
               });
   
   
   
           }) */


        this.realtimeData.map((value, index) => {

            arrd.push(value.valor);

         //   if(index == this.realtimeData.length-1){

          
            this.chartData[0] = Object.assign({}, this.chartData[0], {
                data: [...this.chartData[0].data, value.valor]
            });

            this.chartLabels = [...this.chartLabels, value.date];
//}

        })


        //    this.charData = arr;
        /*   this.chartData.forEach((dataset, index) => {
               this.chartData[0] = Object.assign({}, this.chartData[index], {
                   data: [...this.chartData[index].data, arrd[index]]
               });
           }); */


        // console.log(arr$);
        /*  this.devices$.map( ()=>{
              console.log("suscriptionnnnnnnnnnnnnn");
          })
         
     
          let subscription = this.devices$[this.mac].realtime.subscribe(
            value => {console.log("hmmmmmmmmm")}
         
          ); */


    }
}