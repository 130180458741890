import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { FirebaseListObservable } from 'angularfire2/database-deprecated'
import { Observable } from 'rxjs/Observable';

@Injectable()
export class FirebaseService {
    event: FirebaseListObservable<any[]>;

    decodeDataItems;
    constructor(private db: AngularFireDatabase) {

   // this.decodeDataItems = this.db.list('decodedataGPIO', ref => ref.limitToLast(1));
 // this.decodeDataItems = this.db.list('decodedataGPIO');
        // this.decodeDataItems.$ref
        //     .limitToLast(1)
        //     .on("child_added", (child) => {
        //         console.log(child.val());
        //     }); 
    }
    getData(){
        let observable = new Observable(observer => {
            //reconnect: true,
            this.decodeDataItems = this.db.list('decodedataGPIO', ref => ref.limitToLast(1));
            this.decodeDataItems.snapshotChanges(['child_added'])
            .subscribe(action => {
              //  console.log(action);
                observer.next(action[0]);
                /*action.forEach(_child => {
                    if(_child.type == 'value'){
                        observer.next(_child); 
                    }
                    
                });*/
               
            });
          })     
          return observable;
    }
}