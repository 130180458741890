import { Component, OnInit, ViewChild } from "@angular/core";
import { SharedService } from "../../service/global.service";
import { Router } from "@angular/router";


import { DynamoDBIOTService } from "../../service/ddbiot.service";

import { TranslateService } from '@ngx-translate/core';


import { LoggedInCallback, UserLoginService, UserParametersService } from "../../service/cognito.service";
@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './home.html',
    styleUrls: ['./home.css']
})


export class HomeDashboardComponent implements OnInit {

    public imgUrl: String;
    public bucketUrl = 'https://api3.azlogica.com/getImage?api_key=AKIAIML5VHZTZZJYO2YA&bucket=azcognito-iot&key=';

    public enterprise = "";
    public username = "";

    public imgDomain = "";
    public actualDomain;
    public onlyRead = false;
    public moduleExceptions: any = {

        realtime: true,
        formularios: true,
        reportes: true
    };


    iotAccount: boolean = false;
    adminAccount: boolean = false;
    enterprises = [];



    userPermissionsBool: boolean = false;
    userPermissions = {};

    constructor(private _translate: TranslateService, public ddb: DynamoDBIOTService, public router: Router, public userService: UserLoginService, public sharedService: SharedService, private UserParametersService: UserParametersService) {

        setTimeout(() => {
            this.getUserPermissions();
            this.getEnterprises();

            this.getActualEnterprise();
        }, 1000);
      

        if (this.sharedService.UsId == 'iot@azlogica.com' || this.sharedService.UsId == 'pipech161@gmail.com') {
            this.iotAccount = true;
        }

        

    }

    checkIfAdmin(user){
     
        console.log("actualUserrrr!", user);

        var admin = false;
        user.userPermissions.user.map( (item)=>{
            if(item.type == 7){
                if(item.completed == true){
                    this.onlyRead = true;
      
                   // this.router.navigate(["/home/login"]);
                }
            }
            if(item.name == 'ADMINISTRADOR DE USUARIOS'){
                if(item.completed == true){
                    console.log("admin!");
                    this.iotAccount = true;
                }
            }
        })

    }

    getActualEnterprise(){

        this.ddb.getActualUser(this.sharedService.UsId, this.actualUserSuccess.bind(this), [], null);
    }

    actualUserSuccess(err,data){
        console.log("mi dataaa from actual user", data);

        if (err) {
            console.log("Err retriving user", err);
        }
        else {
            if (data.Items[0]) {
                this.checkIfAdmin(data.Items[0])
            }
        }
    }


    getUserPermissions() {

        this.ddb.getUserPermissions(this.sharedService.UsId, this.userSuccess.bind(this),null, [], null);
   
    }

    userSuccess(err, data) {
        console.log("mi dataaa", data);

        if (err) {
            console.log("Err retriving user", err);
        }
        else {
            if (data.Items[0]) {

                console.log("entreeee");


                this.userPermissions = data.Items[0].IpPermissions;
                this.userPermissionsBool = true;
            }
        }
    }
    ngOnInit() {

        this.actualDomain = this.sharedService.domains[this.sharedService.myDomain];
        console.log(this.actualDomain);
        console.log("asdasdadd domain");
        if (this.sharedService.myDomain == 'evolucion') {
            this.imgDomain = 'assets/img/az-icon.png';
        }
        else {
            this.imgDomain = 'assets/img/tigoune-icon.jpg';
        }
        // this.imgDomain = this.actualDomain.name == 'evolucion' ? 'assets/img/az-icon.png' : 'assets/img/tigoune-icon.png'

        this.imgUrl = this.bucketUrl + this.sharedService.UsId;
        this.UserParametersService.getParameters(this.setParameters.bind(this));


    }

    getEnterprises() {
        this.enterprises = [];
        console.log("getEnterprises");
        this.ddb.getEnterprises(this.enterprises, this.enterprisesSuccess.bind(this), [], null);

    }
    enterprisesSuccess() {

        console.log("enterpriseSuccess");
        console.log(this.enterprises);
        this.enterprises.forEach((data) => {
            if (data.IcEmail == this.sharedService.UsId) {
                this.adminAccount = true;
            }
        }, this);
        

    }
    setParameters(data) {
        this.enterprise = data[2].Value;
        this.username = data[4].Value;
        this.sharedService.UsParameters = data;
        console.log("parammmmms------------");
        console.log(data);

        setTimeout(() => {
            
            //this.ddb.getUserEnterprise(this.enterprise, this.userEnterpriseSuccess.bind(this));
            this.userEnterpriseSuccess(true,null);

        }, 1000);   
       // this.firebaseData();
    }

    userEnterpriseSuccess(err,data){
        if(!err){
            if(data.Items && data.Items[0] && data.Items[0].IcModulesExceptions ){
                this.moduleExceptions = data.Items[0].IcModulesExceptions
            }
               else {
                this.moduleExceptions = {};
            }
        }
        else {
            this.moduleExceptions = {};
        }
    }
 
    logout() {
        this.userService.logout();

        this.router.navigate(['/home/login']);

       setTimeout(function () {
            location.reload();
        }, 1000);
    }

}