import { Component, OnInit, Input, Output } from '@angular/core';
import { Store } from "@ngrx/store";

import * as fromRoot from '../../../common/index';
import * as layout from '../../../common/layout/layout.actions';
import * as devices from '../../../common/devices/devices.actions';
import { DynamoDBIOTService } from "../../../service/ddbiot.service";



@Component({
    selector: 'switch-realtime',
    templateUrl: 'switch.html',
    styleUrls: ['./switch.css']

})

export class SwitchComponent implements OnInit {

    @Input() mac: any;
    @Input() realtimeData: any;
    @Input() deviceName: any;
    @Input() attrname: any;
    modalOptions:any;


    constructor(public db: DynamoDBIOTService,private store: Store<fromRoot.AppState>) { }
    changeChart(value) {

        var params = {
            "DgMac": this.mac,
            "AttrName": this.attrname,
            "NewRealtimeChart": value
        }

        this.store.dispatch(new devices.UpdateAttrRealtimeChartAction(params));
        this.db.updateAttributeRealtimeChart(this.mac, this.attrname, value, this.onChangedChart.bind(this));

    }

    onChangedChart() {

    }

    openServiceModal(){}
    
    ngOnInit() {

    }
    returnNumberStatus() {

        return this.realtimeData[this.realtimeData.length - 1].valor;
    }
    returnStatus() {
        //   console.log(this.realtimeData[this.realtimeData.length-1].valor);
        var s = "";
        if (this.realtimeData[this.realtimeData.length - 1].valor >= 1) {
            s = "Encendido";
        }
        else {
            s = 'Apagado';
        }
        return s;
    }
}